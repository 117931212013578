import React from "react";
import "./Halving.css";

const GlobalMarketCaps = ({ data }) => {
  const marketCaps = data[0];
  return (
    <div className="globalMarketCapsContainer">
      <h2 className="marketCapsHeading">Market Caps</h2>
      <table className="crypto-halving-table-global">
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(marketCaps).map(([key, value], index, array) => (
            <>
              {key === "Total Market Cap" && (
                <tr>
                  <td colSpan="2" className="totalMarketCapLine"></td>
                </tr>
              )}
              <tr key={key}>
                <td className="marketCapKey">{key}</td>
                <td className="marketCapValue">
                  {value}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GlobalMarketCaps;
