import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./StandardDeviation.css";

const StandardDeviation = ({ data, dataType, handleDataChange }) => {
  const tableRef = useRef(null);

  useEffect(() => {
    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      // Create a temporary container for parsing the HTML string
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = data;

      const table = container.append("table").attr("class", "standard-deviation-table");

      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      const headers = Array.from(tempContainer.querySelectorAll("th")).map((th) => th.textContent);
      thead.append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d);

      // Append table rows
      const rows = Array.from(tempContainer.querySelectorAll("tr")).slice(1); // Exclude the header row
      tbody.selectAll("tr")
        .data(rows)
        .enter()
        .append("tr")
        .html((d) => d.innerHTML);

      return table;
    };

    renderTable(data);
  }, [data]);

  return (
    <div className="standard-deviation-table-container">
      <div ref={tableRef} />
      <div className="standard-deviation-data-type-buttons">
        <button
          className={`standard-deviation-data-type-button ${dataType === "5. adjusted close" ? "active" : ""}`}
          onClick={() => handleDataChange("5. adjusted close")}
        >
          Adjusted Close
        </button>
        <button
          className={`standard-deviation-data-type-button ${dataType === "4. close" ? "active" : ""}`}
          onClick={() => handleDataChange("4. close")}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default StandardDeviation;
