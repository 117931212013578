import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SurveyPopup.css";
import { ApiUrl } from "../services/restAPI";
import RingLoader from "react-spinners/RingLoader";

const SurveyPopup = ({ userSpaceFinance, onClose, onSubmit }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [gender, setGender] = useState("Not specified");
  const [birthdate, setBirthdate] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]); // Estado para almacenar los países
  const [discoveryMethods, setDiscoveryMethods] = useState([]); // Estado para almacenar métodos de descubrimiento
  const [selectedMethod, setSelectedMethod] = useState(""); // New state for selected method
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Asegura que el formato sea MM
    const currentDate = String(today.getDate()).padStart(2, '0'); // Asegura que el formato sea DD
    setMaxDate(`${currentYear}-${currentMonth}-${currentDate}`); // Formato YYYY-MM-DD


    setIsSubmitting(true);
    const fetchCountries = async () => {
      try {
        const UrlApi = ApiUrl + "StartUserLogin/Countries";
        const response = await axios.get(UrlApi);
        const sortedCountries = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        ); // Ordena alfabéticamente por el nombre
        setCountries(sortedCountries); // Almacenar los países ordenados en el estado
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    };

    const fetchDiscoveryMethods = async () => {
      try {
        const UrlApi =
          ApiUrl + "StartUserLogin/SurveryQueryOption?ID_SurveryQuery=1";
        const response = await axios.get(UrlApi);
        const sortedMethods = response.data.sort(
          (a, b) => a.orderPosition - b.orderPosition
        );
        setDiscoveryMethods(sortedMethods);
      } catch (error) {
        console.error("Failed to fetch discovery methods", error);
      }
    };

    fetchCountries();
    fetchDiscoveryMethods();
  }, []);

  // Function to determine if a string is an email
  const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

  // Choose the correct userName value
  const userName = isEmail(userSpaceFinance.email)
    ? userSpaceFinance.email
    : isEmail(userSpaceFinance.name)
    ? userSpaceFinance.name
    : "";

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    const genderCode =
      gender === "Male" ? "M" : gender === "Female" ? "F" : "NF";
    const responseValue =
      discoveryMethods.find((method) => method.optionName === selectedMethod)
        ?.answerValue || null;

    const userData = {
      userName: userName,
      firstName: name,
      lastName: surname,
      gender: genderCode,
      birthDate: birthdate,
      country: country,
      responseValue: responseValue,
    };

    try {
      const UrlApi = ApiUrl + "StartUserLogin/SaveCustomerUser";
      await axios.post(UrlApi, userData);
      onSubmit(); // This should likely close the modal or indicate success
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}
      <div className="settings-popup-overlay-Survey">
        <div className="settings-popup-Survey">
          <div className="settings-popup-header-Survey">
            SURVEY
            {/*<button onClick={onClose} className="settings-popup-close-btn">
            X
    </button>*/}
          </div>
          <div className="settings-popup-content-Survey">
            <form onSubmit={handleSubmit}>
              <div className="form-group-Survey">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please fill out this field")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </div>
              <div className="form-group-Survey">
                <label htmlFor="name">Surname:</label>
                <input
                  type="text"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please fill out this field")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </div>
              <div className="form-group-Survey">
                <label htmlFor="name">Gender:</label>
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please select a country")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Not specified">Not specified</option>
                </select>
              </div>
              <div className="form-group-Survey">
                <label htmlFor="birthdate">Date of Birth:</label>
                <input
                  type="date"
                  id="birthdate"
                  value={birthdate}
                  onChange={(e) => setBirthdate(e.target.value)}
                  required
                  max={maxDate}
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please fill out this field")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </div>
              <div className="form-group-Survey">
                <label htmlFor="country">Country:</label>
                <select
                  id="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please select a country")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                >
                  <option value="">Select a country</option>
                  {countries.map((country) => (
                    <option key={country.iD_Country} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group-Survey">
                <label htmlFor="discoveryMethod">How did you find us?:</label>
                <select
                  id="discoveryMethod"
                  value={selectedMethod}
                  onChange={(e) => setSelectedMethod(e.target.value)}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please select a option")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                >
                  <option value="">Select an option</option>
                  {discoveryMethods.map((method) => (
                    <option
                      key={method.id_OptionQuery}
                      value={method.optionName}
                    >
                      {method.optionName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="button-container-Survey">
                <button type="submit" className="button-style-Survey">
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SurveyPopup;
