import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./HistogramGraph.css";

const HistogramGraph = ({ data, height = 300 }) => {
  const ref = useRef();
  const margin = { top: 30, right: 30, bottom: 40, left: 40 };

  useEffect(() => {
    const updateGraph = () => {
      // Tu lógica actual de creación de SVG aquí, pero sin fijar el ancho
      // y usando el ancho del contenedor padre para configurar el ancho del SVG

      if (!data || !data.columns || !data.data) return;

      // Obtén el ancho del contenedor para el SVG
      const currentContainerWidth =
        ref.current.offsetWidth - margin.left - margin.right;

      const container = d3.select(ref.current);
      container.selectAll("*").remove();

      const tickers = data.columns.slice(1); // Exclude 'index' column

      tickers.forEach((ticker) => {
        const columnIndex = data.columns.indexOf(ticker);
        const returnsArray = data.data.map((row) => row[columnIndex]);

        if (returnsArray.length === 0) return;

        const range = d3.max(returnsArray) - d3.min(returnsArray);
        const padding = range > 0.15 ? 0.05 : 0; // Adjust padding based on the range
        //const tickInterval = range <= 0.15 ? 0.05 : 0.05;
        const tickInterval = range / (currentContainerWidth / 80);
        const fontSize = Math.min(11, (currentContainerWidth / 300) * 11);

        // Define histogram parameters
        const histogram = d3.histogram()
        .value(d => d)
        .domain([d3.min(returnsArray), d3.max(returnsArray)])
        .thresholds(30); // Ajustar según sea necesario

        const bins = histogram(returnsArray);

        // Append SVG and visualize the histogram
        const svg = container
          .append("svg")
          .attr("width", currentContainerWidth)
          .attr("height", height)
          .style("margin-right", "10px");

        // Append title to each histogram
        svg
          .append("text")
          .attr("x", margin.left)
          .attr("y", margin.top / 2)
          .attr("text-anchor", "start")
          .text(ticker)
          .attr("font-size", "15px")
          .attr("font-weight", "bold")
          .attr("fill", "#133588");

        // Define scales and axes
        const xScale = d3
          .scaleLinear()
          .domain([d3.min(returnsArray), d3.max(returnsArray)])
          .range([margin.left, currentContainerWidth - margin.right]);

        const yScale = d3
          .scaleLinear()
          .domain([0, d3.max(bins, (d) => d.length)])
          .range([height - margin.bottom, margin.top]);

        const g = svg.append("g");

        g.selectAll("rect")
          .data(bins)
          .enter()
          .append("rect")
          .attr("x", (d) => xScale(d.x0))
          .attr("y", (d) => yScale(d.length) - 2.5)
          .attr("width", (d) => xScale(d.x1) - xScale(d.x0) - 1)
          .attr("height", (d) => height - margin.bottom - yScale(d.length))
          .attr("fill", "#F9409E")
          .attr("rx", 10)
          .attr("ry", 10)
          .on("mouseover", (event, d) => {
            const xPosition = xScale(d.x0 + (d.x1 - d.x0) / 2); // Midpoint of the bin
            const yPosition = yScale(d.length);
            const tooltipGroup = svg.append("g").attr("id", "tooltip");

            // Tooltip background
            const tooltipWidth = 120;
            const tooltipHeight = 40;
            let tooltipXPosition = xPosition - tooltipWidth / 2;
            let tooltipYPosition = yPosition - tooltipHeight - 10; // Offset para separar el tooltip de la barra

            // Verificar si el tooltip está demasiado cerca del borde superior
            if (tooltipYPosition < margin.top) {
              tooltipYPosition = yPosition + 10; // Cambiar la posición del tooltip debajo de la barra
            }

            // Verificar si el tooltip se sale del lado derecho del contenedor
            if (tooltipXPosition + tooltipWidth > currentContainerWidth) {
              tooltipXPosition = currentContainerWidth - tooltipWidth - 25;
            }

            // Verificar si el tooltip se sale del lado izquierdo del contenedor
            if (tooltipXPosition < 0) {
              tooltipXPosition = 0;
            }

            // Tooltip background
            tooltipGroup
              .append("rect")
              .attr("x", tooltipXPosition) // Adjust the x and y positions to properly position the tooltip background
              .attr("y", tooltipYPosition)
              .attr("width", tooltipWidth) // Width of the tooltip
              .attr("height", tooltipHeight) // Height of the tooltip
              .attr("fill", "#238bb0")
              .attr("stroke", "white")
              .attr("rx", 7) // Radio de las esquinas en el eje x
              .attr("ry", 7); // Radio de las esquinas en el eje y

            // Frequency text
            tooltipGroup
              .append("text")
              .attr("x", tooltipXPosition + tooltipWidth / 2)
              .attr("y", tooltipYPosition + tooltipHeight / 2 - 5) // Offset para centrar verticalmente el texto
              .attr("text-anchor", "middle")
              .attr("alignment-baseline", "middle")
              .attr("font-size", "11px")
              .attr("fill", "white")
              .text(`Frequency: ${d.length}`);

            // Range text
            tooltipGroup
              .append("text")
              .attr("x", tooltipXPosition + tooltipWidth / 2)
              .attr("y", tooltipYPosition + tooltipHeight / 2 + 10) // Offset para centrar verticalmente el texto
              .attr("text-anchor", "middle")
              .attr("alignment-baseline", "middle")
              .attr("font-size", "11px")
              .attr("fill", "white")
              .text(
                `Range: ${d3.format(".2f")(d.x0)} to ${d3.format(".2f")(d.x1)}`
              );
          })
          .on("mouseout", () => {
            svg.select("#tooltip").remove();
          });

        // Format and style the labels
        g.append("g")
          .attr("transform", `translate(0, ${height - margin.bottom})`)
          .call(
            d3
              .axisBottom(xScale)
              .ticks(range / tickInterval)
              .tickFormat(d3.format(".2f"))
          )
          .attr("font-size", `${fontSize}px`)
          .attr("color", "black");

        g.append("g")
          .attr("transform", `translate(${margin.left}, 0)`)
          .call(d3.axisLeft(yScale).ticks(5))
          .attr("font-size", `${fontSize}px`)
          .attr("color", "black");
      });
    };

    updateGraph();

    window.addEventListener("resize", updateGraph);
    return () => window.removeEventListener("resize", updateGraph);
  }, [data, height]);

  return <div ref={ref}></div>;
};

export default HistogramGraph;
