import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Use environment variables to securely provide the API key
const API_KEY = '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'

export const articleApi = createApi({
    reducerPath: 'articleApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.openai.com/v1/',
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${API_KEY}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        uploadAudio: builder.mutation({
          query: (audioFile) => {
            let formData = new FormData();
            formData.append('file', audioFile, 'audio.webm'); // Ensure you provide the filename and the format matches your blob type
            formData.append('model', 'whisper-1'); // Use the model provided in the curl example
            
            return {
              url: 'audio/transcriptions',
              method: 'POST',
              body: formData,
            };
          },
        }),
        // Add other endpoints here if needed
    }),
});

export const { useUploadAudioMutation } = articleApi;
