import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./IncomeStatement.css";

const IncomeStatement = ({ data, handleDataChange }) => {
  const tableRef = useRef(null);
  const [frequency, setFrequency] = useState(null); // Set initial state to null

  useEffect(() => {
    console.log("Data received:", data);
    console.log("Frequency:", frequency);

    const preferredFrequency = data.preferred || "quarterly";

    if (frequency === null) {
      setFrequency(preferredFrequency);
    }

    const currentData =
      frequency === "quarterly"
        ? data.quarterly_dataframes
        : data.annually_dataframes;

    console.log("Current Data:", currentData);

    if (currentData) {
      renderTables(currentData);
    } else {
      d3.select(tableRef.current).selectAll("*").remove();
    }
  }, [data, frequency]);

  const renderTables = (currentData) => {
    const container = d3.select(tableRef.current);
    container.selectAll("*").remove(); // Clear previous tables

    if (!currentData || Object.keys(currentData).length === 0) {
      container.append("p").text("No data available for selected frequency.");
      return;
    }

    Object.keys(currentData).forEach((ticker) => {

      const tableContainer = container.append("div").attr("class", "table-container");
      // Añadir título del ticker
      tableContainer
        .append("h2")
        .text(ticker)
        .attr("class", "ticker-header");
    

      const table = container
        .append("table")
        .attr("class", "income-statement-table");
      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      const headers = Object.keys(currentData[ticker][0]); // Assumes data is non-empty
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d);

      // Append table rows
      const rows = currentData[ticker];
      tbody
        .selectAll("tr")
        .data(rows)
        .enter()
        .append("tr")
        .selectAll("td")
        .data((d) => Object.values(d))
        .enter()
        .append("td")
        .text((d) => d);
    });
  };

  return (
    <div>
      <div ref={tableRef} />
      <div className="income-statement-data-type-buttons">
        <button
          className={`income-statement-data-type-button ${
            frequency === "quarterly" ? "active" : ""
          }`}
          onClick={() => {
            setFrequency("quarterly");
            handleDataChange("quarterly");
          }}
        >
          Quarterly
        </button>
        <button
          className={`income-statement-data-type-button ${
            frequency === "annually" ? "active" : ""
          }`}
          onClick={() => {
            setFrequency("annually");
            handleDataChange("annually");
          }}
        >
          Annually
        </button>
      </div>
    </div>
  );
};

export default IncomeStatement;
