import React, { useState, useEffect } from "react";
import axios from "axios";
import { ApiUrl } from "../services/restAPI";
import RingLoader from "react-spinners/RingLoader";
import check from "../assets/check.png";

const ChangePasswordContent = ({ userSpaceFinance }) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retryPassword, setRetryPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  // Ajusta la lógica para habilitar los campos si NO hay email (indicando que no es un usuario de Google)
  const [isChangeEnabled, setIsChangeEnabled] = useState(true);
  const [showSuccessImage, setShowSuccessImage] = useState(false);

  useEffect(() => {
    // La condición ahora verifica si NO hay un email, habilitando el cambio de contraseña para usuarios no Google
    setIsChangeEnabled(!userSpaceFinance.email);
  }, [userSpaceFinance.email]);

  const handleChangePassword = (e) => setPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleRetryPasswordChange = (e) => setRetryPassword(e.target.value);

  const handleSubmit = () => {
    if (!password || !newPassword || !retryPassword) {
      setMessage("All fields are required.");
      return;
    }

    if (newPassword !== retryPassword) {
      setMessage("The new passwords do not match.");
      return;
    }

    setIsSubmitting(true);
    setMessage("");

    const verifyCodeUrl = ApiUrl + "/StartUserLogin/ChangePassword";
    const userName = userSpaceFinance.name;

    axios
      .post(verifyCodeUrl, {
        userName,
        password,
        newPassword,
        retryPassword,
      })
      .then((response) => {
        const { item1, item2 } = response.data;
        if (item2 && item2.token) {
          setMessage("Your password has been successfully reset!");
          // Limpiar los inputs y restablecer el estado
          setPassword("");
          setNewPassword("");
          setRetryPassword("");
          setShowSuccessImage(true);

          // Opcionalmente, también puedes restablecer el estado de isSubmitting aquí si prefieres
          setIsSubmitting(false);
        } else if (item1) {
          setMessage(item1.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setMessage("Error connecting to the API.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}
      <div>
        <div className="menu-item content">Change Password</div>
        {isChangeEnabled ? (
          <>
            <input
              type="password"
              placeholder="Enter old password"
              value={password}
              onChange={handleChangePassword}
            />
            <br />
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <br />
            <input
              type="password"
              placeholder="Re-enter new password"
              value={retryPassword}
              onChange={handleRetryPasswordChange}
            />
            <br />
            <button
              className="button-style-ChangePassword"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Changing..." : "RESET PASSWORD"}
            </button>
          </>
        ) : (
          <div className="message">
            Change password is not available for users logged in with Google.
          </div>
        )}
        {message && <div className="message">{message}</div>}
        {showSuccessImage && (
          <div className="imageCheck">
            <img src={check} alt="Success" style={{ width: 30, height: 30 }} />
          </div>
        )}
      </div>
    </>
  );
};

export default ChangePasswordContent;
