import React from "react";
import "./Halving.css"; 

const BestWorstPerformance = ({
  bestMonths,
  worstMonths,
  bestWeeks,
  worstWeeks,
}) => {
  // Función de ayuda para formatear la fecha
  const formatDateRange = (dateRange) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const start = dateRange.split(" to ")[0];
    const [year, month] = start.split("-");
    return `${months[parseInt(month, 10) - 1]} ${year}`;
  };

  // Function to render table, dynamically handling month/week data
  const renderTable = (data, title, isMonthly) => (
    <>
      <h3>{title}</h3>
      <table className="crypto-halving-table">
        <thead>
          <tr>
            <th></th>
            <th className="table-header">{isMonthly ? "Month" : "Week"}</th>
            <th className="table-header">% Change</th>
            <th className="table-header">Start Price</th>
            <th className="table-header">End Price</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <tr key={index}>
              <td className="bold-text">{entry["Ticker"]}</td>
              <td className="textAlign">{isMonthly ? formatDateRange(entry["Month"]) : entry["Week"]}</td>
              <td className="textAlign">
                {entry["Interval Percentage Change"]
                  ? `${(entry["Interval Percentage Change"]).toFixed(2)}%`
                  : "N/A"}
              </td>
              <td className="textAlign">
                {entry["Start Price"] ? entry["Start Price"].toFixed(2) : "N/A"}
              </td>
              <td className="textAlign">
                {entry["End Price"] ? entry["End Price"].toFixed(2) : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  const hasMonthlyData =
    bestMonths &&
    worstMonths &&
    bestMonths.length > 0 &&
    worstMonths.length > 0;
  const hasWeeklyData =
    bestWeeks && worstWeeks && bestWeeks.length > 0 && worstWeeks.length > 0;

  return (
    <div>
      {hasMonthlyData && (
        <div className="flex-container">
          <div className="flex-item">
            <h2 className="best-monthlyWeekly">Best Monthly</h2>
            <div>{renderTable(bestMonths, "", true)}</div>
          </div>
          <div className="flex-item">
            <h2 className="worst-monthlyWeekly">Worst Monthly</h2>
            <div>{renderTable(worstMonths, "", true)}</div>
          </div>
        </div>
      )}
      {hasWeeklyData && (
        <div className="flex-container">
          <div className="flex-item">
            <h2 className="best-monthlyWeekly">Best Weekly</h2>
            <div>{renderTable(bestWeeks, "", false)}</div>
          </div>
          <div className="flex-item">
            <h2 className="worst-monthlyWeekly">Worst Weekly</h2>
            <div>{renderTable(worstWeeks, "", false)}</div>
          </div>
        </div>
      )}
      {!hasMonthlyData && !hasWeeklyData && (
        <p>No performance data available.</p>
      )}
    </div>
  );
};

export default BestWorstPerformance;
