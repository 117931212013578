import React, { useState, useEffect } from "react";
import axios from "axios";
import { ApiUrl } from "../services/restAPI";
import RingLoader from "react-spinners/RingLoader";

import "./ContactUsPopup.css";

const ContactUsPopup = ({ onClose }) => {
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [contactReasons, setContactReasons] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setIsSubmitting(true);
    const getMessageType = `${ApiUrl}UserMessage/MessageType`;
    axios
      .get(getMessageType) // Asegúrate de reemplazar esta URL con la URL completa de la API
      .then((response) => {
        const reasons = response.data.map((reason) => ({
          id: reason.iD_MessageType,
          name: reason.name,
        }));
        setContactReasons(reasons);
        setIsSubmitting(false);
      })
      .catch((error) => {
        setSubmitError(error);
        setIsSubmitting(false);
      });
  }, []); // El arreglo vacío asegura que este efecto solo se ejecute una vez, cuando el componente se monta.

  const validateInput = () => {
    let errorMessage = "";

    if (!reason) {
      errorMessage += "Please select a reason for contacting us.\n";
    }

    if (!emailIsValid(email)) {
      errorMessage += "Please provide a valid email address.\n";
    }

    if (message.length < 10) {
      errorMessage +=
        "Please ensure the message is at least 10 characters long.\n";
    }

    if (errorMessage) {
      setSubmitError(errorMessage);
      return false;
    }

    return true;
  };

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Definición correcta de handleReasonChange dentro de ContactUsContent
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleMessageChange = (event) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
    setCharCount(newMessage.length);
  };

  const handleSubmit = () => {
    if (!validateInput()) return; // Previene el envío si la validación falla
    setIsSubmitting(true);

    axios
      .post(`${ApiUrl}UserMessage/ExternalContactUs`, {
        userName: email, // Aquí puedes usar el correo electrónico como nombre de usuario
        type: "EX", // Agregamos el parámetro type con el valor "EX"
        category: reason,
        message,
      })
      .then((response) => {
        const { message, numberError } = response.data;
        if (numberError === 0) {
          setSubmitError(
            "Thanks for reaching out! Wishing you a fantastic day!"
          );
          setReason("");
          setMessage("");
          setEmail("");
        } else {
          setSubmitError(message);
        }
      })
      .catch((error) => {
        setSubmitError(
          "An error occurred while sending your message. Please try again."
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Función para manejar el cierre del popup y recargar la página
  const handleCloseAndReload = () => {
    onClose(); // Esta función debería manejar el cierre del popup
    window.location.reload(); // Recarga la página
  };

  return (
    <>
      <div className="contactus-popup">
        <div className="popup-content-contactus">
          <div className="popup-title-contactus">
            <h2>Contact Us</h2>
            <button onClick={handleCloseAndReload} className="popup-close-btn-contactus">
              X
            </button>
          </div>
          {isSubmitting && (
            <div className="chatbot-spinner">
              <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
            </div>
          )}
          <div>
            <p>
              Hello there! Thanks a bunch for reaching out to us. Kindly select
              the reason for your contact and jot down your message in the box
              below. We're eager to hear from you!
            </p>
            <select
              name="contactReason"
              id="contactReason"
              className="select-style-contactus"
              value={reason}
              onChange={handleReasonChange}
            >
              <option value="">Why are you contacting us?</option>
              {contactReasons.map(({ id, name }) => (
                <option key={id} value={name}>
                  {name}
                </option>
              ))}
            </select>
            <br />
            <br />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              className="email-inputContactUs"
            />
            <br />
            <p>Write your message below</p>
            <textarea
              title="spacefinance"
              className="textarea-style-contactus"
              placeholder="Write here, please"
              value={message}
              onChange={handleMessageChange}
              maxLength={500}
            ></textarea>
            <p className="messageCount">Maximum characters: {charCount} / 500</p>            
            <button className="button-style-contactus" onClick={handleSubmit}>
              SEND
            </button>
            {submitError && <div className="message">{submitError}</div>}
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUsPopup;
