import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./AboveBelowTable.css";
import { Tooltip } from "react-tooltip";

const AboveBelowTable = ({ data }) => {
  const tableRef = useRef(null);

  const tooltips = [
    { id: 'Ticker', text: 'Ticker' },
    { id: 'Threshold', text: 'Percentage from which time intervals are measured.' },
    { id: 'IntervalType', text: 'Number of days used to calculate the threshold.' },
    { id: 'AboveDays', text: 'Days within the interval where the asset has exceeded the threshold.' },
    { id: 'BelowDays', text: 'Days within the interval where the asset has fallen below the threshold.' },
    { id: 'TotalDays', text: 'Total interval days within the specified period.' },
    { id: 'ProbAbove', text: 'Percentage of days the asset was above the threshold.' },
    { id: 'ProbBelow', text: 'Percentage of days the asset was below the threshold.' },
    { id: 'StartDate', text: 'Initial date of the specified period.' },
    { id: 'EndDate', text: 'Final date of the specified period.' },
    { id: 'StartPrice', text: 'Start Price' },
    { id: 'EndPrice', text: 'End Price' },
    { id: 'MaxPrice', text: 'Maximum price during the specified period.' },
    { id: 'MinPrice', text: 'Minimum price during the specified period.' },
    { id: 'PercentageChange', text: 'Price variation from start date to end date.' },
  ];

  useEffect(() => {
    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous tables
      container.selectAll("*").remove();

      // Verificar si 'Above Days', 'Below Days', y 'Total Days' están presentes
      const hasDaysData = data["Above Days"] !== undefined && data["Below Days"] !== undefined && data["Total Days"] !== undefined;

      const rowData = [
        data.Ticker,
        data.Threshold,        
        hasDaysData ? data["Interval Type"] : data["Interval Type"],
        hasDaysData ? data["Above Days"] : data["Above Intervals"],
        hasDaysData ? data["Below Days"] : data["Below Intervals"],
        hasDaysData ? data["Total Days"] : data["Total Intervals"],
        data["Probabilities of being Above"],
        data["Probabilities of being Below"],
        data["Start Date"],
        data["End Date"],
        data["Start Price"],
        data["End Price"],
        data["Max Price"],
        data["Min Price"],
        data["Percentage Change"],
      ];

      const table = container
        .append("table")
        .attr("class", "timesTableAbove-table");
      table
        .append("thead")
        .append("tr")
        .selectAll("th")
        .data([
          "Ticker",
          "Threshold",
          "Interval Type",
          hasDaysData ? "Above Days" : "Above Intervals",          
          hasDaysData ? "Below Days" : "Below Intervals",          
          hasDaysData ? "Total Days" : "Total Intervals",                    
          "Probabilities of being Above",
          "Probabilities of being Below",
          "Start Date",
          "End Date",
          "Start Price",
          "End Price",
          "Max Price",
          "Min Price",
          "Percentage Change",
        ])
        .enter()
        .append("th")
        .text((d) => d)
        .attr("data-tooltip-id", (d, i) => tooltips[i].id)
        .attr("data-tooltip-content", (d, i) => tooltips[i].text);

      table
        .append("tbody")
        .append("tr")
        .selectAll("td")
        .data(rowData)
        .enter()
        .append("td")
        .text((d) => d);

      // Append message below the table
      // Append message below the table
      container
        .append("div")
        .attr("class", "info-message")
        .text(
          "The 'Total Days' value is calculated by subtracting weekends from the total days of the year, but it includes festive days. Cryptos are 24/7."
        );

      container.append("div").style("margin-bottom", "20px"); // Add some spacing between tables
    };

    renderTable(data);
  }, [data]);

  const tooltipStyle = {
    backgroundColor: "#438BBA",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "14px"
  };

  return (     
      <div className="time-section-table"  > 
      <div className="timesTableAbove-container" ref={tableRef}></div>
      {/* Add tooltips for table headers */}
      {tooltips.map((tooltip) => (
        <Tooltip key={tooltip.id} id={tooltip.id} place="top" effect="solid" style={tooltipStyle} />
      ))}
    </div> 
  )  
};

export default AboveBelowTable;
