import React, { useRef, useEffect, useState } from "react";

// 4. Stylesheets
import "./DocumentationPopupSideBar.css";

// 5. Static assets like images
/*import Star from "../assets/ourTools/Star.png";
import Tools from "../assets/ourTools/Tools.png";
import Portfolio from "../assets/ourTools/Portfolio.png";
import Annualized from "../assets/ourTools/Annualized.png";
import BalanceSheet from "../assets/ourTools/BalanceSheet.png";
import CashFlow from "../assets/ourTools/CashFlow.png";
import Correlation from "../assets/ourTools/Correlation.png";
import Earnings from "../assets/ourTools/Earnings.png";
import HistoricalPrice from "../assets/ourTools/HistoricalPrice.png";
import IncomeStatement from "../assets/ourTools/IncomeStatement.png";
import PercentageChange from "../assets/ourTools/PercentageChange.png";
import SentimentAnalisis from "../assets/ourTools/SentimentAnalisis.png";
import StandardDeviation from "../assets/ourTools/StandardDeviation.png";
import Times from "../assets/ourTools/Times.png";*/

import Star from "../assets/ourTools/Star.svg";
import Tools from "../assets/ourTools/Tools.svg";
import Portfolio from "../assets/ourTools/Portfolio.svg";
import Annualized from "../assets/ourTools/Annualized.svg";
import BalanceSheet from "../assets/ourTools/BalanceSheet.svg";
import CashFlow from "../assets/ourTools/CashFlow.svg";
import Correlation from "../assets/ourTools/Correlation.svg";
import Earnings from "../assets/ourTools/Earnings.svg";
import HistoricalPrice from "../assets/ourTools/HistoricalPrice.svg";
import IncomeStatement from "../assets/ourTools/IncomeStatement.svg";
import PercentageChange from "../assets/ourTools/PercentageChange.svg";
import SentimentAnalisis from "../assets/ourTools/SentimentAnalisis.svg";
import StandardDeviation from "../assets/ourTools/StandardDeviation.svg";
import Times from "../assets/ourTools/Times.svg";
import Halving from "../assets/ourTools/Halving.svg";
import PE_Ratio from "../assets/ourTools/PE_Ratio.svg";
import MarketCap from "../assets/ourTools/MarketCap.svg";
import Analyst from "../assets/ourTools/Analyst.svg";
import Overview from "../assets/ourTools/Overview.svg";

// Reusable components
const ToolIcon = ({ src, label, title, description, example }) => {
  const tooltipRef = useRef(null); // Ref para el tooltip

  const adjustTooltipPosition = () => {
    const tooltip = tooltipRef.current;
    if (tooltip) {
      const tooltipRect = tooltip.getBoundingClientRect();
      const shiftTooltip = -tooltipRect.left + 50; // Ajusta este valor según sea necesario

      if (tooltipRect.left < 0) {
        tooltip.style.left = `calc(50% + ${shiftTooltip}px)`;
      } else if (tooltipRect.right > window.innerWidth) {
        tooltip.style.left = `calc(50% - ${shiftTooltip}px)`;
      }
    }
  };

  return (
    <div className="tool-Documentation" onMouseEnter={adjustTooltipPosition}>
      <img
        loading="lazy"
        src={src}
        className="tool-icon-Documentation"
        alt={label}
      />
      <div className="tool-label-Documentation">{label}</div>
      {/*<div className="tooltip" ref={tooltipRef}>
        <strong>{title}</strong>
        <br />
        <br />
        {description}
        <br />
        <br />
        <em>{example}</em>
  </div>*/}
    </div>
  );
};

function DocumentationPopupSideBar({ setInputValue }) {
  const [selectedTool, setSelectedTool] = useState();
  useEffect(() => {
    // Set default tool to Portfolio if no other selection exists
    if (!selectedTool) {
      const defaultTool = toolsData.find(
        (tool) => tool.label === "Portfolio Optimization"
      );
      setSelectedTool(defaultTool);
      setInputValue(defaultTool);
    }
  }, [setInputValue, selectedTool]);

  const toolsData = [
    {
      src: Portfolio,
      label: "Portfolio Optimization",
      title: "PORTFOLIO OPTIMIZATION",
      description: (
        <>
          <p>
            The optimization of the portfolio is based on the{" "}
            <strong>Markowitz model</strong>, a fundamental part of investment
            portfolio theory. This model helps us create the best combination of
            assets, taking into account two key factors:
          </p>
          <ol>
            <li>How much money we can earn (return).</li>
            <li>How much risk we are willing to assume.</li>
          </ol>
          <p>
            To achieve this, we determine the{" "}
            <strong>efficient frontier</strong> with the given assets. In other
            words, it is the collection of portfolios that offers the minimum
            risk and maximum return.{" "}
          </p>
          <p>
            To reach this goal, we maximize the portfolio's return based on the
            assets and their average returns, choosing the portfolio considering
            the investor's attitude towards risk.
            <em>
              (Stay tuned; we will explain later how to adjust the risk
              according to your preferences).
            </em>
          </p>
          <p>
            The optimal portfolio is identified as the one that marks the point
            of tangency between the investor's risk attitude and the set of
            optimal portfolios (efficient frontier).
          </p>
          <h3>Risk Metrics:</h3>
          <ol>
            <li>
              Risk Aversion Level: Indicates the investor's willingness to take
              risks. A low level suggests higher aversion, while a high level
              implies a greater willingness to take risks. It is crucial to
              customize the investment strategy based on the investor's risk
              profile.
            </li>
            <li>
              Minimum and Maximum Weight: Sets weighting limits for an asset in
              the portfolio. Helps control exposure to specific assets, avoiding
              excessive concentrations, and diversifying the portfolio.
            </li>
            <li>
              Beta: Evaluates the portfolio's sensitivity to market movements.
              It is essential to understand how the portfolio may behave
              compared to the market under different conditions, helping adjust
              the investment strategy.
            </li>
            <li>
              Sharpe Ratio: Measures risk-adjusted performance, providing a more
              comprehensive view of portfolio efficiency. Investors seek a
              higher Sharpe Ratio as it indicates better performance relative to
              the assumed risk.
            </li>
            <li>
              Standard Deviation: Indicates the historical variability of the
              portfolio's performance. A higher standard deviation suggests
              greater volatility, influencing risk and diversification
              decision-making.
            </li>
            <li>
              Portfolio Volatility: Shows the measure of portfolio volatility,
              enabling investors to understand and manage the risk associated
              with their investments.
            </li>
            <li>
              VAR (Value At Risk): Estimates the maximum expected loss under
              normal market conditions, providing information on potential loss
              scenarios and supporting risk management.
            </li>
          </ol>
          <h3>Return Metrics:</h3>
          <ol>
            <li>
              Alpha: Evaluates risk-adjusted performance compared to the
              benchmark. A positive alpha suggests that the fund manager has
              outperformed the market, crucial for assessing the manager's
              skill.
            </li>
            <li>
              Portfolio Return: Represents the total portfolio performance in a
              specific period, essential for evaluating the success of the
              investment strategy.
            </li>
            <li>
              Expected Portfolio Return: Projects the annualized volatility of
              the portfolio in the indicated period, helping investors estimate
              possible future returns.
            </li>
            <li>
              Benchmark Return: Provides a reference for comparing the
              portfolio's performance, allowing an assessment of its relative
              performance in the market.
            </li>
            <li>
              Benchmark Annualized Return: Provides the annualized volatility of
              the benchmark, facilitating a more accurate comparison of the
              portfolio with the market.
            </li>
            <li>
              Market Risk Premium: Shows the additional compensation the
              investor demands for assuming risks compared to the risk-free
              return. It is crucial for understanding the risk premium
              associated with the portfolio.
            </li>
            <li>
              Risk-Free Rate: Represents the risk-free return, serving as a
              reference to assess whether the portfolio has generated a return
              above the basic risk.
            </li>
          </ol>
          <h3>Drawdown</h3>
          <p>
            It is a measure of investment risk, representing the percentage
            difference between the maximum and minimum portfolio returns.
            Drawdown is crucial for assessing the potential downside risk of an
            investment, providing insights into how much an investment could
            lose during a specific period. This tool is instrumental in aiding
            investors in effective risk management within their portfolios.
          </p>
          <h3>Weights:</h3>
          <p>
            We provide the optimal weights of different assets that the
            portfolio should hold for the specified period to ensure its
            efficiency. Weight analysis is instrumental in constructing a
            well-balanced and efficient portfolio, helping investors determine
            the ideal allocation of assets to achieve desired returns while
            managing risk effectively.
          </p>
          <h3>Risk Contribution:</h3>
          <p>
            Measures the contribution of individual assets to the overall risk
            of the portfolio, considering both weights and the specified time
            period. Understanding risk contribution aids investors in
            identifying and managing the most significant sources of risk within
            their portfolio. It guides decision-making on asset allocation and
            risk mitigation strategies.
          </p>
          <h3>Portfolio Returns:</h3>
          <p>
            A graphical representation illustrating the return of the portfolio
            over the specified period. Portfolio return charts provide a visual
            representation of the investment's performance, helping investors
            track and analyze the overall return trend over time.
          </p>
          <h3>Portfolio vs Benchmark Return:</h3>
          <p>
            A graphical representation comparing the portfolio's return to the
            benchmark return. This chart enables investors to assess how well
            the portfolio has performed relative to a benchmark, providing
            insights into its competitive positioning in the market.
          </p>
          <h3>Covariance Matrix:</h3>
          <p>
            A matrix showing the correlation between different assets,
            indicating their relationships. A correlation of zero means no
            correlation, while a negative correlation suggests an inverse
            relationship. The covariance matrix is a fundamental tool for
            portfolio diversification, helping investors understand how assets
            move in relation to each other and aiding in the creation of
            well-diversified portfolios that mitigate risk through uncorrelated
            or negatively correlated assets.
          </p>
          <p>Remember you can modify this parameters:</p>
          <ol>
            <li>Risk Level.</li>
            <li>Minimum weight.</li>
            <li>Mazimum Weight.</li>
            <li>Benchmark.</li>
            <li>Risk Free rate.</li>
          </ol>
          <p>Easy Level</p>
          <ol>
            <li>
              Optimize portfolio using AAPL, TSLA, MSFT, MDB, SPY, GLD, SAN,
              BTC.c, ETH.c from 2020 to 2021.
            </li>
          </ol>
          <p>Mediaum Level</p>
          <ol>
            <li>
              Optimize my portfolio with the 7 most promising assets from the
              last two years, ensuring each has a minimum weight of 3%, and
              aiming for a risk level of 6.
            </li>
          </ol>
          <p>High Level</p>
          <ol>
            <li>
              Optimize the portfolio with the top ten cryptocurrencies from the
              past two years, setting a minimum weight of 7% and using BTC.c as
              the benchmark.
            </li>
          </ol>
          <p>Expert Level</p>
          <ol>
            <li>
              Optimize the portfolio with AAPL, TSLA, META, SNAP, INTC, IBM,
              SLV, BTC.c, and ETH.c from January 15, 2021, to February 27, 2024,
              setting a minimum weight of 1% and a maximum weight of 85%. Use
              BTC.c as the benchmark and a risk-free rate of 7%, targeting a
              risk level of 7.
            </li>
          </ol>
        </>
      ),
      example: "Optimize my portfolio of AAPL, TSLA, MSFT from 2020 to 2024",
    },
    {
      src: Annualized,
      label: "Annualized Volatility",
      title: "ANNUALIZED VOLATILITY",
      description: (
        <>
          <p>
            The annualized volatility, a fundamental statistical measure,
            assesses the dispersion of investment returns over the course of a
            year. Its calculation involves the standard deviation of daily
            returns multiplied by the square root of 252, taking into account
            business days. Thus, it becomes a crucial tool for evaluating and
            comparing risk among different assets or portfolios. The direct
            relationship between volatility and risk underscores its
            significance; higher volatility corresponds to greater risk
            associated with the asset or portfolio. This understanding enables
            investors to make informed decisions, tailor strategies, and manage
            risks more precisely.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>
              How volatile have AMZN, JNJ, XRP.c been over the past year?
            </li>
            <li>
              What is the annualized volatility of AAPL, TSLA, SAN, GLD, SPY from 2020
              to april 2024.
            </li>
            <li>
              What is the annualized volatility of PG, CL, EL from 2019 to 2023?
            </li>
          </ol>
        </>
      ),
      example:
        "Give me the annualized volatility of AAPL, TSLA, MSFT from 2020 to 2024",
    },
    {
      src: StandardDeviation,
      label: "Standard Deviation",
      title: "STANDARD DEVIATION",
      description: (
        <>
          <p>
            The standard deviation is used to assess the volatility of
            investment returns and is crucial in this context as it provides a
            measure of return variability. The higher the standard deviation,
            the greater the volatility, indicating increased risk associated
            with the investment. As an additional tool, we provide a histogram
            of standard deviations, offering a clear overview of both the most
            and least frequent volatility levels. This provides valuable
            insights into the likelihood of different variations, aiding
            decision-making in risk management and investment strategies.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>What is the standard deviation of AAPL from 2020 to 2023.</li>
            <li>
              What's the level of spread or variance we're seeing in SPY and
              BTC.c from 2020 to 2024?
            </li>
            <li>
              Give me the standard deviation of top 5 cryptos from the last 2 years.
            </li>
          </ol>
        </>
      ),
      example:
        "Give me the standard deviation of AAPL, TSLA, BTC.c, XRP.c from 2020 to 2024",
    },
    {
      src: Times,
      label: "Times",
      title: "TIMES",
      description: (
        <>
          <p>
            Times is the ideal tool for trend analysis as it allows you to
            determine if an asset has experienced an increase greater than a
            specified percentage within the selected time frame. With Times, you
            can assess the probability of the asset rising or falling within the
            specified period, whether daily, weekly, monthly, or at intervals of
            a certain number of days. Additionally, the tool features monthly
            and weekly charts that facilitate a clear and straightforward
            observation of the days and months when the asset is more likely to
            experience fluctuations. In summary, a table is presented with the
            exact days when the asset has risen or fallen by more than the
            indicated percentage. You can also use the "every..." function at
            the end of the tool to specify the days of interest, allowing you to
            check if an asset has increased by more than x% in X days.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>
              How many times AAPL, TSLA, MSFT, META, NVDA, GOOGL, AMZN above 10% monthly from 2020 to 2024.
            </li>
            <li>
              How many times AAPL, TSLA, MSFT, META, NVDA, GOOGL, AMZN above 10% weekly from 2020 to 2024.
            </li>
            <li>
              How many times MDB, DDD, PLTR, SNOW, above 10% over the last quarter.
            </li>
            <li>
              How many times have MDB, DDD, PLTR, SNOW, XRP.c been above 10% over the past year, every 10 days?
            </li>
          </ol>
        </>
      ),
      example: "How many times BTC.c, ETH.c were above 5.5% from 2020 to 2024",
    },
    {
      src: Correlation,
      label: "Correlation",
      title: "CORRELATION",
      description: (
        <>
          <p>
            The correlation matrix is a significant tool in finance that helps
            us understand how different assets in a portfolio relate to each
            other. This tool assigns values between -1 and 1 to describe whether
            assets tend to move together (positive correlation (1)), in opposite
            directions (negative correlation (-1)), or independently
            (correlation close to 0). In other words, it assists us in
            diversifying our portfolio by selecting assets that don't move in
            the same way, allowing us to mitigate risk.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>
              Could you provide the correlation fo KO, PEP over the last year?
            </li>
            <li>Can your analyze de correlation between NFLX, DIS from 2022 to 2024.</li>
            <li>
              Can you provide a correlation for the pharma companies over the last 3 years?
            </li>
          </ol>
        </>
      ),
      example:
        "Give me the correlation of AAPL, TSLA, BTC.c, ETH.c from 2020 to 2024",
    },
    {
      src: HistoricalPrice,
      label: "Historical Price",
      title: "HISTORICAL PRICE",
      description: (
        <>
          <p>
            For historical ETFs and stock data across various assets, you'll be
            able to view detailed information such as the opening price, high,
            low, adjusted price, volume, percentage change, as well as details
            on distributed dividends and their amounts, along with information
            about potential stock splits. For cryptocurrencies, we provide
            metrics like the 24-hour volume, daily percentage change, percentage
            change in total supply, and data on newly mined coins.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>Give me the Price of BTC.c, ETH.c over the last two weeks.</li>
            <li>
              What is the Price of AAPL, SAN, WMT from january 17 to february 2
              2023.
            </li>
            <li>What is the Price of MSFT, INTC on april 2023.</li>
          </ol>
        </>
      ),
      example:
        "Give me the historical price of AAPL, TSLA, BTC.c, ETH.c from January 1, 2023 to January 15, 2024",
    },
    {
      src: PercentageChange,
      label: "Percentage Change",
      title: "PERCENTAGE CHANGE",
      description: (
        <>
          <p>
            Percentage change is a fundamental measure in finance that indicates
            the relative variation in the value of an asset over a specific
            period. Calculated as the percentage difference between the initial
            and final value of the asset during that time, this metric provides
            a quick assessment of the relative profitability or loss of an
            investment. A positive percentage change signals an increase in the
            asset's value, while a negative one indicates a decrease. This tool
            is essential for investors as it allows for effective monitoring of
            the financial health of an investment and informed decision-making
            regarding its retention, sale, or purchase in a portfolio. In
            summary, percentage change is a key measure for evaluating the
            relative performance of an investment over a specific period.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>What were the returns of the top banks in the last quarter?</li>
            <li>
              What is the performance of AAPL, TSLA, SAN, GLD, SPY from 2020 to
              april 2024.
            </li>
          </ol>
        </>
      ),
      example:
        "Give me the Percentage change of AAPL, DDD, MSFT from 2020 to 2024",
    },
    {
      src: BalanceSheet,
      label: "Balance Sheet",
      title: "BALANCE SHEET",
      description: (
        <>
          <p>
            Is one of the fundamental financial statements used by companies to
            provide a snapshot of their financial position at a specific point
            in time. This document reflects the basic accounting equation:
          </p>
          <ol>
            <li>Assets=Liabilities+Equity.</li>
          </ol>
          <p>Here is an explanation of the key sections of a Balance Sheet:</p>
          <ol>
            <li>
              Assets: Represent everything the company owns and has value. They
              are divided into two main categories:
            </li>
            <ul>
              <li>
                Current Assets: Resources expected to be converted into cash or
                used in the short term (examples: cash, accounts receivable,
                inventory).
              </li>
              <li>
                Non-Current Assets (or Fixed Assets): Long-term resources, such
                as properties, machinery, patents, used for long-term
                operations.
              </li>
            </ul>
            <li>
              Liabilities: Represent the financial obligations and debts of the
              company. They are also divided into two categories:
            </li>
            <ul>
              <li>
                Current Liabilities: Debts and obligations that must be paid in
                the short term (examples: accounts payable, short-term loans).
              </li>
              <li>
                Non-Current Liabilities: Long-term debts and obligations, such
                as long-term loans.
              </li>
            </ul>
            <li>
              Equity: Also known as "Owner's Equity" or "Shareholder's Equity,"
              it represents the shareholders' investment in the company. It is
              calculated as the difference between assets and liabilities.
            </li>
          </ol>
          <p>
            The Balance Sheet provides key information about a company's
            financial strength and its ability to meet its obligations.
            Investors and analysts use this financial statement to assess the
            financial health, liquidity, and borrowing capacity of a company.
            Additionally, it helps understand the financing structure and
            financial resource management.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>Balance Sheet of AAPL, MSFT, META.</li>
            <li>Balance Sheet GS, C, BAC, JPM.</li>
            <li>Balance Sheet FSLR, NEE, BEP, SPWR, ENPH.</li>
          </ol>
        </>
      ),
      example: "Give me balance sheet of JPM, C, SAN",
    },
    {
      src: IncomeStatement,
      label: "Income Statement",
      title: "INCOME STATEMENT",
      description: (
        <>
          <p>
            Is another fundamental financial statement that provides a detailed
            overview of a company's financial performance over a specific
            period, typically a quarter or a year. This document outlines how
            revenues were generated and how expenses were incurred, resulting in
            net profit or loss.
          </p>
          <p>
            Here's an explanation of the key sections of an Income Statement:
          </p>
          <ol>
            <li>
              Revenues (Sales): Represents the money generated from the sale of
              goods or services. It may include both operating and non-operating
              revenues.
            </li>
            <li>
              Cost of Goods Sold (COGS): Corresponds to the direct costs
              associated with the production of goods or services sold. It
              includes the cost of raw materials, direct labor, and related
              expenses.
            </li>
            <li>
              Gross Profit: Obtained by subtracting the cost of goods sold from
              revenues. It is the profit before deducting other expenses.
            </li>
            <li>
              Operating Expenses: Include costs such as salaries, rent,
              utilities, and other expenses related to the business's
              operations.Other Income and Expenses: Encompasses non-operating
              income and expenses, such as investments, interest, and asset
              sales.
            </li>
            <li>
              Profit Before Taxes : Is the sum of operating profit and
              non-operating income and expenses.
            </li>
            <li>
              Income Taxes: Represent the taxes the company must pay on its
              profits.
            </li>
            <li>
              Net Profit: Obtained by subtracting income taxes from profit
              before taxes. It is the final net gain left for shareholders.
            </li>
          </ol>
          <p>
            The Income Statement is crucial for assessing a company's
            profitability and understanding how its operations contribute to its
            financial results.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>Income statement of MCD, YUM, WSR, CMG, DPZ.</li>
            <li>Income Statement JNJ, PFE, MRK, UNH, ABT.</li>
            <li>Income Statement TSLA, DIS, NKE, V, PG.</li>
          </ol>
        </>
      ),
      example: "Give me income statement of META, ORCL, NVDA, TSLA",
    },
    {
      src: CashFlow,
      label: "Cash Flow",
      title: "CASH FLOW",
      description: (
        <>
          <p>
            This statement provides vital information about the liquidity and
            the ability of a company to generate and utilize cash.
          </p>
          <p>
            Here's an explanation of each key section of the cash flow
            statement:
          </p>
          <ol>
            <li>
              Operating Cash Flow: Represents the cash inflows and outflows
              related to the company's core operational activities. It includes
              cash generated from sales of goods and services, as well as
              payments to suppliers, employees, and other operational expenses.
            </li>
            <li>
              Investing Cash Flow: Records cash movements related to the
              purchase and sale of long-term assets, such as properties,
              equipment, and securities. Investments also include loans granted
              to third parties and the purchase of stakes in other companies.
            </li>
            <li>
              Financing Cash Flow: Reflects cash transactions with owners and
              creditors. This includes the issuance and repurchase of stocks, as
              well as the borrowing or repayment of loans and debts.
            </li>
            <li>
              Net Change in Cash: Is the sum of the three components above and
              represents the net variation in the company's cash position during
              the period.
            </li>
            <li>
              Beginning and Ending Cash: Shows the amount of cash with which the
              company started and ended the period.
            </li>
          </ol>
          <p>
            The cash flow statement is crucial for assessing the financial
            health of a company and its ability to meet financial obligations.
            Unlike other financial statements, the cash flow statement focuses
            on actual cash movements, providing a clearer view of liquidity and
            the company's ability to make investments, pay debts, and distribute
            dividends.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>CashFLow WMT, COST, AMZN, HD.</li>
            <li>CashFlow LMT, NOC, RTX, GD, LHX.</li>
            <li>Cashflow AMT, PLD, SPG, EQIX, O.</li>
          </ol>
        </>
      ),
      example: "Give me the cashflow of DDD, NIO, AVAV",
    },
    {
      src: Earnings,
      label: "Earnings",
      title: "EARNINGS",
      description: (
        <>
          <p>
            The tool provides financial results of companies compared to
            previous expectations. It calculates the surprise percentage by
            expressing the difference between actual and expected results.
            Additionally, it provides the exact date of earnings reports.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>Give me the earnings of DDOG, CPNG, DIS.</li>
            <li>Give me the earnings of GOOGL, AAPL, TSLA.</li>
            <li>Give me the earnings of UBER, LYFT.</li>
          </ol>
        </>
      ),
      example: "Give me earnings of SSYS, FL, MDB",
    },
    {
      src: SentimentAnalisis,
      label: "Sentiment Analysis",
      title: "SENTIMENT ANALYSIS",
      description: (
        <>
          <p>
            The tool conducts a sentiment analysis based on news related to the
            queried asset. It assesses the tone of each news article, assigning
            a score corresponding to its sentiment: bearish (0-20), somewhat
            bearish (20-40), neutral (40-60), somewhat bullish (60-80), and
            bullish (80-100). To derive the overall score, the tool calculates
            the average score of the 50 analyzed articles. Additionally, a
            breakdown of the articles with their respective scores and sentiment
            categories is provided. The tool also includes direct links to the
            articles for further review.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>Sentiment analysis of JNJ.</li>
            <li>Sentiment analysis of ADBE.</li>
          </ol>
        </>
      ),
      example: "Sentiment analysis of TSLA, META, AAPL",
    },
    {
      src: Halving,
      label: "Halving",
      title: "HALVING",
      description: (
        <>
          <p>
            The Bitcoin halving is a fundamental event in the network of this
            cryptocurrency that occurs approximately every four years. During
            this event, the rate of new bitcoins issuance is halved, meaning the
            amount of bitcoins that miners receive as a reward for verifying
            transactions and securing the network is reduced by half. This
            process was implemented in the Bitcoin protocol since its creation
            in 2009 and is designed to occur every 210,000 mined blocks, which
            typically equates to around four years.
          </p>
          <p>
            The halving of the miners' reward is a key aspect of the Bitcoin
            halving. Initially, when Bitcoin was created, the reward for mining
            a block was 50 bitcoins. However, with each halving, this reward is
            halved. After the first halving in 2012, the reward was reduced to
            25 bitcoins per block. Then, in 2016, the second halving occurred,
            reducing the reward to 12.5 bitcoins. The last halving took place in
            May 2020, when the reward was reduced to 6.25 bitcoins per block. In
            this 2024 halving, the reward is reduced to 3.125 bitcoins per
            block.
          </p>
          <p>
            With this tool, our platform allows you to explore monthly and
            weekly changes in cryptocurrencies, stocks, and ETFs. In addition to
            providing essential data such as market dominance, capitalization,
            and other key indicators, you also have access to information about
            changes in Bitcoin rewards and fundamental cryptocurrency market
            data. Alongside this, we offer tabular summaries to easily identify
            changes and predict trends. This provides you with a comprehensive
            view of market evolution, helps you identify the best and worst
            months/weeks, and enables you to make informed decisions about your
            investments with the most relevant information available in one
            place.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>Halving of the top 10 most important cryptos.</li>
            <li>Halving of SPY, GLD, BTC.c, ETH.c.</li>
            <li>Halving ADA.c, XRP.c, SOL.c.</li>
          </ol>
        </>
      ),
      example:
        "bitcoin Halving with BTC.c, ETH.c, AAPL, DDD, monthly or weekly",
    },
    {
      src: PE_Ratio,
      label: "PE Ratio",
      title: "PE Ratio",
      description: (
        <>
          <p>This tool provides two main metrics:</p>
          <ol>
            <li>PE Ratio.</li>
            <li>Dividend Yield.</li>
          </ol>

          <p>
            P/E Ratio (Price-to-Earnings Ratio) is a key metric in financial
            analysis that measures the relationship between a stock's price and
            its earnings per share (EPS). This ratio provides an assessment of a
            stock's historical performance compared to other companies in the
            same sector or the overall market.
          </p>
          <p>Some important points about the P/E Ratio are:</p>
          <ol>
            <li>
              It is calculated by dividing the current stock price by the
              earnings per share.
            </li>
            <li>
              A high P/E Ratio may indicate that a company's stock is overvalued
              or that investors have high growth expectations.
            </li>
            <li>
              It's important to note that companies that do not generate
              earnings or have losses do not have a P/E Ratio, as there are no
              earnings to divide.
            </li>
            <li>
              It is recommended to use this tool in conjunction with several
              companies in the same sector, as the P/E Ratio makes sense when
              compared to similar companies.
            </li>
          </ol>
          <p>
            It also provides information on the "dividend yield," which refers
            to the dividend yield. This indicator is calculated as the quotient
            between the dividend per share (DPS) and the market price of that
            share, multiplied by 100 (thus, this ratio is measured in
            percentage).
          </p>
          <p>The formula to calculate the P/E Ratio is: Stock Price / EPS.</p>

          <p>Here some examples:</p>
          <ol>
            <li>
              Give me the P/E ratio of JPM, BAC, V, MA, WFC, GS, MS, AXP, BLK.
            </li>
            <li>
              What is the P/E ratio of NEE, DUK, SO, D, AEP, EXC, XEL, PEG, ED,
              and WEC.
            </li>
            <li>What is the P/E Ratio of UPS, FDX, and DE?</li>
          </ol>
          <p>
            Warning: This tool is only available for stocks. It does not work
            for cryptocurrencies and ETF.
          </p>
        </>
      ),
      example:
        "Give me the P/E ratio of JPM, BAC, V, MA, WFC, GS, MS, AXP, BLK.",
    },
    {
      src: MarketCap,
      label: "MarketCap",
      title: "MarketCap",
      description: (
        <>
          <p>
            In this tool, you will obtain information about the price of the
            selected stock, as well as about the outstanding shares and the
            market cap. Market Capitalization represents the total value of all
            outstanding shares of a company, calculated by multiplying the
            current stock price by the total number of outstanding shares.
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>Give me the market cap of UNP, HON, GE, DE.</li>
            <li>What is the market capitalization of PYPL, MA, V.</li>
            <li>What is the market cap of DIS and NFLX.</li>
          </ol>
        </>
      ),
      example:
        "Warning: This tool is only available for stocks. It does not work for cryptocurrencies and ETF",
    },
    {
      src: Analyst,
      label: "Analyst",
      title: "Analyst",
      description: (
        <>
          <p>
            Analyst analyzes expert comments on selected stocks and provides the
            potential price, calculated as the average of prices estimated by
            analysts. This potential price is presented in the context of
            analyst opinions, indicating how many foresee a buy, hold, or sell
            for the Price.
          </p>
          <p>
            It's important to note that this doesn't constitute a
            recommendation, as the potential price is the weighted average of
            analysts' estimates and could be incorrect.{" "}
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>
              What do the experts say about AAPL, TSLA, MSFT, AMZN, and META?
            </li>
            <li>
              What are analysts saying about LSTR, R, JBHT, XPO, FDX, and UPS?
            </li>
            <li>What's the opinion on CCL, RCL, and NCLH?</li>
          </ol>
          <p>
            Warning: This tool is only available for stocks. It does not work
            for cryptocurrencies and ETF.
          </p>
        </>
      ),
      example:
        "What do the experts say about AAPL, TSLA, MSFT, AMZN, and META?",
    },
    {
      src: Overview,
      label: "Overview",
      title: "Overview",
      description: (
        <>
          <p>
            The Overview tool allows you to have a comprehensive understanding
            of one or multiple companies. In this tool, you can find:
          </p>
          <ol>
            <li>
              <b>Description:</b> A brief overview of the company.
            </li>
            <li>
              <b>Sector:</b> The industry sector to which it belongs.
            </li>
            <li>
              <b>Market Cap:</b> The company's value in the financial market.
            </li>
            <li>
              <b>Outstanding Shares:</b> The number of shares the company has in
              circulation.
            </li>
            <li>
              <b>EBITDA:</b> This is a financial indicator of the company's
              profitability, excluding taxes, interest, depreciation, and
              amortization.
            </li>
            <li>
              <b>P/E Ratio:</b> It measures the relationship between the stock
              price and earnings per share, useful for assessing whether the
              stock is overvalued or undervalued.
            </li>

            <li>
              <b>Book Value:</b> This is the company's accounting value,
              representing the amount of money that would remain if the company
              liquidated all assets and paid off its debts.Profit margin TTM:
              The profit margin TTM represents the operating profit margin for
              the trailing twelve months, indicating the percentage of revenue
              converted into profit during this period.
            </li>
            <li>
              <b>Operating margin TTM:</b> The operating margin TTM reflects the
              operating profit margin for the trailing twelve months,
              illustrating the percentage of revenue remaining after deducting
              operating expenses during this period.
            </li>
            <li>
              <b>ROA:</b> Known as return on assets, it measures how much the
              total assets of the company are yielding. It is calculated as the
              net profit divided by the total assets times one hundred.
            </li>
            <li>
              <b>ROE:</b> It measures the profitability of a company in relation
              to the equity of its shareholders. Essentially, ROE indicates how
              efficiently a company is using shareholders' money to generate
              profits. This indicator is of great importance to investors as it
              provides them with an idea of how their investment is being
              managed.
            </li>
            <li>
              <b>Price to book Ratio:</b> The P/B ratio tells us at what prices
              a company trades in relation to its equity. Typically, companies
              trade at prices higher than their book value, that is, at a P/B
              ratio &gt; 1. This is because a company's stock price reflects
              expectations about its ability to generate profits. In contrast,
              when a company trades below its book value (P/B &lt; 1), it may
              indicate that its assets are overvalued. In other words, the
              company's ability to generate profits may have diminished
              (compared to the initial estimate). However, a ratio lower than 1
              can also be interpreted as stocks being undervalued.
            </li>
            <li>
              <b>EV Revenue:</b> stands for Enterprise Value divided by EBITDA.
              Enterprise Value represents the market capitalization, that is,
              the company's value on the stock market, plus net debt. This ratio
              measures the multiplier of the company's value over the resources
              it generates, regardless of its financial structure (thus
              excluding interest, taxes, and amortization). It assesses whether
              the company adds more or less value than directly tied to the
              generated resources, indicating the efficiency of its management.
            </li>
            <li>
              <b>EV EBITDA:</b> refers to Enterprise Value over EBITDA, with
              Enterprise Value being the market capitalization, that is, the
              company's value on the stock market, plus net debt. This ratio
              shows the multiplier of the company's value over the resources it
              generates, irrespective of its financial structure (hence
              excluding interest, taxes, and amortization). It determines
              whether the company incorporates more or less value than directly
              related to the generated resources, thus indicating whether the
              company's management is superior or inferior to the results
              obtained.{" "}
            </li>
          </ol>
          <p>
            Warning: This tool is only available for stocks. It does not work
            for cryptocurrencies and ETF
          </p>
          <p>Here some examples:</p>
          <ol>
            <li>Give me the overview of XOM, CVX, COP, SLB, OXY.</li>
            <li>What is the overview of NVDA, ADBE, PYPL, CRM, TXN.</li>
            <li>Privide an overview DIS, NKE, SBUX, MCD.</li>
          </ol>
        </>
      ),
      example: "Give me the overview of XOM, CVX, COP, SLB, OXY",
    },
  ];

  const handleDoubleClick = (tool) => {
    setSelectedTool(tool);
    setInputValue(tool);
  };

  return (
    <>
      <aside className="sidebar-documentation">
        <header className="header-Documentation">
          SF
          <br />
        </header>
        {/* Título destacado con imagen */}
        <div className="title-container-Documentation">
          <img
            src={Tools}
            alt="Our Tools"
            className="tools-image-Documentation"
          />
          <h2 className="tools-title-Documentation">OUR TOOLS</h2>
        </div>
        <div className="tools-Documentation">
          {toolsData.map((tool) => (
            <div key={
              tool.label} 
              onClick={() => handleDoubleClick(tool)}
              className={selectedTool && selectedTool.label === tool.label ? "selected-tool" : ""}>
              <ToolIcon
                src={tool.src}
                label={tool.label}
                title={tool.title}
                description={tool.description}
                example={tool.example}
              />
            </div>
          ))}
        </div>
      </aside>
    </>
  );
}

export default DocumentationPopupSideBar;
