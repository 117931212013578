import React, { useEffect, useState, useRef } from "react";
import Popup from "./SidebarPopup";
import ChatBotUserProfile from "../loginUserProfile/ChatbotUserProfile";
import "./Sidebar.css";
import Star from "../assets/ourTools/Star.svg";
import Tools from "../assets/ourTools/Tools.svg";
import Portfolio from "../assets/ourTools/Portfolio.svg";
import Annualized from "../assets/ourTools/Annualized.svg";
import BalanceSheet from "../assets/ourTools/BalanceSheet.svg";
import CashFlow from "../assets/ourTools/CashFlow.svg";
import Correlation from "../assets/ourTools/Correlation.svg";
import Earnings from "../assets/ourTools/Earnings.svg";
import HistoricalPrice from "../assets/ourTools/HistoricalPrice.svg";
import IncomeStatement from "../assets/ourTools/IncomeStatement.svg";
import PercentageChange from "../assets/ourTools/PercentageChange.svg";
import SentimentAnalisis from "../assets/ourTools/SentimentAnalisis.svg";
import StandardDeviation from "../assets/ourTools/StandardDeviation.svg";
import Times from "../assets/ourTools/Times.svg";
import Halving from "../assets/ourTools/Halving.svg";
import SFLogo from "../assets/ourTools/SF_Logo.svg";
import PE_Ratio from "../assets/ourTools/PE_Ratio.svg";
import MarketCap from "../assets/ourTools/MarketCap.svg";
import Analyst from "../assets/ourTools/Analyst.svg";
import Overview from "../assets/ourTools/Overview.svg";

const InstructionStep = ({ id, text, onClick }) => {
  return (
    <div className="instruction-step" onClick={onClick}>
      <span className="instruction-number">{id}. </span>
      <span className="instruction-text">{text}</span>
    </div>
  );
};

function Sidebar({ setInputValue, userSpaceFinance }) {
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [popupTitle, setPopupTitle] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedGroups, setExpandedGroups] = useState({});
  const [clickTimeouts, setClickTimeouts] = useState({});
  const [sidebarVisible, setSidebarVisible] = useState(true);
  
  const sidebarRef = useRef(null);

  const handleInstructionClick = (title, content) => {
    setPopupTitle(title);
    setPopupContent(content);
    setPopupVisible(true);
  };

  const instructionsData = [
    {
      id: 1,
      text: "Use tickers for companies, ETFs, and crypto, separating them with commas.",
      tooltipContent:
        "Examples: APPL, TSLA, MSFT, MDB, NVDA, BTC.C, ETH.C, DOGE.C, GLD, SLV",
    },
    {
      id: 2,
      text: "Add '.c' to crypto tickers (e.g., BTC.c, ETH.c).",
      tooltipContent: "Examples: BTC.c , ETH.c, XRP.c, ADA.c",
    },
    {
      id: 3,
      text: "Crypto info from January 2020.",
      tooltipContent: "Data is available from January 2020 to the present.",
    },
    {
      id: 4,
      text: "Remember: Eugene works with voice too!",
      tooltipContent:
        "Voice Messaging Interface: Microphone icon (Start recording), Send icon (Send voice message), Trash icon (Cancel recording and delete it)",
    },
  ];

  const toolsData = [
    {
      group: "Main Info",
      tools: [
        {
          src: PE_Ratio,
          label: "PE Ratio",
          title: "PE Ratio",
          description: (
            <>
              The P/E Ratio (Price-to-Earnings Ratio) is a metric that evaluates the
              relationship between a company's stock price and its expected earnings
              per share.
              <br />
              <br />
              P/E Ratio = Market Value per Share / EPS
              <br />
              <br />
              This ratio provides key information to assess the relative value of a
              company's stock compared to others in the same sector.
              <br />
              <br />
              The P/E Ratio is a crucial tool for determining whether a company is
              overvalued or undervalued. It is compared to the industry average and
              similar companies to gain a clearer perspective. This ratio indicates
              how many dollars investors are willing to pay for each dollar of the
              company's earnings. Therefore, it is known as a price multiple, as it
              shows the amount investors pay for each unit of earnings. For example,
              if a company has a P/E Ratio of 20x, it means investors are willing to
              pay $20 for every $1 of current earnings.
              <br />
              <br />
              <strong>Here some examples:</strong>
              <ul>
                <li>What is the PE Ratio of INTC, NVDA, AMD, QCOM, TXN.</li>
                <li>
                  Which of these companies has the highest P/E ratio? META, SNAP,
                  PINS, MTCH.
                </li>
                <li>Give me the PE ratio of PFE, JNJ, MKR, ABBV, BMY.</li>
              </ul>
              Warning: This<strong> tool is only available for stocks.</strong> It
              does not work for cryptocurrencies and ETF.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "What is the PE Ratio of INTC, NVDA, AMD, QCOM, TXN.",
        },
        {
          src: MarketCap,
          label: "MarketCap",
          title: "MarketCap",
          description: (
            <>
              Market Cap measures the size of the company in the financial market.
              It is calculated by multiplying the current price of the shares by the
              total number of shares that the company has in the stock market.
              <br />
              <br />
              <strong>Here some examples:</strong>
              <ul>
                <li>
                  Give me the market cap of AAPL, TSLA, NVDA, META, AMZN, GOOGL,
                  MSFT.
                </li>
                <li>What is the market cap of BA, LMT, NOC, RTX, GD.</li>
                <li>
                  Which company has a higher market capitalization, AAPL or MSFT?
                </li>
              </ul>
              Warning: This<strong> tool is only available for stocks and cryptocurrencies.</strong> It does not work for ETF.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example:
            "Give me the market cap of AAPL, TSLA, NVDA, META, AMZN, GOOGL, MSFT.",
        },        
        {
          src: Overview,
          label: "Overview",
          title: "Overview",
          description: (
            <>
              The Overview tool provides a comprehensive view of the company,
              facilitating its comparison with others in the market. It allows
              obtaining a complete overview of the company's details, relevant basic
              information, key metrics of profitability and valuation, as well as
              its operational and financial performance. Additionally, it provides
              detailed metrics on profitability and risk, along with a thorough
              evaluation of the company.
              <br />
              <br />
              <strong>Here some examples:</strong>
              <ul>
                <li>Give me the overview of MCD, YUM, PLAY.</li>
                <li>What is the overview of PLTR, AVAV.</li>
                <li>General information about LMT, RTX, NOC.</li>
              </ul>
              Warning: This<strong> tool is only available for stocks.</strong> It
              does not work for cryptocurrencies and ETF.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "Give me the overview of MCD, YUM, PLAY.",
        },
        {
          src: HistoricalPrice,
          label: "Historical Price",
          title: "HISTORICAL PRICE",
          description: (
            <>
              Discover the historical prices of a wide range of assets on our
              platform:
              <ul>
                <li>
                  <strong>For stocks:</strong> You'll have access to data such as
                  current price, high, low, and trading volume, as well as
                  information on dividends and splits.
                </li>
                <li>
                  <strong>For ETFs:</strong> You'll find details on trading volume,
                  current price, high, low, and percentage changes.
                </li>
                <li>
                  <strong>For cryptocurrencies:</strong> We provide information on
                  current price, total supply, trading volume, and changes in
                  supply, including coins mined daily.
                </li>
              </ul>
              <strong>Example:</strong> Historical Price of BTC.c, GLD, and SPY for
              the last month.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "Historical Price of BTC.c, GLD, and SPY for the last month.",
        },
    
      ],
    },
    {
      group: "Sentiment",
      tools: [
        {
          src: SentimentAnalisis,
          label: "Sentiment Analysis",
          title: "SENTIMENT ANALYSIS",
          description: (
            <>
              Explore our Sentiment Analysis tool to gauge market sentiment
              associated with various assets. We analyze 50 news articles to assess
              sentiment, which you can find in our News section. Each article
              receives a score indicating sentiment, and clicking "Read More"
              provides further insight. Sentiment is classified as bearish, somewhat
              bearish, neutral, somewhat bullish, or bullish. Bar charts display the
              number of articles per sentiment category, while pie charts illustrate
              the corresponding percentages.
              <br />
              <br />
              <strong>Example:</strong> Sentiment analysis of AAPL.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "Sentiment analysis of AAPL.",
        },
        {
          src: Analyst,
          label: "Analyst ratings",
          title: "Analyst ratings",
          description: (
            <>
              Analyst ratings provides opinions from various analysts about selected
              assets, allowing you to gain insights from industry experts. It
              presents the potential price, which is the average of prices estimated
              by the analysts. It's important to note that this isn't a
              recommendation, but rather an average estimate, and therefore, it may
              not materialize. <br />
              <br />
              Use these insights to make informed decisions and steer yourself
              towards success. Considering these opinions in your investment
              decisions offers you an additional and diverse perspective on the
              selected assets.
              <br />
              <strong>Here some examples:</strong>
              <ul>
                <li>What do analysts think about UBER and LYFT?</li>
                <li>What do experts think about TSLA, NIO, F, GM.</li>
                <li>Opinion of analyst about TSLA, MSFT, NVDA?</li>
              </ul>
              Warning: This<strong> tool is only available for stocks.</strong> It
              does not work for cryptocurrencies and ETF.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "What do analysts think about UBER and LYFT?",
        },
    
    
      ],
    },
    {
      group: "Diversification",
      tools: [
        {
          src: Portfolio,
          label: "Portfolio Optimization",
          title: "PORTFOLIO OPTIMIZATION",
          description: (
            <>
              Portfolio optimization aims to balance profitability and risk by
              assigning appropriate weights to each asset. This tool provides
              metrics such as beta, sharpe ratio, volatility, VAR, and alpha. You
              can adjust parameters such as:
              <ul>
                <li>
                  <strong>
                    Risk aversion (1 is very risky, 10 is very conservative)
                  </strong>
                </li>
                <li>
                  <strong>Maximum weight</strong>
                </li>
                <li>
                  <strong>Minimum weight</strong>
                </li>
                <li>
                  <strong>Benchmark</strong>
                </li>
                <li>
                  <strong>Risk free rate</strong>
                </li>
              </ul>
              Here are examples of how to customize it:
              <ul>
                <li>
                  <strong>Easy Level:</strong> Optimize the portfolio of SAN, TLRY,
                  BTC.c, ETH.c, GLD, SLV from 2020 to 2024.
                </li>
                <li>
                  <strong>Medium Level:</strong> Optimize the portfolio of SAN,
                  TLRY, BTC.c, ETH.c, GLD, SLV from 2020 to 2024 with
                  benchmark BTC.c.
                </li>
                <li>
                  <strong>Expert Level:</strong> Optimize the portfolio of SAN,
                  TLRY, BTC.c, ETH.c, GLD, SLV from 2020 to 2024 with a risk
                  aversion of 7, minimum weight of 10%, maximum weight of 70%, using
                  BTC.c as a benchmark.
                </li>
              </ul>
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example:
            "Optimize the portfolio of SAN, TLRY, BTC.c, ETH.c, GLD, SLV from 2020 to 2024.",
        },    
        {
          src: Correlation,
          label: "Correlation",
          title: "CORRELATION",
          description: (
            <>
              Correlation measures the similarity between two or more companies by
              evaluating their behavior over a defined period. A higher correlation
              value, with 1 being the maximum, indicates a greater resemblance in
              the behavior of these companies, while a value of -1 signifies
              divergent behavior. This metric is crucial for understanding how the
              actions of one company might impact another within a portfolio.
              <ul>
                <li>
                  <strong>Example:</strong> Give me the correlation between JPM, C,
                  BAC, WMT, and KO over the last three weeks.
                </li>
              </ul>
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example:
            "Give me the correlation between JPM, C, BAC, WMT, and KO over the last three weeks.",
        },  
    
      ],
    },
    {
      group: "Tendency",
      tools: [
        {
          src: Times,
          label: "Times",
          title: "TIMES",
          description: (
            <>
              This tool helps track market trends by counting how often an asset has
              changed by a certain percentage within a set timeframe. It's perfect
              for identifying market patterns and trends, offering the flexibility
              to customize the observation period to suit your preference, whether
              it's daily, weekly, monthly, or any other interval.
              <ul>
                <li>
                  <strong>Daily Example:</strong> How many times BTC.c, ETH.c, AAPL,
                  TSLA above 5% from January 5, 2022, to February 15, 2024?
                </li>
                <li>
                  <strong>Weekly Example:</strong> How many times BTC.c, ETH.c,
                  AAPL, TSLA above 5% weekly from January 5, 2022, to February 15,
                  2024?
                </li>
                <li>
                  <strong>Monthly Example:</strong> How many times BTC.c, ETH.c,
                  AAPL, TSLA above 5% monthly from January 5, 2022, to February 15,
                  2024?
                </li>
                <li>
                  <strong>Every X Days Example:</strong> How many times BTC.c,
                  ETH.c, AAPL, TSLA above 5% from January 5, 2022, to February 15,
                  2024, every 12 days?
                </li>
              </ul>
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example:
            "How many times BTC.c, ETH.c, AAPL, TSLA above 5% from January 5, 2022, to February 15, 2024?",
        },
        {
          src: Halving,
          label: "Halving",
          title: "HALVING",
          description: (
            <>
              The halving event, occurring every four years, halves the rewards for
              Bitcoin miners and historically leads to significant price increases.
              Our platform allows you to explore monthly and weekly trends for
              cryptocurrencies, stocks, and ETFs, alongside essential data like
              market dominance and capitalization, as well as changes in Bitcoin
              rewards.
              <ul>
                <li>
                  <strong>Weekly Example:</strong> Give me the halving of BTC.c,
                  ETH.c, MSFT, META weekly.
                </li>
                <li>
                  <strong>Monthly Example:</strong> Give me the halving of BTC.c,
                  ETH.c, MSFT, META monthly.
                </li>
              </ul>
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "Give me the halving of BTC.c, ETH.c, MSFT, META weekly.",
        },
    
    
      ],
    },
    {
      group: "Volatility",
      tools: [
        {
          src: StandardDeviation,
          label: "Standard Deviation",
          title: "STANDARD DEVIATION",
          description: (
            <>
              Standard deviation measures the dispersion of one or multiple assets'
              returns and is used to assess its volatility. A higher standard
              deviation indicates greater variability in returns, implying a higher
              level of risk. This indicator is crucial for evaluating the historical
              stability and predictability of a stock.
              <br />
              <br />
              <strong>Example:</strong> Give me the Standard Deviation of TSLA,
              AAPL, AMZN, NVDA, PLTR over the last two years”.
              <br />
              <br />
              Remember, <strong>double-click</strong>on the tool to get a
              ready-to-use example.
            </>
          ),
          example:
            "Give me the Standard Deviation of TSLA, AAPL, AMZN, NVDA, PLTR last two years.",
        },    
        {
          src: Annualized,
          label: "Annualized Volatility",
          title: "ANNUALIZED VOLATILITY",
          description: (
            <>
              Annualized volatility reflects the variability of one or several
              assets on the selected date over a period of time. This indicator
              helps understand how fluctuant a stock has been when considered over
              the course of a year. Higher volatility indicates more significant
              price movements, which can be interpreted as increased risk.
              <br />
              <br />
              <strong>Example:</strong> Give me the annualized volatility of TSLA,
              AAPL, AMZN, NVDA, PLTR last year.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example:
            "Give me the annualized volatility of TSLA, AAPL, AMZN, NVDA, PLTR last year.",
        },
        {
          src: PercentageChange,
          label: "Percentage Change",
          title: "PERCENTAGE CHANGE",
          description: (
            <>
              It displays the percentage change of one or multiple assets over a
              selected period. This indicator provides a clear view of how the value
              of assets has varied in relative terms, enabling you to measure its
              performance.
              <br />
              <br />
              <strong>Example:</strong> Give me the percentage change of TSLA, AMZN,
              ADBE, BTC.c, GLD over the last quarter.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example:
            "Give me the percentage change of TSLA, AMZN, ADBE, BTC.c, GLD over the last quarter.",
        },
    
      ],
    },
    {
      group: "Financial Statement",
      tools: [
        {
          src: BalanceSheet,
          label: "Balance Sheet",
          title: "BALANCE SHEET",
          description: (
            <>
              Explore the balance sheet to view the quarterly and annual assets,
              liabilities, and shareholders' equity of one or multiple companies.
              <br />
              <br />
              <strong>Example:</strong> Give me the balance sheet of C, GS, SAN.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "Give me the balance sheet of C, GS, SAN.",
        },
        {
          src: IncomeStatement,
          label: "Income Statement",
          title: "INCOME STATEMENT",
          description: (
            <>
              Explore the income statement to view sales, gross margin, expenses,
              and more up to the net income for both quarterly and annual periods of
              one or multiple companies.
              <br />
              <br />
              <strong>Example:</strong> Give me the income statement of JNJ, PFE.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "Give me the income statement of JNJ, PFE",
        },
        {
          src: CashFlow,
          label: "Cash Flow",
          title: "CASH FLOW",
          description: (
            <>
              Explore the cash flow, which measures a company's liquidity through
              this formula:
              <em>Cashflow = Profit + Depreciation + Provisions.</em>
              <br />
              <br />
              <strong>Example:</strong> Give me the cashflow of META, MSFT.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "Give me the cashflow of META, MSFT.",
        },
        {
          src: Earnings,
          label: "Earnings",
          title: "EARNINGS",
          description: (
            <>
              Showcases the financial results of one or multiple companies upon
              presenting their reports, along with the percentage of deviation from
              expectation and the exact date of publication, among other data.
              <br />
              <br />
              <strong>Example:</strong> Give me the earnings of MCD, YUM.
              <br />
              <br />
              Remember, <strong>double-click</strong> on the tool to get a
              ready-to-use example.
            </>
          ),
          example: "Give me the earnings of MCD, YUM.",
        },
    
      ],
    },
    // Add more groups and tools here...
  ];

  const handleSingleClick = (title, description) => {
    setPopupTitle(title);
    setPopupContent(description);
    setPopupVisible(true);
  };

  const handleDoubleClick = (example) => {
    setInputValue(example);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredToolsData = toolsData.map((group) => {
    const filteredTools = group.tools.filter((tool) =>
      tool.label.toLowerCase().includes(searchTerm)
    );
    return { ...group, tools: filteredTools };
  });

  const toggleGroup = (groupName) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const handleClick = (title, description, example) => {
    if (clickTimeouts[title]) {
      clearTimeout(clickTimeouts[title]);
      setClickTimeouts((prev) => {
        const newTimeouts = { ...prev };
        delete newTimeouts[title];
        return newTimeouts;
      });
      handleDoubleClick(example);
    } else {
      const timeout = setTimeout(() => {
        handleSingleClick(title, description);
        setClickTimeouts((prev) => {
          const newTimeouts = { ...prev };
          delete newTimeouts[title];
          return newTimeouts;
        });
      }, 250);
      setClickTimeouts((prev) => ({
        ...prev,
        [title]: timeout,
      }));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (window.innerWidth > 1000) {
        setSidebarVisible(true);
      } else {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setSidebarVisible((prev) => !prev);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setSidebarVisible(true);
      } else {
        setSidebarVisible(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Agregar los event listeners después de que el componente se monte
    document.querySelectorAll(".tool").forEach((tool) => {
      tool.addEventListener("mouseenter", function (event) {
        const tooltip = this.querySelector(".tooltip");
        const rect = this.getBoundingClientRect();
        tooltip.style.left = `${rect.left + window.scrollX}px`;
        tooltip.style.top = `${rect.bottom + window.scrollY + 5}px`; // 5px abajo del elemento
      });
    });
  }, []); // El array vacío asegura que el efecto solo se ejecute una vez

  useEffect(() => {
    const adjustSidebarHeight = () => {
      const footerHeight = document.querySelector(".footer-pp").offsetHeight;
      const sidebar = document.querySelector(".sidebar");
      sidebar.style.maxHeight = `calc(200vh - ${footerHeight}px)`;
    };

    window.addEventListener("resize", adjustSidebarHeight);
    return () => window.removeEventListener("resize", adjustSidebarHeight);
  }, []);

  return (
    <>
      <button
        className="hamburger-menu"        
      >
        &#9776;
      </button>
      {popupVisible && (
        <Popup title={popupTitle} onClose={() => setPopupVisible(false)}>
          {popupContent}
        </Popup>
      )}
      <aside ref={sidebarRef} className={`sidebar ${sidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
        <div className="sidebar-content">
          <header className="header">
            <img loading="lazy" src={SFLogo} className="imageLogo" />
          </header>
          <section className="instructions">
            <div className="title-container">
              <img loading="lazy" src={Star} className="tools-image" />
              <h2 className="tools-title">TIPS</h2>
            </div>
            <div>
              {instructionsData.map(({ id, text, tooltipContent }) => (
                <InstructionStep
                  key={id}
                  id={id}
                  text={text}
                  onClick={() => handleInstructionClick(text, tooltipContent)}
                />
              ))}
            </div>
          </section>
          <div className="title-container">
            <img src={Tools} alt="Our Tools" className="tools-image" />
            <h2 className="tools-title">OUR TOOLS</h2>
          </div>
          <input
            type="text"
            placeholder="Search Tools"
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-box"
          />
          <div className="tools">
            {filteredToolsData.map(
              ({ group, tools }) =>
                tools.length > 0 && (
                  <div key={group} className="tool-group">
                    <div
                      className="group-header"
                      onClick={() => toggleGroup(group)}
                    >
                      {group}
                    </div>
                    {expandedGroups[group] &&
                      tools.map(({ src, label, title, description, example }) => (
                        <div
                          key={label}
                          onClick={() => handleClick(title, description, example)}
                          className="toolDescription"
                        >
                          <img src={src} className="tool-icon" alt={label} />
                          <div className="tool-label">{label}</div>
                        </div>
                      ))}
                  </div>
                )
            )}
          </div>
        </div>
        <ChatBotUserProfile userSpaceFinance={userSpaceFinance} />
      </aside>
    </>
  );
}

export default Sidebar;
