import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import Paper from "@mui/material/Paper";
import "./NewsTable.css";

const NewsTable = ({ data }) => {
  const containerRef = useRef(null);

  console.log("NewsTable Rendered");
  console.log("Data in NewsTable:", data);

  const renderNews = (containerRef, data) => {
    const container = d3.select(containerRef.current);
    container.selectAll("*").remove();

    const {
      banner_images,
      titles,
      urls,
      dates,
      summaries,
      sources,

      source_domains,
      sentiment_labels,
      sentiment_scores2,
    } = data;

    if (banner_images && banner_images.length > 0) {
      banner_images.forEach((bannerImage, index) => {
        const newsItem = container.append("div").attr("class", "news-item");

        // Display image
        newsItem
          .append("img")
          .attr("src", bannerImage)
          .attr("alt", "Banner")
          .attr("style", "max-width:500px; max-height:150px;");

        // Display title
        newsItem
          .append("h2")
          .text(titles[index])
          .style("font-weight", "bold")                    
          .style("color", "#ACEBFF");

        // Display other data
        newsItem
          .append("a")
          .attr("href", urls[index])
          .attr("target", "_blank")
          .text("Read more")
          .style("color", "#ACEBFF");

        newsItem
          .append("p")
          .html(`<strong>Date:</strong> 
          <span style="color:#ACEBFF; padding: 2px;">${dates[index]}</span>`);

        newsItem
          .append("p")
          .html(`<strong>Summary:</strong> 
          <span style="color:#ACEBFF; padding: 2px;">${summaries[index]}</span>`);          

        newsItem
          .append("p")
          .html(`<strong>Source:</strong> 
          <span style="color:#ACEBFF; padding: 2px;">${sources[index]}</span>`);

        newsItem
          .append("p")
          .html(`<strong>Source Domain:</strong> 
          <span style="color:#ACEBFF; padding: 2px;">${source_domains[index]}</span>`);

        newsItem
          .append("p")
          .html(
            `<strong>Sentiment Score:</strong> 
            <span style="color:#ACEBFF; padding: 2px;">${sentiment_scores2[index].toFixed(2)}</span>`);

        // Determine sentiment color and apply
        const sentiment = sentiment_labels[index];
        let sentimentColor;
        switch (sentiment) {
          case "Bearish":
            sentimentColor = "#FC6A6A";
            break;
          case "Somewhat-Bearish":
            sentimentColor = "#FFC6C6";
            break;
          case "Neutral":
            sentimentColor = "#D7DBDB";
            break;
          case "Somewhat-Bullish":
            sentimentColor = "#B6F2BA";
            break;
          case "Bullish":
            sentimentColor = "#86F75D";
            break;
          default:
            sentimentColor = "black";
        }

        // Display sentiment with background color
        newsItem
          .append("p")
          .html(
            `<strong>Sentiment:</strong> 
            <span style="background-color:${sentimentColor}; padding: 3px; border-radius: 5px; color:black">${sentiment}</span>`          
          );
      });
    }
  };

  useEffect(() => {
    renderNews(containerRef, data);
  }, [data]);

  return (
    <Paper elevation={3} className="news-container">
      <div ref={containerRef} className="scrollable"></div>
    </Paper>
  );
};

export default NewsTable;
