import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./MonthAboveBelow.css";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthAboveBelow = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    const cleanup = () => {
      d3.select(".tooltip").remove();
    };

    if (!data) return cleanup;

    const container = d3.select(ref.current);
    container.selectAll("*").remove();

    Object.entries(data).forEach(([key, tickerData], index) => {
      const aboveData = months.map(
        (month) => tickerData.above_months[month] || 0
      );
      const belowData = months.map(
        (month) => tickerData.below_months[month] || 0
      );

      // Create a container for each pair of graphs
      const tickerContainer = container
        .append("div")
        .classed("ticker-container", true) // Added class for styling
        .style("display", "flex");

      createBarGraph(
        tickerContainer,
        key,
        "Above: Daily increases in the month",
        aboveData,
        "#47C712"
      );
      createBarGraph(
        tickerContainer,
        key,
        "Below: Daily decreases in the month",
        belowData,
        "#F94040"
      );
    });

    return cleanup;
  }, [data]);

  const createBarGraph = (tickerContainer, ticker, title, data, color) => {
    const margin = { top: 20, right: 35, bottom: 40, left: 40 };
    const width = 345 - margin.left - margin.right;
    const height = 200 - margin.top - margin.bottom;

    // Create a container for the title and the graph
    const graphContainer = tickerContainer
      .append("div")
      .classed("graph-container-Mounth", true) // Added class for styling
      .style("flex-direction", "column"); // Adjusted style

    graphContainer
      .append("h4")
      .text(`${ticker} - ${title}`)
      .style("color", color)
      .style("margin-left", "5px")
      .style("text-align", "left");

    /*const svgContainer = graphContainer
      .append("svg")
      .attr("width", width + margin.left + margin.right + 100)
      .attr("height", height + margin.top + margin.bottom);*/

    const containerWidth = ref.current.clientWidth; // Obtener el ancho del contenedor
    const svgContainer = graphContainer
      .append("svg")
      .attr("width", containerWidth + 140)
      .attr("height", height + margin.top + margin.bottom);

    const svg = svgContainer
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .domain(months)
      .range([0, width - 15])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data)])
      .nice()
      .range([height, 0]);

    // Creación de tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("className", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "#238bb0")
      .style("color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // Establecer un número adecuado de ticks basado en la altura del gráfico
    const numTicks = Math.floor(height / 20); // Ajusta este divisor según necesidades

    // Ajustar el eje Y para mostrar solo números enteros
    const yAxis = d3
      .axisLeft(y)
      .tickFormat(d3.format("d"))
      .tickValues(
        d3.range(0, d3.max(data) + 1, Math.ceil((d3.max(data) + 1) / numTicks))
      );
    svg.append("g").call(yAxis);
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d, i) => x(months[i]))
      .attr("y", (d) => y(d))
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - y(d))
      .attr("fill", color)
      .attr("rx", 7) // Radio de bordes redondeados en el eje x
      .attr("ry", 7) // Radio de bordes redondeados en el eje y
      .on("mouseover", function (event, d) {
        tooltip
          .html("Valor: " + d)
          .style("opacity", 1)
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY + 10 + "px");
      })
      .on("mousemove", function (event) {
        tooltip
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY + 10 + "px");
      })
      .on("mouseout", function () {
        tooltip.style("opacity", 0);
      });
  };

  return <div className="monthAboveBelowStyle" ref={ref} />;
};

export default MonthAboveBelow;
