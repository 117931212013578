// LoginForgotPassword.js
import React, { useState } from "react";
import "./Login.css"; // Reutilizamos los estilos de Login.css
import { ApiUrl } from "../services/restAPI";
import axios from "axios";
import { Link } from "react-router-dom";
import LoginSendCode from "./LoginSendCode";
import RingLoader from "react-spinners/RingLoader";

const LoginForgotPassword = ({ onBackToLogin }) => {
  const [userName, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showLoginSendCode, setShowLoginSendCode] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleResetPassword = async () => {
    try {
      if (!emailRegex.test(userName)) {
        setErrorMessage("Incorrect email format.");
        return;
      }

      setIsSubmitting(true);

      const urlAPI = ApiUrl + "StartUserLogin/forgotPassword";
      const response = await axios.post(urlAPI, { userName });

      if (response.status === 200) {
        setIsSubmitting(false);
        const { item1, item2 } = response.data;

        if (item2) {
          // Si item2 es un código válido, muestra el componente LoginSendCode
          setShowLoginSendCode(true);
          setErrorMessage(""); // Limpia cualquier mensaje de error previo
        } else {
          // Si item2 es 0, muestra el mensaje de error de item1
          setErrorMessage(item1.message);
          setShowLoginSendCode(false); // No muestra LoginSendCode en caso de error
        }
      } else {
        setErrorMessage("You can't change the password with this email since it's not the one you used as a user.");
        setShowLoginSendCode(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("You can't change the password with this email since it's not the one you used as a user.");
      setShowLoginSendCode(false);
      setIsSubmitting(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
    setSuccessMessage("");
  };

  if (showLoginSendCode) {
    return <LoginSendCode userName={userName} />;
  }

  return (
    <>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}
      <div className="login fadeInDown">
        <div className="wrapper">
          <div id="formContent">
            <div className="login-right">
              <div className="fadeIn first">
                <h2 className="forgot-password-header">
                  Forgot your password?
                </h2>
                <p className="forgot-password-text">
                  {successMessage ||
                    "Please provide your email address, and we'll send you instructions on how to reset your password."}
                </p>
              </div>
              <input
                type="email"
                className="fadeIn second"
                placeholder="Email address"
                value={userName}
                onChange={handleEmailChange}
              />
              <div className="fadeIn third">
                <button
                  className="reset-password-button"
                  onClick={handleResetPassword}
                  disabled={!emailRegex.test(userName)} // Deshabilitar botón si el correo no es válido
                >
                  Continue
                </button>
              </div>
              <div className="text-left-FP">
                <div className="fadeIn fourth">
                  <a 
                    href="#"                     
                    onClick={(e) => {
                      e.preventDefault();
                      onBackToLogin();
                    }}
                    className="link-style">
                    Back to SpaceFinance
                  </a>
                </div>
              </div>
              {errorMessage && (
                <div id="formFooter">
                  <div className="alert-danger">{errorMessage}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForgotPassword;
