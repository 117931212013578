import React, { useState, useEffect, useRef } from 'react';
import { useUploadAudioMutation } from './articleApi'; // Adjust the path as necessary
import "./AudioRecorder.css"

const AudioRecorder = ({ onRecordingComplete, onRecordingStart, onRecordingCancel, isStartRecordingButtonDisabled, isStopRecordingButtonDisabled, isCancelRecordingButtonDisabled }) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(null);
  const audioChunksRef = useRef([]);

  // RTK Query mutation hook for uploading audio
  const [uploadAudio, { isLoading, isSuccess, isError, error }] = useUploadAudioMutation();

  useEffect(() => {
    // Clean up the media stream and timer when the component is unmounted
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
      }
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [mediaRecorder, timer]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
      recorder.ondataavailable = (e) => {
        audioChunksRef.current.push(e.data);
      };
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
      onRecordingStart();

      // Start a 30-second timer
      const newTimer = setTimeout(cancelRecording, 30000);
      setTimer(newTimer);
    } catch (error) {
      console.error('Error starting the recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      // Clear the timer
      clearTimeout(timer);
      setTimer(null);

      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        audioChunksRef.current = [];
        setIsRecording(false);

        uploadAudio(audioBlob).unwrap()
          .then((response) => {
            const transcribedText = response.text;
            onRecordingComplete(transcribedText);
          })
          .catch((uploadError) => {
            console.error('Upload failed:', uploadError);
          });
      };
    }
  };
  const cancelRecording = () => {
    if (mediaRecorder) {
      // Clear the timer
      clearTimeout(timer);
      setTimer(null);
  
      if (mediaRecorder.state === 'recording') {
        mediaRecorder.onstop = () => {}; // Remove the onstop event handler
        mediaRecorder.onerror = () => {}; // Remove the onerror event handler
        mediaRecorder.ondataavailable = () => {}; // Remove the ondataavailable event handler
  
        // Stop the media recorder and release the media stream
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        mediaRecorder.stop();
      }
  
      // Reset the audio chunks
      audioChunksRef.current = [];
  
      // Reset the recording state
      setIsRecording(false);
  
      // Notify the parent component that recording was canceled
      onRecordingCancel();
    }
  };  
  
  return (
    <div className="audio-recorder-container">
      <button 
          className={`audio-recorder-button ${isRecording ? 'recording' : ''}`}
          onClick={startRecording}         
          disabled={isStartRecordingButtonDisabled || isRecording}
          title="Record audio">            
      </button>      
      <button 
        className="audio-recorderstop-button"
        onClick={stopRecording} 
        disabled={!isRecording}
        title="Send audio">        
      </button>
      <button        
        className="audio-recordercancel-button" 
        onClick={cancelRecording} 
        disabled={!isRecording || isCancelRecordingButtonDisabled}
        title="Cancel audio">        
      </button>
      {isLoading && <p></p>}
      {isSuccess && <p></p>}
      {isError && <p> {error.toString()}</p>}
    </div>
  );
};

export default AudioRecorder;
