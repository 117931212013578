import React, { useState, useEffect } from "react";
import "./Halving.css";

// Constantes para fechas importantes y colores de resaltado
const HALVING_DATES = ["2024-04-20", "2020-05-11"]; // Fechas de halving
const ETF_APPROVAL_DATES = ["2024-01-10"]; // Fechas de aprobación del ETF
const HALVING_COLOR = "#09f"; // Color para el halving
const ETF_COLOR = "#f39a3e"; // Color para la aprobación del ETF

const MatrixHalving = ({ type, data }) => {
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest("#formContentHalving")) {
        setDetail(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCellClick = (ticker, detailData) => {
    // Set the ticker along with the detail data
    setDetail({ ticker, ...detailData });
  };

  const handleCloseDetail = () => {
    // Reset detail to null to close the detail view
    setDetail(null);
  };

  // Función de ayuda para formatear la fecha
  const formatDateRange = (dateRange) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [year, month] = dateRange.split("-"); // Asumiendo un formato de entrada "YYYY-MM"
    return `${months[parseInt(month, 10) - 1]} ${year}`;
  };

  // Función de ayuda para formatear la fecha y comparar con los rangos
  const isDateInRange = (dateRange, targetDates, isWeekly) => {
    if (isWeekly) {
      return targetDates.some((date) => {
        const [start, end] = dateRange.split(" to ");
        return date >= start && date <= end;
      });
    } else {
      const monthRange = dateRange.substring(0, 7); // Extraer YYYY-MM del rango
      return targetDates.some((date) => date.startsWith(monthRange));
    }
  };

  // Función para formatear el valor según las condiciones dadas
  const formatPrice = (price) => {
    return price > 1 ? price.toFixed(2) : price;
  };

  const renderDetails = () =>
    detail && (
      <div id="formContentHalving" className="wrapper">
        {" "}
        {/* Añadido className para estilos adicionales */}
        {/* Cabecera fija */}
        <div id="popupHeader">
          <h4>{detail.ticker}</h4>
          <p>
            <b>Start Price:</b> ${formatPrice(Number(detail["Start Price"]))}
          </p>
          <p>
            <b>End Price:</b> ${formatPrice(Number(detail["End Price"]))}
          </p>
          {detail["Daily Data"] && detail["Daily Data"][0] && (
            <p>
              <b>Date Range:</b> {detail["Daily Data"][0]["Date Range"]}
            </p>
          )}
          {detail["Interval Percentage Change"] && (
            <p>
              <b>Percentage Change:</b>{" "}
              {Number(detail["Interval Percentage Change"]).toFixed(2)}%
            </p>
          )}
        </div>
        {/* Contenido desplazable */}
        <div id="popupContent">
          {/* Aquí puedes insertar más contenido detallado que desees que sea desplazable */}
          <h4>Daily Data:</h4>
          {detail["Daily Data"] && (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Price</th>
                  <th>Percentage Change</th>
                </tr>
              </thead>
              <tbody>
                {detail["Daily Data"].map((daily, index) => (
                  <tr key={index}>
                    <td>{daily.Date}</td>
                    <td>${formatPrice(Number(daily.Price))}</td>
                    <td>
                      {Number(daily["Daily Percentage Change"]).toFixed(2) ??
                        "N/A"}
                      %
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {/* Botón para cerrar el popup *
        <button className="popup-close-btn" onClick={handleCloseDetail}>
          X
        </button>*/}
      </div>
    );

  const renderMatrix = (periodData, periodTitle, isWeekly) => {
    let dateRanges = [];
    Object.values(periodData).forEach((tickerData) => {
      if (tickerData.weekly_intervals_df) {
        tickerData.weekly_intervals_df.forEach((interval) => {
          if (!dateRanges.includes(interval["Date Range"])) {
            dateRanges.push(interval["Date Range"]);
          }
        });
      }
      if (tickerData.monthly_intervals_df) {
        tickerData.monthly_intervals_df.forEach((interval) => {
          if (!dateRanges.includes(interval["Date Range"])) {
            dateRanges.push(interval["Date Range"]);
          }
        });
      }
    });
    dateRanges.sort(); // Sort date ranges chronologically

    const hasWeeklyData = Object.values(periodData).some(
      (tickerData) => tickerData.weekly_intervals_df
    );
    const hasMonthlyData = Object.values(periodData).some(
      (tickerData) => tickerData.monthly_intervals_df
    );

    return (
      <>
        {hasWeeklyData && (
          <h2 className="crypto-halving-subtitle">{type} - Weekly</h2>
        )}
        {hasMonthlyData && (
          <h2 className="crypto-halving-subtitle">{type} - Monthly</h2>
        )}
        <table className="fixed-width-table">
          <thead>
            <tr>
              <th></th> {/* Espacio para el encabezado de las filas */}
              {dateRanges.map((dateRange) => {
                const isHalvingDate = isDateInRange(
                  dateRange,
                  HALVING_DATES,
                  isWeekly
                );
                const isEtfDate = isDateInRange(
                  dateRange,
                  ETF_APPROVAL_DATES,
                  isWeekly
                );
                const bgColor = isHalvingDate
                  ? HALVING_COLOR
                  : isEtfDate
                  ? ETF_COLOR
                  : "transparent";
                const textColor =
                  isHalvingDate || isEtfDate ? "#FFFFFF" : "#133588"; // Texto blanco para fechas destacadas, negro de lo contrario

                return (
                  <th
                    key={dateRange}
                    style={{ backgroundColor: bgColor, color: textColor }}
                  >
                    <b>{isWeekly ? dateRange : formatDateRange(dateRange)}</b>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Object.entries(periodData).map(([ticker, tickerData]) => (
              <tr key={ticker}>
                <td>
                  <b>{ticker}</b>
                </td>
                {dateRanges.map((dateRange) => {
                  const halvingHighlight = isDateInRange(
                    dateRange,
                    HALVING_DATES,
                    isWeekly
                  )
                    ? HALVING_COLOR
                    : "";
                  const etfHighlight = isDateInRange(
                    dateRange,
                    ETF_APPROVAL_DATES,
                    isWeekly
                  )
                    ? ETF_COLOR
                    : "";
                  const textColor =
                    halvingHighlight || etfHighlight ? "#FFFFFF" : "#133588";
                  const boldText =
                    halvingHighlight || etfHighlight ? "bold" : "500";
                  const bgColor = halvingHighlight || etfHighlight;
                  let intervalData;
                  if (tickerData.weekly_intervals_df) {
                    intervalData = tickerData.weekly_intervals_df.find(
                      (interval) => interval["Date Range"] === dateRange
                    );
                  } else if (tickerData.monthly_intervals_df) {
                    intervalData = tickerData.monthly_intervals_df.find(
                      (interval) => interval["Date Range"] === dateRange
                    );
                  }
                  // Establecer el color de fondo basado en las fechas importantes
                  if (HALVING_DATES.includes(dateRange)) {
                    bgColor = HALVING_COLOR;
                  } else if (ETF_APPROVAL_DATES.includes(dateRange)) {
                    bgColor = ETF_COLOR;
                  }

                  return (
                    <td
                      key={dateRange}
                      onClick={() =>
                        intervalData && handleCellClick(ticker, intervalData)
                      }
                      style={{
                        backgroundColor: bgColor,
                        color: textColor,
                        fontWeight: boldText,
                      }}
                    >
                      {intervalData
                        ? `${
                            intervalData["Interval Percentage Change"]?.toFixed(
                              2
                            ) ?? "N/A"
                          }%`
                        : "N/A"}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div>
      {renderDetails()} {/* Render details above the tables */}
      {data.weekly && renderMatrix(data.weekly, "Weekly", true)}
      {data.monthly && renderMatrix(data.monthly, "Monthly", false)}
    </div>
  );
};

export default MatrixHalving;
