import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
import { ApiUrl } from "../services/restAPI";

const LoginResetPassword = ({ userName }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = () => {
    setIsSubmitting(false);
    setErrorMessage("");
    setSuccessMessage("");
  
    // Validar que las contraseñas coincidan
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setIsSubmitting(false);
      return;
    }
  
    let resetPasswordUrl = ApiUrl + "StartUserLogin/ResetPassword"; // URL de la API para resetear la contraseña
  
    axios.post(resetPasswordUrl, { userName, newPassword: password, retryPassword: confirmPassword }).then((response) => {
        setIsSubmitting(false);
        const { item1, item2 } = response.data;
  
        if (item2) {
          // Si item2 contiene datos, significa que la contraseña se ha restablecido con éxito
          setSuccessMessage("Password reset successful.");
          setIsAuthenticated(true);
          
          window.location.href = "/"; // Cambia "app.js" por la URL correcta de tu página principal
          return null; // Asegúrate de devolver null para evitar renderizar nada más en este componente

        } else if (item1) {
          // Si item1 contiene datos, significa que se ha producido un error, muestra ese mensaje
          setErrorMessage(item1.message);
          setIsAuthenticated(false);
        } else {
          // Si no hay datos en item1 ni item2, muestra un mensaje genérico de error
          setErrorMessage("Error connecting to the API to reset the password.");
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Error connecting to the API to reset the password.");
        setIsAuthenticated(false);
      });
  };  

  const isButtonDisabled = password === "" || confirmPassword === "";  

  return (
    <div className="login fadeInDown">
      <div className="wrapper">
        <div id="formContent">
          <div className="login-right">
            <div className="fadeIn first">
              <h2 className="forgot-password-header">Reset Password</h2>
              <p className="forgot-password-text">
                {successMessage ||
                  "Enter a new password below to change your password."}
              </p>
            </div>
            <input
              type="password"
              className="fadeIn second input-style"
              placeholder="New Password"
              value={password}
              onChange={handlePasswordChange}
            />
            <input
              type="password"
              className="fadeIn third input-style"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <button
              onClick={handleResetPassword}
              disabled={isSubmitting || isButtonDisabled}
              className="fadeIn fourth reset-password-button"
            >
              Reset Password
            </button>
            {errorMessage && (
              <div id="formFooter">
                <div className="alert-danger">{errorMessage}</div>
              </div>
            )}            
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginResetPassword;
