import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./HistoricalPrices.css";

const HistoricalPrices = ({ dataString, cryptoData }) => {
  const tableRef = useRef(null);
  const stockTableRef = useRef(null);
  const cryptoTableRef = useRef(null);

  useEffect(() => {
    const parseData = (dataStr) => {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(dataStr, "text/html");
      const headers = Array.from(htmlDoc.querySelectorAll("th")).map(
        (th) => th.textContent
      );
      const rows = Array.from(htmlDoc.querySelectorAll("tbody tr")).map((tr) =>
        Array.from(tr.querySelectorAll("td")).map((td) => td.textContent)
      );

      return { headers, rows };
    };

    // Function to render table
    const renderTable = (data, headers, containerRef) => {
      const container = d3.select(containerRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      const table = container
        .append("table")
        .attr("class", "historical-prices-table");

      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d);

      // Append table rows
      tbody
        .selectAll("tr")
        .data(data)
        .enter()
        .append("tr")
        .selectAll("td")
        .data((d) => d)
        .enter()
        .append("td")
        .text((d) => d);
    };

    // Render stock data
    if (dataString) {
      const { headers: stockHeaders, rows: stockRows } = parseData(dataString);
      renderTable(stockRows, stockHeaders, stockTableRef);
    }

    // Render crypto data
    if (cryptoData) {
      const { headers: cryptoHeaders, rows: cryptoRows } =
        parseData(cryptoData);
      renderTable(cryptoRows, cryptoHeaders, cryptoTableRef);
    }
  }, [dataString, cryptoData]);

  return (
    <>
      <div ref={stockTableRef} />
      <div ref={cryptoTableRef} />
    </>
  );
};

export default HistoricalPrices;
