import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function ClusterComponent({ cluster_labels }) {
  const ref = useRef();

  useEffect(() => {
    if (!cluster_labels || !cluster_labels.Cluster) return;

    const svg = d3.select(ref.current);
    const width = 600;
    const height = 400;
    const padding = 30;

    svg.attr("width", width)
       .attr("height", height);

    const uniqueClusters = [...new Set(Object.values(cluster_labels.Cluster))];
    const colorScale = d3.scaleOrdinal().domain(uniqueClusters).range(d3.schemeCategory10);

    const data = Object.entries(cluster_labels.Cluster).map(([stock, cluster]) => ({
      stock,
      cluster,
    }));

    svg.selectAll('*').remove();

    // Calculating the center for each cluster
    const clusterCenters = uniqueClusters.map((d, i) => {
      const angle = (i / uniqueClusters.length) * Math.PI * 2;
      return {
        x: (width / 2) + Math.cos(angle) * (width / 4),
        y: (height / 2) + Math.sin(angle) * (height / 4),
      }
    });

    // Define the radius based on the number of clusters
    const radius = Math.min(width, height) / (2 * uniqueClusters.length);

    // Positioning each point in a circular fashion within its cluster
    data.forEach((d) => {
      const center = clusterCenters[uniqueClusters.indexOf(d.cluster)];
      const angle = Math.random() * Math.PI * 2;
      d.x = center.x + Math.cos(angle) * radius;
      d.y = center.y + Math.sin(angle) * radius;
    });

    // Define force simulation to avoid overlap
    const simulation = d3.forceSimulation(data)
      .force("x", d3.forceX(d => d.x).strength(1))
      .force("y", d3.forceY(d => d.y).strength(1))
      .force("collide", d3.forceCollide(15))
      .stop();

    for (let i = 0; i < 300; ++i) simulation.tick();

    svg.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", d => d.x)
      .attr("cy", d => d.y)
      .attr("r", 10)
      .attr("fill", d => colorScale(d.cluster))
      .append("title")
      .text(d => d.stock);

    svg.selectAll("text")
      .data(data)
      .enter()
      .append("text")
      .attr("x", d => d.x)
      .attr("y", d => d.y)
      .attr("dy", -12)
      .attr("text-anchor", "middle")
      .text(d => d.stock)
      .style("font-family", "Inter")
      .style("font-size", 12)
      .style("fill", "#133588");

  }, [cluster_labels]);

  return (
    <div className="cluster-visualization">
      <svg ref={ref}></svg>
    </div>
  );
}

export default ClusterComponent;
