import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./AboveBelowDataFrames.css";

const AboveBelowDataFrames = ({ data, isCrypto }) => {
  const aboveTableRef = useRef(null);
  const belowTableRef = useRef(null);

  // Funciones de ayuda para el formateo
  const formatDate = (dateStr) => {
    // Simplemente devuelve el texto tal cual
    return dateStr ?? ""; // Devuelve una cadena vacía si dateStr es null o undefined
  };

  const formatNumber = (num) => {
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
      // Verificar si el número es menor a 0.01 y mayor a 0 para mantener su formato original
      if (num > 0 && num < 0.01) {
        return num.toString();
      }
      // Caso contrario, formatear a 2 dígitos decimales
      return num.toFixed(2);
    }
    return num;
  };
  

  const formatPercentage = (percent) => {
    return typeof percent === "number"
      ? (percent * 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "%"
      : percent;
  };

  const determineHeaders = (tickerData, isCrypto) => {
    // Si isCrypto es verdadero, decidir entre dos tipos de encabezados para criptomonedas
    if (isCrypto) {
      const hasPrice = tickerData.some((entry) =>
        entry.hasOwnProperty("Price")
      );
      //console.log("Has Price rodrigo:", hasPrice);
      if (
        tickerData &&
        tickerData.some((entry) => entry.hasOwnProperty("Price"))
      ) {
        return ["Date", "Price", "% Change"];
      } else {
        return [
          "Date Range",
          "Start Price",
          "End Price",
          "% Change",
        ];
      }
    }
    // Si no es criptomoneda, decidir entre dos tipos de encabezados para acciones u otros datos
    else {
      if (
        tickerData &&
        tickerData.some((entry) => entry.hasOwnProperty("Adjusted Close"))
      ) {
        return ["Date", "Adjusted Close", "% Change"];
      } else if (
        tickerData &&
        tickerData.some((entry) => entry.hasOwnProperty("Price"))
      ) {
        return ["Date", "Price", "% Change"];
      } else {
        return [
          "Date",
          "Start Price",
          "End Price",
          "% Change",
        ];
      }
    }
  };

  const renderTable = (tableRef, dataArray, title) => {
    const container = d3.select(tableRef.current);
    container.selectAll("*").remove();

    const headers = determineHeaders(dataArray);

    const table = container
      .append("table")
      .attr("class", "data-frame-change-table");

    table
      .append("thead")
      .append("tr")
      .selectAll("th")
      .data(headers)
      .enter()
      .append("th")
      .text((d) => d);

    if (dataArray && Array.isArray(dataArray)) {
      const rowsData = dataArray.map((entry) => {
        return headers.map((header) => {
          switch (header) {
            case "Date":
            case "Date Range":
              return formatDate(entry[header]); // Uso de formatDate
            case "Price":
            case "Adjusted Close":
            case "Start Price":
            case "End Price":
              return formatNumber(entry[header]);
            case "% Change":
              return formatPercentage(entry[header]);
            default:
              return entry[header];
          }
        });
      });

      table
        .append("tbody")
        .selectAll("tr")
        .data(rowsData)
        .enter()
        .append("tr")
        .selectAll("td")
        .data((d) => d)
        .enter()
        .append("td")
        .text((d) => d);
    }

    container.append("div").style("margin-bottom", "20px");
  };

  useEffect(() => {
    Object.entries(data).forEach(([ticker, tickerData]) => {
      const aboveDaysKey = isCrypto ? "above_days_df1" : "above_days_df";
      const belowDaysKey = isCrypto ? "below_days_df1" : "below_days_df";

      renderTable(aboveTableRef, tickerData[aboveDaysKey], "Above Days");
      renderTable(belowTableRef, tickerData[belowDaysKey], "Below Days");
    });
  }, [data, isCrypto]);

  return (
    <div className="container">
      <div className="scrollable_above">
        <div className="AboveBelowDataFrames-section-table">
          <div
            className="AboveBelowDataFrames-container"
            ref={aboveTableRef}
          ></div>
        </div>
      </div>
      <div className="scrollable_below">
        <div className="AboveBelowDataFrames-section-table">
          <div
            className="AboveBelowDataFrames-container"
            ref={belowTableRef}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AboveBelowDataFrames;
