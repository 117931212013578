import React, { useState, useEffect  } from "react";
import axios from "axios";
import { ApiUrl } from "../services/restAPI";
import RingLoader from "react-spinners/RingLoader";

import "./SettingsPopup.css";

const ContactUsContent = ({ userSpaceFinance }) => {
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [contactReasons, setContactReasons] = useState([]);    
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");  
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setIsSubmitting(true);
    const getMessageType = `${ApiUrl}UserMessage/MessageType`;
    axios
      .get(getMessageType) // Asegúrate de reemplazar esta URL con la URL completa de la API
      .then((response) => {
        const reasons = response.data.map((reason) => ({
          id: reason.iD_MessageType,
          name: reason.name,
        }));
        setContactReasons(reasons);        
        setIsSubmitting(false);
      })
      .catch((error) => {
        setSubmitError(error);
        setIsSubmitting(false);
      });
  }, []); // El arreglo vacío asegura que este efecto solo se ejecute una vez, cuando el componente se monta.

  const validateInput = () => {
    // Validación básica del input
    if (!reason || message.length < 10) {
      setSubmitError("Please select a option and ensure the message is at least 10 characters long.");
      return false;
    }
    // Aquí podrías añadir más validaciones si lo consideras necesario
    return true;
  };

  // Definición correcta de handleReasonChange dentro de ContactUsContent
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleMessageChange = (event) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
    setCharCount(newMessage.length);
  };

  const handleSubmit = () => {
    if (!validateInput()) return; // Previene el envío si la validación falla
    setIsSubmitting(true);
    
    const userName = userSpaceFinance.email || userSpaceFinance.name; // Utiliza email si está disponible, si no, usa el nombre
    axios
      .post(`${ApiUrl}UserMessage/ContactUs`, {
        userName,
        type: "IN",
        category: reason,
        message,
      })
      .then((response) => {
        const { message, numberError } = response.data;
        if (numberError === 0) {
          // Mensaje enviado con éxito
          setSubmitError("Thanks for reaching out! Wishing you a fantastic day!"); // Limpia errores previos          
          //alert(message); // O mostrar el mensaje de éxito de otra manera
          // Limpiar los campos después del envío exitoso
          setReason("");
          setMessage("");
        } else {
          // Error al enviar mensaje
          setSubmitError(message);
        }
      })
      .catch((error) => {
        setSubmitError("An error occurred while sending your message. Please try again.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}
      <div>
        <div className="menu-item content">Contact Us</div>
        <p>
          Please selected an option that best matches the reason for your
          contact. We're here to assist you!
        </p>
        <select
          name="contactReason"
          id="contactReason"
          className="select-style"
          value={reason}
          onChange={handleReasonChange}
        >
          <option value="">-- Select an option --</option>
          {contactReasons.map(({ id, name }) => (
            <option key={id} value={name}>
              {name}
            </option>
          ))}
        </select>
        <br />
        <br />
        <p>Write your message below</p>
        <textarea
          className="textarea-style"
          value={message}
          onChange={handleMessageChange}
          maxLength={500}
        ></textarea>
        <p className="messageCount">Maximum characters: {charCount} / 500</p>     
        <button className="button-style-ContactUs" onClick={handleSubmit}>
          SEND
        </button>
        {submitError && <div className="message">{submitError}</div>}
      </div>
    </>
  );
};
export default ContactUsContent;
