import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./SentimentCategories.css";

const SentimentCategories = ({ data }) => {
  const tableRef = useRef(null);

  useEffect(() => {
    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous tables
      container.selectAll("*").remove();

      // If sentiment4 and sentiment_categories exist in data, render the table
      if (data.sentiment_categories && data.sentiment_categories) {
        const categories = data.sentiment_categories;

        const customOrder = [
          "Bullish",
          "Somewhat-Bullish",
          "Neutral",
          "Somewhat-Bearish",
          "Bearish",
        ];

        /*Set short custom order */
        const sortedCategories = Object.entries(categories).sort((a, b) => {
          return customOrder.indexOf(a[0]) - customOrder.indexOf(b[0]);
        });

        const table = container
          .append("table")
          .attr("class", "sentiment-table");

        table
          .append("thead")
          .append("tr")
          .selectAll("th")
          .data(["Sentiment", "Range"])
          .enter()
          .append("th")
          .text((d) => d);

        table
          .append("tbody")
          .selectAll("tr")
          .data(sortedCategories)
          .enter()
          .append("tr")
          .selectAll("td")
          .data((d) => d)
          .enter()
          .append("td")
          .text((d) => d);
      }
    };

    renderTable(data);
  }, [data]);

  return <div ref={tableRef} />;
};

export default SentimentCategories;
