import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

function Dendrogram({ data, width = 600 }) { // Añade una prop 'width' con un valor predeterminado
  const ref = useRef();

  useEffect(() => {
    if (!data) return;

    const svg = d3.select(ref.current);
    svg.selectAll("*").remove();

    const width = 950; // Puedes ajustar esto como sea necesario
    const height = 500; // Puedes ajustar esto como sea necesario

    svg.attr("width", width).attr("height", height);

    // Agrega esto dentro de useEffect, después de configurar las dimensiones del svg
    const defs = svg.append("defs");

    const gradient = defs
      .append("linearGradient")
      .attr("id", "line-gradient")
      .attr("gradientUnits", "userSpaceOnUse") // Esto asegura que el gradiente se aplique a través de todo el SVG
      .attr("x1", 0)
      .attr("y1", height)
      .attr("x2", 0)
      .attr("y2", 0);

    // Define los colores del degradado
    gradient.append("stop").attr("offset", "0%").attr("stop-color", "#E5E6E7"); // Color rojo en la parte superior

    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#E5E6E7"); // Color azul en la parte inferior

    // Aquí creamos la jerarquía de datos
    const root = d3.hierarchy(data);

    // Cambiamos el cluster para que se ajuste al nuevo layout vertical
    const cluster = d3.cluster().size([width, height - 100]); // Invertimos el ancho y el alto aquí

    cluster(root);

    const g = svg.append("g").attr("transform", "translate(0,40)"); // Ajustamos esto para el nuevo layout

    // Actualizamos la función para las líneas, invirtiendo x e y
    const link = g
      .selectAll(".link")
      .data(root.descendants().slice(1))
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", (d) => {
        return "M" + d.x + "," + d.y + "V" + d.parent.y + "H" + d.parent.x;
      })
      .style("fill", "none")
      .style("stroke", "url(#line-gradient)") // Usa el gradiente definido
      .style("stroke-width", 1.5);

    // Actualizamos los nodos para el nuevo layout
    const node = g
      .selectAll(".node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr(
        "class",
        (d) => "node" + (d.children ? " node--internal" : " node--leaf")
      )
      .attr("transform", (d) => "translate(" + d.x + "," + d.y + ")");

    node.append("circle").attr("r", 2.5).style("fill", "#999");

    // Ajustamos el texto para el nuevo layout
    node
      .append("text")
      .attr("dy", 20) // Ajusta este valor según sea necesario para el espacio debajo del nodo
      .attr("x", 0) // Centrar el texto debajo del nodo
      .style("text-anchor", "middle") // Asegura que el texto esté centrado
      .text((d) => d.data.name)
      .style("font-family", "Inter")
      .style("font-size", 11)
      .style("font-weight","bold")
      .style("fill", "#133588");
  }, [data]);

  return (
    <div className="dendrogram" style={{ overflowX: "auto" }}>
      <svg ref={ref}></svg>
    </div>
  );
}

export default Dendrogram;
