import React from 'react';

const BTCMetrics = ({ data }) => {
  // Assuming data is an array with a single object as its element
  const btcMetrics = data[0];

  // Función para formatear los valores numéricos con 2 decimales
  const formatValue = (value) => {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }
    return value;
  };

  return (
    <div className="btcMetricsContainer">
      <h2 className="btcMetricsHeading">BITCOIN INFORMATION</h2>
      <table className="crypto-halving-table-BTC">
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(btcMetrics).map(([key, value]) => (
            <tr key={key}>
              <td className="btcMetricsKey">{key}</td>
              <td className="btcMetricsValue">{formatValue(value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BTCMetrics;
