import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { scaleOrdinal } from "d3-scale";
import "./PieGraph.css";

const PieGraph = ({ data }) => {
  const ref = useRef();
  const labelsRef = useRef();

  useEffect(() => {
    if (
      !data ||
      !data.sentiment_percentages ||
      typeof data.sentiment_percentages !== "object"
    ) {
      console.error("Invalid data passed to PieGraph:", data);
      return;
    }

    const svg = d3.select(ref.current);
    svg.selectAll("*").remove();

    const sentimentPercentages = data?.sentiment_percentages;
    let processedData = [];
    if (sentimentPercentages) {
      processedData = Object.keys(sentimentPercentages).map((key) => ({
        label: key,
        value: sentimentPercentages[key],
      }));
    }

    // Set Short custom order
    const customOrder = ["Bullish", "Somewhat-Bullish", "Neutral", "Somewhat-Bearish", "Bearish"];
    processedData.sort((a, b) => customOrder.indexOf(a.label) - customOrder.indexOf(b.label));

    const width = 450;
    const height = 350;
    const radius = Math.min(width, height) / 2 - 30;

    const pie = d3
      .pie()
      .value((d) => d.value)
      .sort(null);
    const arc = d3.arc().innerRadius(0).outerRadius(radius);
    
    const customColors = ["#8DFF57", "#C9FFBB", "#D7DBDB", "#FFC6C6", "#FC6A6A"];
    const colorScale = scaleOrdinal().range(processedData.map(d => d.label)).range(customColors);

    const g = svg
      .append("g")      
      .attr("filter", "url(#shadow)")
      .attr("transform", `translate(${width / 2},${height / 2 + 35})`);

    g.selectAll(".arc")
      .data(pie(processedData))
      .enter()
      .append("path")
      .attr("class", "arc")
      .attr("d", arc)      
      .attr("fill", d => colorScale(d.data.label));

    // Label rendering outside SVG
    const labelsDiv = d3.select(labelsRef.current);
    labelsDiv.selectAll("*").remove();

    processedData.forEach((d, i) => {
      const labelDiv = labelsDiv
        .append("div")
        .attr("class", "labelDiv")
        .style("color", colorScale(d.label));

      labelDiv.append("span").text(`${d.label}: ${d.value}%`);
    });
  }, [data]);

  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        width: "450px",
        height: "400px",
      }}
    >
      <svg ref={ref} width={450} height={400}></svg>
      <div
        ref={labelsRef}
        style={{
          position: "absolute",
          top: "0px",
          right: "-10px",
          lineHeight: "18px",
        }}
      >
        {/* Labels will be appended here by D3 */}
      </div>
    </div>
  );
};

export default PieGraph;
