import React, { useState, useEffect } from "react";

import DocumentationPopupSideBar from "./DocumentationPopupSideBar";

import "./DocumentationPopup.css"; //

const DocumentationPopup = ({ onClose }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {}, [inputValue]);

  // Dentro del componente ChatBot
  const setInputValueFromSidebar = (value) => {
    setInputValue(value);
  };

  // Función para manejar el cierre del popup y recargar la página
  const handleCloseAndReload = () => {
    onClose(); // Esta función debería manejar el cierre del popup
    window.location.reload(); // Recarga la página
  };

  return (
    <div className="documentation-popup">
      <div className="popup-content-documentation">
        <button onClick={handleCloseAndReload} className="popup-close-btn-Documentation">
          X
        </button>
        <div className="column left-Documentation">
          <DocumentationPopupSideBar setInputValue={setInputValueFromSidebar} />
        </div>
        <div className="column middle-Documentation">
          {inputValue && (
            <>
              <h2>{inputValue.title}</h2>
              <div className="description-Documentation">{inputValue.description}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentationPopup;
