import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import MyHeatmap from "../tickerMyHeatmap/MyHeatmap";
import Dendrogram from "./Dendrogram";
import ClusterComponent from "./ClusterComponent";
import "./CorrelationMatrix.css";

function CorrelationTable({ data }) {
  const mostCorrelatedRef = useRef(null);
  const leastCorrelatedRef = useRef(null);
  const dateRangeRef = useRef(null); // <-- Create a ref for date range table

  useEffect(() => {
    if (data && data.dataframe_top_most_correlated_pairs) {
      const mostCorrelatedContainer = d3.select(mostCorrelatedRef.current);
      renderPairsTable(
        mostCorrelatedContainer,
        data.dataframe_top_most_correlated_pairs
      );
    }

    if (data && data.dataframe_top_least_correlated_pairs) {
      const leastCorrelatedContainer = d3.select(leastCorrelatedRef.current);
      renderPairsTable(
        leastCorrelatedContainer,
        data.dataframe_top_least_correlated_pairs
      );
    }

    // Render Start and End Date Table
    if (data && data.start_date && data.end_date) {
      const dateRangeContainer = d3.select(dateRangeRef.current);
      renderDateRangeTable(dateRangeContainer, data.start_date, data.end_date);
    }
  }, [data]);

  const renderPairsTable = (container, pairs) => {
    container.selectAll("*").remove();

    if (!pairs.Rank || !pairs.correlation || !pairs.level_0 || !pairs.level_1) {
      console.error("Malformed pairs object:", pairs);
      return;
    }

    const table = container
      .append("table")
      .style("width", "100%")
      .classed("my-table-style", true);

    const thead = table.append("thead");
    const tbody = table.append("tbody");

    thead
      .append("tr")
      .selectAll("th")
      .data(["Rank", "Pair", "Correlation"])
      .enter()
      .append("th")
      .text((d) => d)
      .style("border", "1px solid black")
      .classed("header-cell", true);

    const sortedPairs = Object.entries(pairs.Rank)
      .sort((a, b) => a[1] - b[1])
      .map(([key]) => ({
        rank: pairs.Rank[key],
        correlation: pairs.correlation[key],
        level_0: pairs.level_0[key],
        level_1: pairs.level_1[key],
      }));

    sortedPairs.forEach((pair) => {
      const row = tbody.append("tr");
      row.append("td").text(pair.rank);
      row.append("td").text(`${pair.level_0} - ${pair.level_1}`);
      row.append("td").text(parseFloat(pair.correlation).toFixed(2));
    });
  };

  const renderDateRangeTable = (container, startDate, endDate) => {
    container.selectAll("*").remove();

    const table = container
      .append("table")
      .style("width", "100%")
      .classed("my-table-style", true);

    const thead = table.append("thead");
    const tbody = table.append("tbody");

    /*thead
      .append("tr")
      .selectAll("th")
      .data(["Type", "Date"])
      .enter()
      .append("th")
      .text((d) => d)
      .style("border", "1px solid black")
      .classed("header-cell", true);*/

    tbody
      .append("tr")
      .selectAll("td")
      .data(["Start Date", startDate])
      .enter()
      .append("td")
      .text((d) => d);
    tbody
      .append("tr")
      .selectAll("td")
      .data(["End Date", endDate])
      .enter()
      .append("td")
      .text((d) => d);
  };

  return (
    <div className="correlation-table">
      <div className="correlation-matrix-section-table table-container">
        <div>
          <h3>Date Range</h3>
          <div
            className="top-most-correlated-pairs-section"
            ref={dateRangeRef}
          ></div>             
        </div>
      </div>
      <h3></h3>
      <div className="correlation-matrix">
        {data && data.dataframe_correlation_matrix && (
          <>
            <MyHeatmap data={data.dataframe_correlation_matrix} 
                      containerWidth={1200}/>
            <h3>Dendrogram</h3>
            {data.dendrogram && (
              <Dendrogram data={JSON.parse(data.dendrogram)} />
            )}
          </>
        )}
      </div>

      <div className="correlation-matrix-section-table table-container">
        <div>
          <h3 className="correlationH3Most">Top 10 Most Correlated Pairs</h3>
          <div
            className="top-most-correlated-pairs-section-most"
            ref={mostCorrelatedRef}
          ></div>
          <h3 className="correlationH3Least">Top 10 Least Correlated Pairs</h3>
          <div
            className="top-most-correlated-pairs-section-least"
            ref={leastCorrelatedRef}
          ></div>
        </div>
      </div>

      {data && data.cluster_labels && (
        <div className="cluster-section">
          <h2>Cluster Visualization</h2>
          <ClusterComponent cluster_labels={data.cluster_labels} />
        </div>
      )}
    </div>
  );
}

export default CorrelationTable;