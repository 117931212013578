import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SearchTicker.css";
import copyImage from "../assets/newImages/Copy.svg"; 
import clearImage from "../assets/newImages/Clear.svg"; 

const SearchTicker = ({ onTickersSelected }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedTickers, setSelectedTickers] = useState([]);

  const API_KEY = "UK3X72HWC0OWCCXU";

  useEffect(() => {
    const searchQuery = inputValue.split(",").pop().trim();

    if (searchQuery.length === 0) {
      setSuggestions([]);
      return;
    }

    const fetchSuggestions = async () => {
      try {
        const apiUrl = `https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=${searchQuery}&apikey=${API_KEY}`;
        const response = await axios.get(apiUrl);
        const suggestionList = response.data.bestMatches;
        setSuggestions(suggestionList);
      } catch (error) {
        console.error(error);
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [inputValue]);

  const handleSuggestionClick = (suggestion) => {
    const lastCommaIdx = inputValue.lastIndexOf(",");
    const newInputValue =
      lastCommaIdx !== -1
        ? `${inputValue.substring(0, lastCommaIdx + 1)} ${
            suggestion["1. symbol"]
          }, `
        : `${suggestion["1. symbol"]}, `;

    setInputValue(newInputValue);
    setSuggestions([]);
    setSelectedTickers((prev) => [...prev, suggestion["1. symbol"]]);
    if (onTickersSelected) {
      onTickersSelected([...selectedTickers, suggestion["1. symbol"]]);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(inputValue).catch((error) => {
      console.error("Copy failed!", error);
    });
  };

  const handleClearClick = () => {
    setInputValue("");
    setSelectedTickers([]);
    if (onTickersSelected) {
      onTickersSelected([]);
    }
  };

  return (
    <div className="search-ticker-1">
      <div className="search-container-1 my-fixed-item-1">
        {/*<span className="button-padding">*/}
        {/*</span>*/}
        {/*<h2>Search Tickers</h2>*/}
        <input
          className="rounded-search-1"
          placeholder="Search your tickers"
          value={inputValue}
          onChange={handleInputChange}
        />
        <table>
          <tbody>
            <tr>
              <td>
                <div style={{ display: "inline-block", paddingRight: "15px" }}>
                  <button
                    onClick={handleCopyToClipboard}
                    title="Copy tickers to clipboard"
                  >
                    <img src={copyImage} alt="Copy tickers" className="imageSearchTicker" />
                  </button>
                </div>
                <div style={{ display: "inline-block" }}>
                  <button
                    onClick={handleClearClick}
                    title="Clear all selected tickers"
                  >
                    <img src={clearImage} alt="Clear tickers" className="imageSearchTicker" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {/* Mueve la lista de sugerencias aquí, antes del campo de entrada */}
        <ul className="suggestion-list-1">
          {inputValue.trim() &&
            suggestions.map((suggestion) => (
              <li
                key={suggestion["1. symbol"]}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion["1. symbol"]} - {suggestion["2. name"]} (
                {suggestion["4. region"]})
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchTicker;
