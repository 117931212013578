import React from "react";
import { TypeAnimation } from "react-type-animation";

import "./App.css"; // General App styles

const AnimatedText = () => {
    return (
      <section1 className="containerSection1">
        <div style={{ transform: 'translateX(-60px)' }}> {/* Contenedor adicional para el desplazamiento */}
        <TypeAnimation
          sequence={[
            "PRECISE",
            2000,
            "",
            1000,
            "SIMPLE",
            2000,
            "",
            1000,
            "FAST",
            2000,
            "",
            1000,
          ]}
          wrapper="div"
          speed={2}
          deletionSpeed={1}
          style={{
            fontFamily: "Inter, sans-serif",
            textShadow: "0 18.100000381469727px 18.1px rgba(0, 0, 0, 0.25)",
            fontWeight: "800",
            fontSize: "clamp(80px, 10vw, 350px)", // Tamaño del texto escalable
            lineHeight: "1.2", // Ajuste opcional de la altura de línea
            textTransform: "capitalize",
            letterSpacing: "-5.03px",
            textAlign: "center", // Alineación al centro            
          }}
          repeat={Infinity}
          omitDeletionAnimation={true}
        />
        </div>
      </section1>
    );
  };
  
  export default AnimatedText;