import React, { useEffect } from "react";
import BarGraph from "./BarGraph"; // Import your BarGraph component
import LineChart from "./LineChart";
import LineChart2 from "./LineChart2";
import DrawdownGraph from "./DrawdownGraph";
import { Tooltip } from "react-tooltip";
import "./PortfolioMetricsTable.css";

function PortfolioMetricsTable(props) {
  const { data } = props; // Destructure the data prop

  useEffect(() => {
    // Add tooltips to dynamically generated table headers for Risk Metrics
    const riskTable = document.querySelector('.portfolio-metrics-content table');
    if (riskTable) {
      const headers = riskTable.querySelectorAll('th');
      const riskTooltips = [
        { id: 'TimeFrame1', text: 'SOLO POR EL TEMA DEL INDECE SE HA CREADO ESTE.', className:"custom-tooltip" },
        { id: 'TimeFrame', text: 'Period within which events or actions occur.', className:"custom-tooltip" },
        { id: 'RiskAversionLevel', text: 'Scale from 1 (high risk tolerance) to very conservative.', className:"custom-tooltip" },
        { id: 'MinimumWeight', text: 'Lowest allocation assigned to an asset.', className:"custom-tooltip" },
        { id: 'MaximumWeight', text: 'Highest allocation assigned to an asset.', className:"custom-tooltip" },
        { id: 'Beta', text: 'Measures relative portfolio volatility compared to a benchmark.', className:"custom-tooltip" },
        { id: 'SharpeRatio', text: 'Evaluates risk-adjusted return against a risk-free asset.', className:"custom-tooltip" },
        { id: 'StandardDeviation', text: 'Indicates historical variability of portfolio returns.', className:"custom-tooltip" },
        { id: 'PortfolioVolatility', text: 'Measures fluctuation in investment portfolio returns over time.', className:"custom-tooltip" },
        { id: 'VAR', text: 'Measures return fluctuation over time, indicating risk level.', className:"custom-tooltip" },
        { id: 'Alpha', text: 'Assesses risk-adjusted performance relative to a benchmark.', className:"custom-tooltip" },
        { id: 'PortfolioReturns', text: 'Total performance of a portfolio within a specified period.', className:"custom-tooltip" }
      ];

      headers.forEach((header, index) => {
        if (riskTooltips[index]) {
          header.setAttribute('data-tooltip-id', riskTooltips[index].id);
          header.setAttribute('data-tooltip-content', riskTooltips[index].text);
        }
      });
    }

    // Add tooltips to dynamically generated table headers for Return Metrics
    const returnTable = document.querySelectorAll('.portfolio-metrics-content')[1];
    if (returnTable) {
      const headers = returnTable.querySelectorAll('th');
      const returnTooltips = [
        { id: 'Alpha1', text: 'SOLO POR EL TEMA DEL INDECE SE HA CREADO ESTE.' },
        { id: 'Alpha', text: 'Assesses risk-adjusted performance relative to a benchmark.' },
        { id: 'PortfolioReturns', text: 'Total performance of a portfolio within a specified period.' },
        { id: 'ExpectedReturn', text: 'Projects annualized volatility of the portfolio.' },
        { id: 'BenchmarkReturns', text: 'Reference for evaluating portfolio performance relative to the market.' },
        { id: 'BenchmarkVolatility', text: 'Annualized volatility of the benchmark.' },
        { id: 'MarketRiskPremium', text: 'Portfolio return minus risk-free return.' },
        { id: 'RiskFreeRate', text: 'Represents guaranteed return on risk-free investments.' },
        { id: 'Drawdown', text: 'Represents percentage difference between peak and trough portfolio returns.' },
        { id: 'Weights', text: 'Optimal asset allocations for portfolio efficiency over a specified period.' },
        { id: 'RiskContribution', text: 'Contribution of individual assets to overall portfolio risk.' },
        { id: 'PortfolioPerformance', text: 'Performance charts visually represent investment outcomes.' },
        { id: 'PortfolioVsBenchmark', text: 'Compares portfolio returns with benchmark performance.' },
        { id: 'CovarianceMatrix', text: 'Analyzes relationships between different assets.' }
      ];

      headers.forEach((header, index) => {
        if (returnTooltips[index]) {
          header.setAttribute('data-tooltip-id', returnTooltips[index].id);
          header.setAttribute('data-tooltip-content', returnTooltips[index].text);
        }
      });
    }
  }, [data]);

  // Debug to check what we get
  
  //console.log("PortfolioMetricsTable");
  //console.log("PortfolioMetricsTable:", data.portfolio_metrics1);


  const tooltipStyle = {
    backgroundColor: "#438BBA",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "14px"
  };

  return (
    <div className="portfolio-metrics-table">
      <h4>Portfolio Optimization</h4>

      <div className="portfolio-metrics-section">
        <h4>Risk Metrics</h4>
        <div
          className="portfolio-metrics-content"
          dangerouslySetInnerHTML={{ __html: data.portfolio_metrics1 }}
        />
      </div>

      <div className="portfolio-metrics-section">
        <h4>Return Metrics</h4>
        <div
          className="portfolio-metrics-content"
          dangerouslySetInnerHTML={{ __html: data.portfolio_metrics2 }}
        />
      </div>

      <div className="drawdown-section">
        <h4>Drawdown</h4>
        <DrawdownGraph data={data.drawdown} />
      </div>

      <div className="weights-section">
        <h4>Weights</h4>
        <div
          className="weights-content"
          dangerouslySetInnerHTML={{ __html: data.weights }}
        />
      </div>

      <div className="risk-contribution-section">
        <h4>Risk Contribution </h4>
        <BarGraph data={data.normalized_risk_contrib} />
      </div>

      <div className="portfolio-returns-section">
        <h4>Portfolio Returns</h4>
        {/* Render the BarGraph component here */}
        <LineChart data={data.portfolio_return} />
      </div>

      <h4>Portfolio vs Benchmark Returns</h4>
      {/* Render the LineChart2 component here */}
      <LineChart2
        portfolio_return={data.portfolio_return}
        benchmark_return={data.benchmark_return}
      />

      {/* Add tooltips for table headers */}
      <Tooltip id="TimeFrame" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="RiskAversionLevel" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="MinimumWeight" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="MaximumWeight" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="Beta" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="SharpeRatio" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="StandardDeviation" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="PortfolioVolatility" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="VAR" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="Alpha" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="PortfolioReturns" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="ExpectedReturn" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="BenchmarkReturns" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="BenchmarkVolatility" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="MarketRiskPremium" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="RiskFreeRate" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="Drawdown" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="Weights" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="RiskContribution" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="PortfolioPerformance" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="PortfolioVsBenchmark" place="top" effect="solid" style={tooltipStyle} />
      <Tooltip id="CovarianceMatrix" place="top" effect="solid" style={tooltipStyle} />
    </div>
  );
}

export default PortfolioMetricsTable;
