import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./DrawdownGraph.css";

const DrawdownGraph = ({ data }) => {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({ width: 950, height: 280 });

  const parsedData = Object.keys(data.date).map((key) => ({
    date: new Date(data.date[key]),
    value: parseFloat(data.value[key]),
  }));

  useEffect(() => {
    if (!parsedData.length) return;
  
    const svg = d3
      .select(ref.current)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height + 30);
    svg.selectAll("*").remove();
  
    const yMin = d3.min(parsedData, (d) => d.value);
    const yMax = 0;
  
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };
    const innerWidth = dimensions.width - margin.left - margin.right;
    const innerHeight = dimensions.height - margin.top - margin.bottom;
  
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(parsedData, (d) => d.date))
      .range([0, innerWidth]);
  
    const yScale = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range([innerHeight, 0]);
  
    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
  
    g.append("g")
      .call(
        d3.axisLeft(yScale).tickSize(-innerWidth).tickFormat(d3.format(".2%"))
      )
      .attr("class", "y-axis")
      .selectAll("text")
      .style("fill", "#3462A0")
      .style("font-size", "11px");
  
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(d3.timeMonth.every(3))
      .tickSize(0)
      .tickFormat(d3.timeFormat("%b-%Y"));
  
    g.append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end")
      .style("font-size", "10px")
      .style("fill", "#3462A0")
      .attr("dx", "-0.5em")
      .attr("dy", "0.5em");
  
    const line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
      .curve(d3.curveMonotoneX);
  
    const gradient = svg
      .append("linearGradient")
      .attr("id", "area-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", margin.left)
      .attr("y1", yScale(0))
      .attr("x2", margin.left)
      .attr("y2", yScale(yMin));
  
    gradient.append("stop").attr("offset", "0%").attr("stop-color", "#0099FF");
    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#0099FF");
  
    const area = d3
      .area()
      .x((d) => xScale(d.date))
      .y0(innerHeight)
      .y1((d) => yScale(d.value))
      .curve(d3.curveLinear);
  
    g.append("path")
      .datum(parsedData)
      .attr("fill", "url(#area-gradient)")
      .attr("d", area);
  
    g.append("path")
      .datum(parsedData)
      .attr("fill", "none")
      .attr("stroke", "#438BBA")
      .attr("d", line);
  
    const focus = g.append("g").style("display", "none");
  
    focus
      .append("circle")
      .attr("r", 5)
      .attr("stroke", "steelblue")
      .attr("fill", "white")
      .attr("stroke-width", 2);
  
    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "#438BBA")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("z-index", "2")
      .style("box-shadow", "0px 10px 10px rgba(0,0,0,0.1)")
      .style("border", "1px solid white")
      .style("top", "0px")
      .style("left", "0px")
      .style("text-align", "center")
      .attr("class", "rounded p-2 bg-white text-dark");
  
    svg
      .on("mousemove", function (event) {
        const [mx] = d3.pointer(event);
        const currentDate = xScale.invert(mx);
        const bisect = d3.bisector((d) => d.date).left;
        const idx = bisect(parsedData, currentDate, 1);
        const d0 = parsedData[idx - 1];
        const d1 = parsedData[idx];
        const d =
          d0 && d1
            ? currentDate - d0.date > d1.date - currentDate
              ? d1
              : d0
            : d0 || d1;
  
        if (d) {
          focus.attr(
            "transform",
            `translate(${xScale(d.date)}, ${yScale(d.value)})`
          );
          focus.style("display", null);
  
          tooltip
            .html(
              `<span style="font-size:16px; font-weight:bold; font-family:Oxanium; color:white;">
                ${(d.value * 100).toFixed(2)} %
              </span>
              <br>
              <span style="font-size:12px; font-family:Oxanium; color:white">
                ${d.date.toISOString().split("T")[0]}
              </span>`
            )
            .style("left", `${event.pageX - 15}px`)
            .style("top", `${event.pageY - 70}px`)
            .style("visibility", "visible");
        }
      })
      .on("mouseleave", () => {
        focus.style("display", "none");
        tooltip.style("visibility", "hidden");
      });
  
    const handleResize = () => {
      const { width } = ref.current.parentElement.getBoundingClientRect();
      setDimensions({ ...dimensions, width: width * 0.9 });
    };
  
    window.addEventListener("resize", handleResize);
  
    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
      tooltip.remove(); // Eliminar el tooltip al desmontar el componente
    };
  }, [data, dimensions]);

  return (
    <div>
      <svg
        ref={ref}
        width="100%"
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      ></svg>
    </div>
  );
};

export default DrawdownGraph;