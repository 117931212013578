import React from "react";
import "./AboveBelowTableSection.css";
import { Tooltip } from "react-tooltip";

class AboveBelowTableSection extends React.Component {
  determineTitles() {
    const { data1, data6 } = this.props;
    let useDays = false;

    // Verificar en data1 y data6 si existe al menos un registro con 'Days'
    [data1, data6].forEach((dataSet) => {
      if (dataSet) {
        for (let ticker in dataSet) {
          if (
            dataSet[ticker]["Above Days"] !== undefined &&
            dataSet[ticker]["Below Days"] !== undefined &&
            dataSet[ticker]["Total Days"] !== undefined
          ) {
            useDays = true;
            break;
          }
        }
      }
    });

    return useDays
      ? ["Above Days", "Below Days", "Total Days"]
      : ["Above Interval", "Below Interval", "Total Interval"];
  }

  render() {
    const { tickersWithAboveBelowTable, data1, data6 } = this.props;
    const titles = this.determineTitles();

    const tooltips = [
      { id: 'Ticker', text: 'Ticker' },
      { id: 'Threshold', text: 'Percentage from which time intervals are measured.' },
      { id: 'IntervalType', text: 'Number of days used to calculate the threshold.' },
      { id: titles[0].replace(' ', ''), text: 'Days within the interval where the asset has exceeded the threshold.' },
      { id: titles[1].replace(' ', ''), text: 'Days within the interval where the asset has fallen below the threshold.' },
      { id: titles[2].replace(' ', ''), text: 'Total interval days within the specified period.' },
      { id: 'ProbAbove', text: 'Percentage of days the asset was above the threshold.' },
      { id: 'ProbBelow', text: 'Percentage of days the asset was below the threshold.' },
      { id: 'StartDate', text: 'Initial date of the specified period.' },
      { id: 'EndDate', text: 'Final date of the specified period.' },
      { id: 'StartPrice', text: 'Start Price' },
      { id: 'EndPrice', text: 'End Price' },
      { id: 'MaxPrice', text: 'Maximum price during the specified period.' },
      { id: 'MinPrice', text: 'Minimum price during the specified period.' },
      { id: 'PercentageChange', text: 'Price variation from start date to end date.' },
    ];

    const tooltipStyle = {
      backgroundColor: "#438BBA",
      color: "#fff",
      fontFamily: "Arial, sans-serif",
      padding: "10px",
      borderRadius: "5px",
      fontSize: "14px"
    };

    return (
      <div className="timesTableAboveResumen-section-table">
        <div className="timesTableAboveResumen-container">
          <table className="timesTableAboveResumen-table">
            <thead>
              <tr>
              {["Ticker", "Threshold", "Interval Type", ...titles, "Probabilities of being Above", "Probabilities of being Below", "Start Date", "End Date", "Start Price", "End Price", "Max Price", "Min Price", "Percentage Change"].map((header, index) => (
                  <th
                    key={header}
                    data-tooltip-id={tooltips[index]?.id}
                    data-tooltip-content={tooltips[index]?.text}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tickersWithAboveBelowTable.map((ticker) => {
                const hasData1 = data1 && data1[ticker];
                const hasData6 = data6 && data6[ticker];

                // Renderizar la fila solo si existe data1 o data6 para el ticker
                if (hasData1 || hasData6) {
                  // Verificar si 'Above Days', 'Below Days', y 'Total Days' están presentes en data1
                  const useDaysData1 =
                    hasData1 &&
                    data1[ticker]["Above Days"] !== undefined &&
                    data1[ticker]["Below Days"] !== undefined &&
                    data1[ticker]["Total Days"] !== undefined;
                  const useDaysData6 =
                    hasData6 &&
                    data6[ticker]["Above Days"] !== undefined &&
                    data6[ticker]["Below Days"] !== undefined &&
                    data6[ticker]["Total Days"] !== undefined;

                  return (
                    <tr key={ticker}>
                      <td>{ticker}</td>
                      {/* Datos de data1 */}
                      {hasData1 && (
                        <>
                          <td>{data1[ticker]?.Threshold}</td>
                          <td>{data1[ticker]?.["Interval Type"]}</td>
                          <td>
                            {useDaysData1
                              ? data1[ticker]?.["Above Days"]
                              : data1[ticker]?.["Above Intervals"]}
                          </td>
                          <td>
                            {useDaysData1
                              ? data1[ticker]?.["Below Days"]
                              : data1[ticker]?.["Below Intervals"]}
                          </td>
                          <td>
                            {useDaysData1
                              ? data1[ticker]?.["Total Days"]
                              : data1[ticker]?.["Total Intervals"]}
                          </td>
                          <td>
                            {data1[ticker]?.["Probabilities of being Above"]}
                          </td>
                          <td>
                            {data1[ticker]?.["Probabilities of being Below"]}
                          </td>
                          <td>{data1[ticker]?.["Start Date"]}</td>
                          <td>{data1[ticker]?.["End Date"]}</td>
                          <td>{data1[ticker]?.["Start Price"]}</td>
                          <td>{data1[ticker]?.["End Price"]}</td>
                          <td>{data1[ticker]?.["Max Price"]}</td>
                          <td>{data1[ticker]?.["Min Price"]}</td>
                          <td>{data1[ticker]?.["Percentage Change"]}</td>
                        </>
                      )}

                      {/* Datos de data6 */}
                      {hasData6 && (
                        <>
                          <td>{data6[ticker]?.Threshold}</td>
                          <td>{data6[ticker]?.["Interval Type"]}</td>
                          <td>
                            {useDaysData6
                              ? data6[ticker]?.["Above Days"]
                              : data6[ticker]?.["Above Intervals"]}
                          </td>
                          <td>
                            {useDaysData6
                              ? data6[ticker]?.["Below Days"]
                              : data6[ticker]?.["Below Intervals"]}
                          </td>
                          <td>
                            {useDaysData6
                              ? data6[ticker]?.["Total Days"]
                              : data6[ticker]?.["Total Intervals"]}
                          </td>
                          <td>
                            {data6[ticker]?.["Probabilities of being Above"]}
                          </td>
                          <td>
                            {data6[ticker]?.["Probabilities of being Below"]}
                          </td>
                          <td>{data6[ticker]?.["Start Date"]}</td>
                          <td>{data6[ticker]?.["End Date"]}</td>
                          <td>{data6[ticker]?.["Start Price"]}</td>
                          <td>{data6[ticker]?.["End Price"]}</td>
                          <td>{data6[ticker]?.["Max Price"]}</td>
                          <td>{data6[ticker]?.["Min Price"]}</td>
                          <td>{data6[ticker]?.["Percentage Change"]}</td>
                        </>
                      )}
                    </tr>
                  );
                }
                // No renderizar la fila si no hay datos para data1 ni data6
                return null;
              })}
            </tbody>
          </table>
        </div>
        {/* Add tooltips for table headers */}
        {tooltips.map((tooltip) => (
          <Tooltip key={tooltip.id} id={tooltip.id} place="top" effect="solid" style={tooltipStyle} />
        ))}      
      </div>
    );
  }
}

export default AboveBelowTableSection;
