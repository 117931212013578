import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./BarGraph2.css";
import { scaleOrdinal } from "d3-scale";

const BarGraph2 = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    if (
      !data ||
      !data.sentiment_counts ||
      typeof data.sentiment_counts !== "object"
    ) {
      console.error("Invalid data passed to BarGraph2:", data);
      return; // exit useEffect if data is not valid
    }

    const svg = d3.select(ref.current);
    svg.selectAll("*").remove();

    const sentimentCounts = data?.sentiment_counts;
    let processedData = [];
    if (sentimentCounts) {
      processedData = Object.keys(sentimentCounts).map((key) => ({
        label: key,
        value: sentimentCounts[key],
      }));
    }

    // Set Short custom order
    const customOrder = ["Bullish", "Somewhat-Bullish", "Neutral", "Somewhat-Bearish", "Bearish"];
    processedData.sort((a, b) => customOrder.indexOf(a.label) - customOrder.indexOf(b.label));

    // Set dimensions
    const margin = { top: 20, right: 10, bottom: 40, left: 30 };
    const width = 380 - margin.left - margin.right;
    const height = 370 - margin.top - margin.bottom;

    // Create scales
    const xScale = d3
      .scaleBand()
      .domain(processedData.map((d) => d.label))
      .range([0, width])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(processedData, (d) => d.value)])
      .range([height, 0]);
        
    const customColors = ["#8DFF57", "#C9FFBB", "#D7DBDB", "#FFC6C6", "#FC6A6A"];    
    const colorScale = scaleOrdinal().range(customColors);    

    // Append group and apply transform to position graph within margins
    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("className", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "#238bb0")
      .style("color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // Create the bars
    g.selectAll(".bar")
      .data(processedData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.label))
      .attr("y", (d) => yScale(d.value) - 2.5)
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - yScale(d.value))
      /*.attr("fill", "#30cfd0")*/
      .attr("fill", (d, i) => colorScale(i))
      .attr("stroke", "white")
      .attr("rx", 7) // Radio de las esquinas en el eje x
      .attr("ry", 7)
      .on("mouseover", function (event, d) {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`Value: ${d.value}`)
          .style("left", event.pageX + 5 + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", function (d) {
        tooltip.transition().duration(500).style("opacity", 0);
      }); // Radio de las esquinas en el eje y

    // Add x-axis
    g.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale));

    // Add y-axis
    g.append("g").call(d3.axisLeft(yScale));
  }, [data]);

  return <svg ref={ref} width={420} height={370}></svg>;
};

export default BarGraph2;
