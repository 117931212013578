import React, { useState, useEffect } from "react";
import MatrixHalving from "./MatrixHalving";
import DominanceBTCETH from "./DominanceBTCETH";
import GlobalMarketCaps from "./GlobalMarketCaps"; // Import the new component
import BTCMetrics from "./BTCMetrics";
import BestWorstPerformance from "./BestWorstPerformance";
import "./Halving.css";
import Countdown from "./Countdown";

const MainHalving = ({
  cryptoData,
  stockData,
  globalMetrics,
  globalMetrics2,
  btcMetrics,
  bestMonths, // Add this
  worstMonths, // Add this
  bestWeeks, // Add this
  worstWeeks, // Add this
}) => {
  // Add globalMetrics2 as a new prop
  const [interval, setInterval] = useState("monthly"); // Default initial state

  useEffect(() => {
    // Dynamically set the interval based on available data
    const availableInterval = cryptoData.monthly ? "monthly" : "weekly";
    setInterval(availableInterval);
  }, [cryptoData, stockData]); // Depend on cryptoData and stockData to dynamically set interval

  const CryptoHalvingLegendItem = ({ color, label }) => (
    <div className="crypto-halving-legend-item">
      <div
        className="crypto-halving-legend-color"
        style={{ backgroundColor: color }}
      />
      <div className="crypto-halving-legend-label">{label}</div>
    </div>
  );

  return (
    <>
      <main className="crypto-halving">
        <h1 className="crypto-halving-title">Halving</h1>
        <section className="crypto-halving-section">
          {/*<h2 className="crypto-halving-subtitle">Crypto</h2> */}
          <div className="crypto-halving-table">
            <MatrixHalving
              type="Cryptos"
              data={cryptoData}
              interval={interval}
            />
            <div className="crypto-halving-table-legends">
              <CryptoHalvingLegendItem color="#09f" label="Halving" />
              <CryptoHalvingLegendItem color="#f39a3e" label="Approval ETF" />
            </div>
          </div>
          {/*<h2 className="crypto-halving-subtitle">Stocks</h2>*/}
          {(stockData.weekly && Object.keys(stockData.weekly).length > 0) ||
          (stockData.monthly && Object.keys(stockData.monthly).length > 0) ? (
            <div className="crypto-halving-table">
              <MatrixHalving
                type="Stocks"
                data={stockData}
                interval={interval}
              />
              <div className="crypto-halving-table-legends">
                <CryptoHalvingLegendItem color="#09f" label="Halving" />
                <CryptoHalvingLegendItem color="#f39a3e" label="Approval ETF" />
              </div>
            </div>
          ) : null}

          <BestWorstPerformance
            bestMonths={bestMonths}
            worstMonths={worstMonths}
            bestWeeks={bestWeeks}
            worstWeeks={worstWeeks}
          />
          <GlobalMarketCaps data={globalMetrics2} />
          <DominanceBTCETH data={globalMetrics} />
          <BTCMetrics data={btcMetrics} />
          <div>
            {/* Render the new component *
            <HalvingToNow data={cryptoData.percentage_change_df} />
            <HalvingToNow data={stockData.percentage_change_df} />  */}
          </div>
          <Countdown title="HALVING COUNT DOWN"></Countdown>
        </section>
      </main>
    </>
  );
};

export default MainHalving;
