import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Tooltip } from "react-tooltip";
import "./Analyst.css";

const Analyst = ({ data, dataType, handleDataChange }) => {
  const tableRef = useRef(null);

  const tooltips = [
    { id: 'Ticker', text: 'Ticker' },
    { id: 'CurrentPrice', text: 'Current trading price.' },
    { id: 'PriceTarget', text: 'Average analyst target price.' },
    { id: 'PricePotential', text: 'Percentage change potential.' },
    { id: 'TotalAnalysts', text: 'Number of analysts covering the stock.' },
    { id: 'BuyPercentage', text: 'Percentage of analysts recommending to buy.' },
    { id: 'HoldPercentage', text: 'Percentage of analysts recommending to hold.' },
    { id: 'SellPercentage', text: 'Percentage of analysts recommending to sell.' }
  ];

  const tooltipStyle = {
    backgroundColor: "#438BBA",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "14px"
  };

  useEffect(() => {
    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      // Create a temporary container for parsing the HTML string
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = data;

      const table = container.append("table").attr("class", "Analyst");

      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      const headers = Array.from(tempContainer.querySelectorAll("th")).map(
        (th) => th.textContent
      );
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d)
        .attr("data-tooltip-id", (d, i) => tooltips[i]?.id)
        .attr("data-tooltip-content", (d, i) => tooltips[i]?.text);

      // Append table rows
      const rows = Array.from(tempContainer.querySelectorAll("tr")).slice(1); // Exclude the header row
      tbody
        .selectAll("tr")
        .data(rows)
        .enter()
        .append("tr")
        .html((d) => d.innerHTML);

      return table;
    };

    renderTable(data);
  }, [data]);

  return (
    <div className="Analyst-container">
      <div ref={tableRef} />
      {/* Add tooltips for table headers */}
      {tooltips.map((tooltip) => (
        <Tooltip key={tooltip.id} id={tooltip.id} place="top" effect="solid" style={tooltipStyle} />
      ))}
      {/*<div className="Analyst-data-type-buttons">
        <button
          className={`Analyst-data-type-button ${
            dataType === "5. adjusted close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("5. adjusted close")}
        >
          Adjusted Close
        </button>
        <button
          className={`Analyst-data-type-button ${
            dataType === "4. close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("4. close")}
        >
          Close
        </button>
        </div>*/}
    </div>
  );
};

export default Analyst;
