import React from "react";
import "./AboutUsPopup.css"; // Asegúrate de crear y definir los estilos necesarios

const AboutUsPopup = ({ onClose }) => {
  
  // Función para manejar el cierre del popup y recargar la página
  const handleCloseAndReload = () => {
    onClose(); // Esta función debería manejar el cierre del popup
    window.location.reload(); // Recarga la página
  };

  return (
    <div className="aboutus-popup">
      <div className="popup-content">
        <div className="popup-title">
          <h2>About Us</h2>
          <button onClick={handleCloseAndReload} className="popup-close-btn">
            X
          </button>
        </div>
        <p>
          Dive into an exciting journey into the captivating universe of Space
          Finance.AI, a leader in artificial intelligence-driven financial
          market analysis.
        </p>
        <p>
          It all started with the pressing need of our co-founders to access
          highly qualified market metrics in a simple, fast, and accurate
          manner. This is how Space Finance.AI was born, driven by the steadfast
          mission to simplify finance and the bold vision of creating an
          intelligent and easily understandable financial ecosystem.
        </p>
        <p>
          At Space Finance.AI, our commitment goes beyond merely providing
          information; it extends to assisting our users in gaining a clear
          understanding of financial complexities. We pledge to create an
          environment where questions find direct answers. Bid farewell to
          intricate complications and immerse yourself in the dynamic world of
          financial markets, guided by Eugene, your ever-present financial
          analyst.
        </p>
      </div>
    </div>
  );
};

export default AboutUsPopup;
