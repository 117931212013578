// BarGraph.js
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./BarGraph.css";

const BarGraph = ({ data }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Limpiar elementos anteriores del gráfico, si los hay

    const margin = { top: 20, right: 120, bottom: 40, left: 50 };
    const width = 600; // Ancho fijo, ajusta según tus necesidades
    const height = 230; // Altura fija, ajusta según tus necesidades

    svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .style("fill", "#3462A0");

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("className", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "#238bb0")
      .style("color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("z-index", "10000");

    // Crear escalas
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.label))
      .range([0, width])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([height, 0]);

    // Añadir grupo y aplicar transformación para posicionar el gráfico dentro de los márgenes
    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Crear las barras
    g.selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.label))
      .attr("y", (d) => yScale(d.value) - 2.5)
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - yScale(d.value))
      .attr("fill", "#F29331")
      .attr("rx", 10)
      .attr("ry", 10)
      .on("mouseover", function (event, d) {
        tooltip
          .html("Valor: " + d.value.toFixed(2)) // Asegúrate de acceder a la propiedad correcta
          .style("opacity", 1)
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY + 10 + "px");
      })
      .on("mousemove", function (event) {
        tooltip
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY + 10 + "px");
      })
      .on("mouseout", function () {
        tooltip.style("opacity", 0);
      });

    // Añadir eje x
    g.append("g")
      .attr("transform", `translate(0,${height})`)
      .style("font-size", "13px")
      .style("color", "#3462A0") // Cambia el color aquí
      .call(d3.axisBottom(xScale));

    // Añadir eje y
    g.append("g")
      .style("font-size", "13px")
      .style("color", "#3462A0")
      .call(d3.axisLeft(yScale));
  }, [data]);

  return (
    <div
      className="bar-graph-container"
      style={{ width: "100%", height: "100%" }}
    >
      <svg ref={svgRef} />
      <div className="tooltip"></div>
    </div>
  );
};

export default BarGraph;
