import React from "react";
import "./LimitPopup.css";

const LimitPopup = ({ onClose }) => {
  return (
    <div className="limit-popup-overlay">
      <div className="limit-popup">
        <div className="limit-popup-header">
          <button className="limit-popup-close-btn" onClick={onClose}>
            &times;
          </button>
          <h3>You've reached the question limit!</h3>
        </div>
        <div className="limit-popup-content">
          <p>
            Due to the high number of users, we've set a temporary limit. Don't worry, we'll let you know when you can ask more questions.
          </p>
          <p>Thanks for you patience and for being part of SpaceFinance!</p>
        </div>
      </div>
    </div>
  );
};

export default LimitPopup;
