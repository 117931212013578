// DeleteAccountContent.jsx
import React, { useState } from "react";
import axios from "axios";
import { ApiUrl } from "../services/restAPI";
import RingLoader from "react-spinners/RingLoader";
import check from "../assets/check.png";

const DeleteAccountContent = ({ userSpaceFinance, onClose }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessImage, setShowSuccessImage] = useState(false);

  const handleDelete = () => {
    /*const isConfirmed = window.confirm(
      "Are you sure you want to delete your account? This action is irreversible."
    );

    if (!isConfirmed) {
      //onClose(); // Cierra el popup si el usuario elige 'No'
      return;
    }*/

    setIsSubmitting(true);
    setMessage("");

    setIsDeleting(true);
    // Construye la URL para la solicitud GET

    let userName = "";
    if (userSpaceFinance.email) {
      userName = userSpaceFinance.email;
    } else {
      userName = userSpaceFinance.name;
    }
    const getUserDeleteUrl = ApiUrl + "StartUserLogin/DeleteUser/" + userName;

    axios
      .get(getUserDeleteUrl)
      .then((response) => {
        // Aquí asumimos que la API devolverá alguna forma de confirmación de que el usuario ha sido "eliminado" o desactivado
        if (response.status === 200) {
          // Asumiendo que la API devuelve un campo 'success'
          setMessage(
            "We've successfully closed your account. If your ever decide to return, we'll be here for you!"
          );
          setTimeout(() => {
            onClose(); // Cierra el popup y podría redirigir o cerrar sesión
          }, 6000);
          localStorage.removeItem("sessionTokenSpaceFinance");
          localStorage.clear();
          window.location.href = "/";
          setShowSuccessImage(true);
        } else {
          // Maneja el caso en que la API devuelva una respuesta que no sea de éxito
          setMessage("Unable to delete the account. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error processing your request:", error);
        setMessage(
          "An error occurred while trying to process your request. Please try again later."
        );
      })
      .finally(() => {
        setIsDeleting(false);
        setIsSubmitting(false);
      });
  };
  return (
    <>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}

      <div>
        <div className="menu-item content">Delete Account</div>
        <div className="menu-item content-2">
          Are you sure you want to delete your account?
        </div>
        <p>
          Please make sure, as this action is irreversible. If you have any
          concerns or need assistance, please contact us.
        </p>
        <button className="button-style-YesNot" onClick={onClose}>
          No
        </button>
        <button
          className="button-style-YesNot"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          {isDeleting ? "Deleting..." : "Yes"}
        </button>
        {message && <div className="message">{message}</div>}
        {showSuccessImage && (
          <div className="imageCheck">
            <img src={check} alt="Success" style={{ width: 30, height: 30 }} />
          </div>
        )}
      </div>
    </>
  );
};
export default DeleteAccountContent;
