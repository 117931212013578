import React, { useState } from "react";
import PropTypes from "prop-types";
import MyHeatmap from "./tickerMyHeatmap/MyHeatmap";
import PercentageChangeTable from "./tickerPercentageChange/PercentageChangeTable";
import StandardDeviation from "./tickerStandardDeviation/StandardDeviation";
import AnnualizedVolatility from "./tickerAnnualizedVolatility/AnnualizedVolatility";
import HistogramGraph from "./tickerHistogramGraph/HistogramGraph";
import IncomeStatement from "./tickerIncomeStatement/IncomeStatement";
import PortfolioMetricsTable from "./tickerPortfolioMetricsTable/PortfolioMetricsTable";
import HistoricalPrices from "./tickerHistoricalPrices/HistoricalPrices";
import BalanceSheet from "./tickerBalanceSheet/BalanceSheet";
import CashFlow from "./tickerCashFlow/CashFlow";
import Earnings from "./tickerEarnings/Earnings";
import CorrelationTable from "./tickerCorrelationTable/CorrelationTable";
import Dendrogram from "./tickerCorrelationTable/Dendrogram";
import Times from "./tickerTimes/Times";
import SentimentAnalysis from "./tickerSentimentAnalysis/SentimentAnalysis";
import "./BotResponse.css";
import MainHalving from "./tickerHalving/MainHalving";
import HalvingToNow from "./tickerHalving/HalvingToNow";
import PERatio from "./tickerPERatio/PERatio";
import MarketCap from "./tickerMarketCap/MarketCap";
import Analyst from "./tickerAnalyst/Analyst";
import Overview from "./tickerOverview/Overview";

const BotResponse = ({ response }) => {
  /*console.log("Type of response:", typeof response); // Add this line
  console.log("Value of response:", response); // Add this line*/

  const columnWidths = ["100px", "300px", "150px", "auto"];
  const [dataType, setDataType] = useState("5. adjusted close");
  const [frequency, setFrequency] = useState(response.preferred || "quarterly");
  const [frequency1, setFrequency1] = useState(
    response.preferred1 || "quarterly"
  );
  const [frequency2, setFrequency2] = useState(
    response.preferred2 || "quarterly"
  );
  const [frequency3, setFrequency3] = useState(
    response.preferred3 || "quarterly"
  );
  let preferredFrequency =
    response.preferred === "quarterly_dataframes" ? "quarterly" : "annually";
  let preferredFrequency1 =
    response.preferred1 === "quarterly_dataframes1" ? "quarterly" : "annually";
  let preferredFrequency2 =
    response.preferred2 === "quarterly_dataframes2" ? "quarterly" : "annually";
  let preferredFrequency3 =
    response.preferred3 === "quarterly_dataframes3" ? "quarterly" : "annually";

  let covMatrixData = {};

  // If cov_matrix is available in the response, populate covMatrixData.
  if (response && response.corr_matrix) {
    covMatrixData = response.corr_matrix;
  }

  let modifiedCorrelationMatrix2 = {};

  if (response && response.dataframe_correlation_matrix) {
    modifiedCorrelationMatrix2 = response.dataframe_correlation_matrix;
    // Rest of the code to process correlationMatrix
  }

  let modifiedCorrelationMatrix = null;
  if (response && response.correlation_matrix) {
    const correlationMatrix = response.correlation_matrix;
    const uniqueTickers = [...new Set(Object.keys(correlationMatrix))];

    modifiedCorrelationMatrix = uniqueTickers.reduce((acc, ticker1) => {
      acc[ticker1] = uniqueTickers.reduce((innerAcc, ticker2) => {
        innerAcc[ticker2] = correlationMatrix[ticker1][ticker2];
        return innerAcc;
      }, {});
      return acc;
    }, {});
  }

  if (response.error) {
    return <div className="error-message">{response.error}</div>;
  } else if (response.text) {
    return <div className="normal-text">{response.text}</div>;
  } else {
    return (
      <div>
        {/* Percentage Change Table */}
        {((dataType === "5. adjusted close" &&
          response.output_table_html_adjusted_close) ||
          (dataType === "4. close" && response.output_table_html_close)) && (
          <div className="output-table-html">
            <h2 className="percentagechangetable-title">
              Percentage Change Table
            </h2>
            {dataType === "5. adjusted close" ? (
              <PercentageChangeTable
                data={response.output_table_html_adjusted_close}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            ) : (
              <PercentageChangeTable
                data={response.output_table_html_close}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            )}
          </div>
        )}

        {/* PERation Table */}
        {((dataType === "5. adjusted close" &&
          response.output_table_html_adjusted_close3) ||
          (dataType === "4. close" && response.output_table_html_close3)) && (
          <div className="output-table-html">
            <h2 className="peratio-title">P/E Ratio</h2>
            {dataType === "5. adjusted close" ? (
              <PERatio
                data={response.output_table_html_adjusted_close3}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            ) : (
              <PERatio
                data={response.output_table_html_close3}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            )}
          </div>
        )}

        {/* MarketCap Table */}
        {((dataType === "5. adjusted close" &&
          response.output_table_html_adjusted_close4) ||
          (dataType === "4. close" && response.output_table_html_close4)) && (
          <div className="output-table-html">
            <h2 className="marketcap-title">MarketCap</h2>
            {dataType === "5. adjusted close" ? (
              <MarketCap
                data={response.output_table_html_adjusted_close4}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            ) : (
              <MarketCap
                data={response.output_table_html_close4}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            )}
          </div>
        )}

        {/* Analyst Table */}
        {((dataType === "5. adjusted close" &&
          response.output_table_html_adjusted_close5) ||
          (dataType === "4. close" && response.output_table_html_close5)) && (
          <div className="output-table-html">
            <h2 className="marketcap-title">Analyst</h2>
            {dataType === "5. adjusted close" ? (
              <Analyst
                data={response.output_table_html_adjusted_close5}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            ) : (
              <Analyst
                data={response.output_table_html_close5}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            )}
          </div>
        )}

        {/* Overview Table */}
        {((dataType === "5. adjusted close" &&
          response.output_table_html_adjusted_close6) ||
          (dataType === "4. close" && response.output_table_html_close6)) && (
          <div className="output-table-html">
            <h2 className="marketcap-title">Overview</h2>
            {dataType === "5. adjusted close" ? (
              <>
                {console.log(
                  "Data to be passed to Overview (adjusted close):",
                  response.output_table_html_adjusted_close6
                )}
                <Overview
                  data={response.output_table_html_adjusted_close6}
                  dataType={dataType}
                  handleDataChange={setDataType}
                  columnWidths={columnWidths}
                />
              </>
            ) : (
              <>
                {console.log(
                  "Data to be passed to Overview (close):",
                  response.output_table_html_close6
                )}
                <Overview
                  data={response.output_table_html_close6}
                  dataType={dataType}
                  handleDataChange={setDataType}
                  columnWidths={columnWidths}
                />
              </>
            )}
          </div>
        )}

        {/* Standard Deviation */}
        {((dataType === "5. adjusted close" &&
          response.output_table_html_adjusted_close1) ||
          (dataType === "4. close" && response.output_table_html_close1)) && (
          <div className="output-table-html">
            <h2 className="standard-deviation-title">
              Standard Deviation Table
            </h2>
            {dataType === "5. adjusted close" ? (
              <StandardDeviation
                data={response.output_table_html_adjusted_close1}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            ) : (
              <StandardDeviation
                data={response.output_table_html_close1}
                dataType={dataType}
                handleDataChange={setDataType}
              />
            )}
          </div>
        )}

        {/* Annualized volatility */}
        {((dataType === "5. adjusted close" &&
          response.output_table_html_adjusted_close2) ||
          (dataType === "4. close" && response.output_table_html_close2)) && (
          <div className="output-table-html">
            <h2 className="annualizedvolatility-title">
              Annualized Volatility Table
            </h2>
            <AnnualizedVolatility
              data={
                dataType === "5. adjusted close"
                  ? response.output_table_html_adjusted_close2
                  : response.output_table_html_close2
              }
              dataType={dataType}
              handleDataChange={setDataType}
              adjustedVolatilityData={response.adjusted_volatility}
              closeVolatilityData={response.close_volatility}
            />
          </div>
        )}

        {/* Histogram Graph */}
        {response.dataframe_daily_returns && (
          <div className="histogram-graph-container">
            <h2 className="histogram-title">Histogram Graph</h2>
            <HistogramGraph data={response.dataframe_daily_returns} />
            {response.dataframe_daily_returns_cryptos && (
              <HistogramGraph data={response.dataframe_daily_returns_cryptos} />
            )}
          </div>
        )}

        {/* Income Statement */}
        {response.quarterly_dataframes && response.annually_dataframes && (
          <div className="income-statement-container">
            <h2 className="income-statement-title">Income Statement</h2>
            <IncomeStatement
              data={{
                quarterly_dataframes: response.quarterly_dataframes,
                annually_dataframes: response.annually_dataframes,
                preferred: preferredFrequency, // Add this line to pass down the preferred frequency
              }}
              handleDataChange={setFrequency}
            />
          </div>
        )}
        {/* Portfolio Metrics Table */}
        {response.portfolio_metrics1 || response.portfolio_metrics2 ? (
          <div className="portfolio-metrics-container">
            {/*<h2 className="portfolio-metrics-title">Portfolio Metrics</h2>*/}
            <PortfolioMetricsTable
              data={{
                portfolio_metrics1: response.portfolio_metrics1,
                portfolio_metrics2: response.portfolio_metrics2,
                weights: response.weights,
                cov_matrix: response.corr_matrix,
                normalized_risk_contrib: response.normalized_risk_contrib,
                portfolio_return: response.portfolio_return,
                benchmark_return: response.benchmark_return,
                drawdown: response.drawdown,
              }}
            />

            {/* Display the covariance matrix heatmap here */}
            {covMatrixData && Object.keys(covMatrixData).length > 0 && (
              <div className="correlation-matrix">
                <h2 className="correlation-title">Correlation matrix</h2>
                <MyHeatmap data={covMatrixData} containerWidth={1200} />
              </div>
            )}
          </div>
        ) : (
          <div>{/* ... */}</div>
        )}

        {/* Correlation Table and Dendrogram */}
        {(response.dataframe_top_least_correlated_pairs &&
          response.dataframe_top_most_correlated_pairs) ||
        response.dendrogram ||
        (response.clusteredData && response.nClusters) ? (
          <div className="correlation-table-section">
            <h2 className="correlation-title">Correlation Table</h2>
            <CorrelationTable
              data={{
                dataframe_top_least_correlated_pairs:
                  response.dataframe_top_least_correlated_pairs,
                dataframe_top_most_correlated_pairs:
                  response.dataframe_top_most_correlated_pairs,
                dataframe_correlation_matrix: modifiedCorrelationMatrix2, // If you have this
                dendrogram: response.dendrogram, // Pass dendrogram data
                cluster_labels: response.cluster_labels,
                start_date: response.start_date, // Pass start date
                end_date: response.end_date, // Pass end date
              }}
            />
          </div>
        ) : null}

        {response.above_below_df1 ||
        response.above_below_df2 ||
        response.above_below_results4 ||
        response.above_below_results5 ||
        response.above_below_results6 ||
        response.above_below_results3 ||
        response.above_below_results11 ||
        response.above_below_df_date ||
        response.above_below_df_date1 ? (
          <div className="times-container">
            <h2 className="times-title">Times that happened</h2>
            <Times
              data1={response.above_below_df1}
              data6={response.above_below_df2}
              data2={response.above_below_results4}
              data3={response.above_below_results3}
              data4={response.above_below_df_date}
              data5={response.above_below_results6}
              data7={response.above_below_results11}
              data8={response.above_below_df_date1} // New data for cryptos
              data9={response.above_below_results10}
            />
          </div>
        ) : null}

        {/* Balance Sheet */}
        {response.quarterly_dataframes1 && response.annually_dataframes1 && (
          <div className="balance-sheet-container">
            <h2 className="balance-sheet-title">Balance Sheet</h2>
            <BalanceSheet
              data={{
                quarterly_dataframes1: response.quarterly_dataframes1,
                annually_dataframes1: response.annually_dataframes1,
                preferred1: preferredFrequency1, // Add this line to pass down the preferred frequency
              }}
              handleDataChange={setFrequency1}
            />
          </div>
        )}

        {/* Cash Flow */}
        {response.quarterly_dataframes2 && response.annually_dataframes2 && (
          <div className="cash-flow-container">
            <h2 className="cash-flow-title">Cash Flow</h2>
            <CashFlow
              data={{
                quarterly_dataframes2: response.quarterly_dataframes2,
                annually_dataframes2: response.annually_dataframes2,
                preferred2: preferredFrequency2, // Add this line to pass down the preferred frequency
              }}
              handleDataChange={setFrequency2}
            />
          </div>
        )}

        {/* Earnings */}
        {response.quarterly_dataframes3 && response.annually_dataframes3 && (
          <div className="earnings-container">
            <h2 className="earnings-title">Earnings</h2>
            <Earnings
              data={{
                quarterly_dataframes3: response.quarterly_dataframes3,
                annually_dataframes3: response.annually_dataframes3,
                preferred3: preferredFrequency3, // Add this line to pass down the preferred frequency
              }}
              handleDataChange={setFrequency3}
            />
          </div>
        )}

        {response &&
        response.sentiment_data &&
        Object.values(response.sentiment_data).some(
          (tickerData) =>
            tickerData.hasOwnProperty("news") ||
            tickerData.hasOwnProperty("sentiment1") ||
            tickerData.hasOwnProperty("sentiment2") ||
            tickerData.hasOwnProperty("sentiment3") ||
            tickerData.hasOwnProperty("sentiment4")
        ) ? (
          Object.values(response.sentiment_data).every(
            (tickerData) =>
              tickerData.news &&
              tickerData.sentiment1 &&
              tickerData.sentiment2 &&
              tickerData.sentiment3 &&
              tickerData.sentiment4
          ) ? (
            <div className="news-times-section-container">
              <h2 className="news-times-title">News</h2>
              {/* Pass `response.sentiment_data` instead of `response` */}
              <SentimentAnalysis data={response.sentiment_data} />
            </div>
          ) : (
            <p>No news available</p>
          )
        ) : null}

        {/* Historical Prices */}
        {response.output_html_adjusted_close && (
          <div className="historical-prices-container">
            <h2 className="historical-prices-title">Historical Prices</h2>
            <HistoricalPrices
              dataString={response.output_html_adjusted_close}
              cryptoData={response.output_html_crypto}
            />
          </div>
        )}

        {response.crypto_weekly_dfs &&
          response.crypto_monthly_dfs &&
          response.stocks_weekly_dfs &&
          response.stocks_monthly_dfs &&
          response.global_metrics_df1 &&
          response.global_metrics_df2 &&
          response.btc_metrics_df &&
          response.best_months_df &&
          response.worst_months_df &&
          response.best_weeks_df &&
          response.worst_weeks_df && (
            <div>
              <MainHalving
                cryptoData={{
                  weekly: response.crypto_weekly_dfs,
                  monthly: response.crypto_monthly_dfs,
                }}
                stockData={{
                  weekly: response.stocks_weekly_dfs,
                  monthly: response.stocks_monthly_dfs,
                }}
                globalMetrics={response.global_metrics_df1}
                globalMetrics2={response.global_metrics_df2}
                btcMetrics={response.btc_metrics_df} // Pass the btc_metrics_df data
                bestMonths={response.best_months_df} // Pass the best months data
                worstMonths={response.worst_months_df} // Pass the worst months data
                bestWeeks={response.best_weeks_df} // Pass the best weeks data
                worstWeeks={response.worst_weeks_df} // Pass the worst weeks data
              />
              <main className="crypto-halvingToNow">
                <section className="crypto-halving-sectionToNow">
                  <h2 className="crypto-halving-subtitleToNow">
                    Percentage change from Halving (April 2024) to today
                  </h2>
                  <div className="crypto-halving-tableToNow">
                    <HalvingToNow data={response.percentage_change_df} />
                  </div>
                </section>
              </main>
            </div>
          )}

        {/* Correlation Matrix, Out of Range, Invalid Ticker */}
        <div>
          {modifiedCorrelationMatrix &&
            Object.keys(modifiedCorrelationMatrix).length > 1 && (
              <div className="correlation-matrix">
                <h2 className="correlation-title">Correlation Matrix</h2>
                <MyHeatmap
                  data={modifiedCorrelationMatrix}
                  containerWidth={1200}
                />
              </div>
            )}

          {/* benchmark_error_message */}
          {typeof response.benchmark_error_message === "string" &&
            response.benchmark_error_message.trim() !== "" && (
              <div className="outofrange-container-response">
                <h2 className="outofrange-title-response">Error</h2>
                <div
                  className="outofrange-data-response"
                  dangerouslySetInnerHTML={{
                    __html: response.benchmark_error_message,
                  }}
                ></div>
              </div>
            )}

          {/* benchmark_error_messagee - Array Check */}
          {Array.isArray(response.benchmark_error_message) &&
            response.benchmark_error_message.length > 0 && (
              <div className="outofrange-container-response">
                <h2 className="outofrange-title-response">Error</h2>
                <ul className="outofrange-data-response">
                  {response.benchmark_error_message.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            )}

          {/* Out of Range - String Check */}
          {typeof response.output_out_of_range_html === "string" &&
            response.output_out_of_range_html.trim() !== "" && (
              <div className="outofrange-container">
                <h2 className="outofrange-title">Out of Range</h2>
                <div
                  className="outofrange-data"
                  dangerouslySetInnerHTML={{
                    __html: response.output_out_of_range_html,
                  }}
                ></div>
              </div>
            )}

          {/* Out of Range - Array Check */}
          {Array.isArray(response.output_out_of_range_html) &&
            response.output_out_of_range_html.length > 0 && (
              <div className="outofrange-container-response">
                <h2 className="outofrange-title-response">Out of Range</h2>
                <ul className="outofrange-data-response">
                  {response.output_out_of_range_html.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            )}

          {/* Invalid Ticker for cryptos - String Check */}
          {typeof response.crypto_error_message === "string" &&
            response.crypto_error_message.trim() !== "" && (
              <div className="output-invalid-container-response">
                <h2 className="output-invalid-title-response">
                  Invalid Ticker
                </h2>
                <div
                  className="output-invalid-data-response"
                  dangerouslySetInnerHTML={{
                    __html: response.crypto_error_message,
                  }}
                ></div>
              </div>
            )}

          {/* Invalid Ticker - String Check */}
          {typeof response.output_invalid_html === "string" &&
            response.output_invalid_html.trim() !== "" && (
              <div className="output-invalid-container-response">
                <h2 className="output-invalid-title">Invalid Ticker</h2>
                <div
                  className="output-invalid-data-response"
                  dangerouslySetInnerHTML={{
                    __html: response.output_invalid_html,
                  }}
                ></div>
              </div>
            )}

          {/* Invalid Ticker - Array Check */}
          {Array.isArray(response.output_invalid_html) &&
            response.output_invalid_html.length > 0 && (
              <div className="output-invalid-container-response">
                <h2 className="output-invalid-title-response">
                  Invalid Ticker
                </h2>
                <ul className="output-invalid-data-response">
                  {response.output_invalid_html.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
        </div>
      </div>
    );
  }
};

BotResponse.propTypes = {
  response: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};
export default BotResponse;
