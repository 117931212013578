import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./TotalScoreSentiment.css";

const TotalScoreSentiment = ({ data }) => {
  const cardRef = useRef(null);
  const [sentimentClass, setSentimentClass] = React.useState("card-neutral");

  useEffect(() => {
    const renderData = (data) => {
      const container = d3.select(cardRef.current);
      container.selectAll("*").remove();

      const avgScore = data.average_score_all_categories2;
      const sentimentLabel = data.sentiment_label_for_average;

      setSentimentClass(getSentimentClass(sentimentLabel));

      const sentimentLabelRow = container
        .append("div")
        .attr("class", "row sentiment-label-row");
        
      sentimentLabelRow.append("p").attr("class", "data1").text(sentimentLabel);

      const avgScoreRow = container
        .append("div")
        .attr("class", "row average-score-row");
        
      avgScoreRow.append("p").attr("class", "heading").text("Average Score: ");
      avgScoreRow.append("p").attr("class", "data").text(avgScore);
    };

    const getSentimentClass = (sentiment) => {
      const sentimentClasses = {
        "Bullish": "card-bullish",
        "Somewhat-Bullish": "card-somewhat-bullish",
        "Neutral": "card-neutral",
        "Somewhat-Bearish": "card-somewhat-bearish",
        "Bearish": "card-bearish",
      };
      return sentimentClasses[sentiment] || "card-neutral";
    };

    renderData(data);
  }, [data]);

  return (
    <div ref={cardRef} className={`card ${sentimentClass}`}>
      <p className="title">Sentiment Analysis</p>
    </div>
  );
};

export default TotalScoreSentiment;
