import { configureStore } from "@reduxjs/toolkit";
import { articleApi } from "./articleApi"; // Make sure the path to articleApi is correct

export const store = configureStore({
    reducer: {
        [articleApi.reducerPath]: articleApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(articleApi.middleware),
});


