import React, { useState, useEffect } from "react";
import "../login/Login.css";
import axios from "axios";
/*import Google from "./assets/google.png";
import Facebook from "./assets/facebook.png";
import Github from "./assets/github.png";*/
import Login from "../login/Login";
import GetStartedSendCode from "./GetStartedSendCode";
import RingLoader from "react-spinners/RingLoader";
import { ApiUrl } from "../services/restAPI";
import { jwtDecode } from 'jwt-decode';
import ChatBot from "../Chatbot";
import ClientCredentials from "../services/ClientCredentials";

/*Google Services */
import { TypeUserLoginGoogle } from "../services/restAPI";

/*Facebook Services */

/*Twitter Services */

const GetStarted = ({ onAuthenticate, onClose }) => {
  const [form, setForm] = useState({
    userName: "",
    password: "",
    newPassword: "",
    retryPassword: "",
    numberToken: "",
    type: "LP",
  });

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSendCode, setShowSendCode] = useState(false);
  const [showLoginSignUp, setShowLoginSignUp] = useState(false);
  const [userSpaceFinance, setUserSpaceFinance] = useState({});
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [showChatBoot, setshowChatBoot] = useState(false);

  function handleCallbackReponse(response) { 
    var userObject = jwtDecode(response.credential);
    console.log(userObject);
    setUserSpaceFinance({
      email: userObject.email,
      emailVerified: userObject.email_verified,
      name: userObject.name,
      givenName: userObject.given_name,
      familyName: userObject.family_name,
      picture: userObject.picture,
      locale: userObject.locale,   
    });
  
    const username = userObject.email; // Obtener el nombre de usuario del objeto decodificado
    const code = userObject.aud; // Definir un código de ejemplo, ya que el inicio de sesión se realiza con Google
    const type = TypeUserLoginGoogle; // Definir el tipo de inicio de sesión (por ejemplo, Google)
    const firstName = userObject.given_name; // Obtener el nombre usuario
    const lastName = userObject.family_name; // Obtener el apellido del usuario
    const photo = userObject.picture; // Obtener la foto del usuario
     
    // Verificar si el usuario ya completó el registro en la base de datos
    axios.post(`${ApiUrl}/StartUserLogin/CompleteSignupLogin`, { username, code, type, firstName, lastName, photo})
      .then(response => {
        const { item1, item2 } = response.data;
        if (item1 === null && item2 && item2.iD_User) {
          // Si el usuario existe en la base de datos, mostrar el ChatBot                    
          localStorage.setItem("sessionTokenSpaceFinance", item2.token);      
          localStorage.setItem("usersSpaceFinance", JSON.stringify(item2));    
          setshowChatBoot(true);              
        } else {
          // Si el usuario no completó el registro, mostrar el mensaje de error
          handleSignOut();
          setError(true);
          setErrorMessage(item1.message || "User not found in the database.");
        }
      })
      .catch(error => {
        // Manejar errores de la solicitud
        setError(true);        
        setErrorMessage("Error verifying user. Please try again later.");
        console.log("error: " + error)
      });
     
    document.getElementById("signInDiv").hidden = true;    
  }  

  function handleSignOut(){
    setUserSpaceFinance({});
    document.getElementById("signInDiv").hidden = false;
  }

  useEffect(() => {
    setIsSubmitting(true); 
    /*global google */
    google.accounts.id.initialize({
      client_id: ClientCredentials.clientId,
      callback: handleCallbackReponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      {theme: "outline", size:"large"}
    );

    google.accounts.id.prompt();

    // Verificación de Edge y aplicación de estilos específicos
    function isEdge() {
      return navigator.userAgent.indexOf("Edg") !== -1;
    }

    if (isEdge()) {
      const loginButtonContinue = document.querySelector(".loginButtonContinue");
      if (loginButtonContinue) {
        loginButtonContinue.style.width = "290px";
        loginButtonContinue.style.padding = "15px 20px";
      }
    }
    setIsSubmitting(false);
  }, []);

  const eventHandlerSubmit = (e) => {
    e.preventDefault();
    eventHandlerButton();
  };

  const eventHandlerChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const eventHandlerButton = async () => {
    if (!validateForm()) return;
    setIsSubmitting(true);
    let urlAPI = ApiUrl + "StartUserLogin/CompleteSignup";

    try {
      const response = await axios.post(urlAPI, form);
      const responseData = response.data;

      if (responseData.item2 > 0) {
        setShowSendCode(true);
        setError(false);
        setErrorMessage("");
      } else {
        setShowSendCode(false);
        setError(true);
        setErrorMessage(responseData.item1.message);
      }
    } catch (error) {
      console.error(error);
      setShowSendCode(false);
      setError(true);
      setErrorMessage("Error connecting to the API.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = () => {
    const { userName, password } = form;
    let isValid = true;
    let errorMsg = "";

    if (!userName || !password) {
      errorMsg = "Please enter your username and password to proceed.";
      isValid = false;
    }

    setError(!isValid);
    setErrorMessage(errorMsg);
    return isValid;
  };

  const showLoginSignUpForm = () => setShowLoginSignUp(true);
  const backToLogin = () => {
    setShowLoginSignUp(false);
  };

  if (showLoginSignUp) {
    return <Login onBackToLogin={backToLogin} onClose={onClose}/>;
  }

  if (showSendCode) {
    return <GetStartedSendCode userName={form.userName} />;
  }

  if(showChatBoot){                     
    return <ChatBot userSpaceFinance={ userSpaceFinance } selectedTickers={selectedTickers} />
  }  

  return (
    <React.Fragment>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}
      <div className="login fadeInDown">
        <div className="wrapper">
          <div id="formContent">
            <div className="fadeIn first">
              {/*<img src={spacefinance} alt="Space Finance Icon" className="icon" />*/}
              <h2 className="forgot-password-header">
                Welcome to Space Finance
              </h2>
              <button onClick={onClose} className="popup-close-btn">X</button>
              <p className="description-text">
                Create your free accounts to get starter.
              </p>
              <div className="login-left">
                <div className="loginButtonContinue google" id="signInDiv"></div>  
                {/*
                <div
                  className="loginButton google"
                  onClick={handleGoogleLogin}
                  role="button"
                  tabIndex="0"
                >
                  <img src={Google} alt="" className="icon" />
                  Google
                </div>
                <div className="loginButton facebook">
                  <img src={Facebook} alt="Facebook" className="icon" />
                  Facebook
                </div>
                <div className="loginButton github">
                  <img src={Github} alt="Github" className="icon" />
                  Github
                </div>*/}
              </div>
              <div className="center">
                <div className="line" />
                <div className="or">OR</div>
              </div>
            </div>
            <div className="login-right">
              <form onSubmit={eventHandlerSubmit}>
                <input
                  type="text"
                  className="fadeIn second"
                  name="userName"
                  placeholder="Email address"
                  onChange={eventHandlerChange}
                />
                <input
                  type="password"
                  className="fadeIn third"
                  name="password"
                  placeholder="Password"
                  onChange={eventHandlerChange}
                />
                <input
                  type="submit"
                  className="fadeIn fourth"
                  value="Complete Signup"
                />
                <div id="signupLink" className="text-left fadeIn fourth">
                  <span className="no-account-text">
                    You are already user. Please clik {""}
                  </span>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showLoginSignUpForm();
                    }}
                    className="link-style"
                  >
                    login
                  </a>
                  <span className="no-account-text">
                  {" "}to continue.
                  </span>                  
                </div>
                <p className="description-text-policy">
                  By clicking 'complete signup', you agree to our terms. Learn more about how we collect, use your data in the <a href="./privacy-policy.html" target="_blank" className="link-style-policy">privacy policy</a>, as well as we use cookies and similar technologies in the <a href="./privacy-policy.html" className="link-style-policy" target="_blank">cookies policy</a>.
                </p>
                <br></br>
              </form>
              {error && (
                <div
                  id="formFooter"
                  className="alert alert-danger"
                  role="alert"
                >
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GetStarted;
