import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./BarGraph3.css";

const BarGraph3 = ({ data, isCrypto = false }) => {
  const ref = useRef();

  // Include all days for cryptos, otherwise use weekdays only
  const weekdays = isCrypto
    ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    : ["Mon", "Tue", "Wed", "Thu", "Fri"];

  useEffect(() => {
    const cleanup = () => {
      d3.select(".tooltip").remove();
    };

    if (!data) return cleanup;

    const container = d3.select(ref.current);
    container.selectAll("*").remove();

    const transformedDataByTicker = Object.entries(data).map(
      ([key, tickerData]) => {
        const aboveData = weekdays.map(
          (day) => tickerData.above_weekdays[day] || 0
        );
        const belowData = weekdays.map(
          (day) => tickerData.below_weekdays[day] || 0
        );
        return {
          ticker: key,
          above: aboveData,
          below: belowData,
        };
      }
    );

    transformedDataByTicker.forEach((tickerData) => {
      // Container for each ticker
      const tickerContainer = container
        .append("div")
        .attr("class", "ticker-container")
        .style("display", "flex");

      createBarChart(
        tickerContainer,
        tickerData.ticker,
        `${tickerData.ticker} - Above: Weekday increases`,
        tickerData.above,
        "#47C714"
      );

      createBarChart(
        tickerContainer,
        tickerData.ticker,
        `${tickerData.ticker} - Below: Weekday decreases`,
        tickerData.below,
        "#F94040"
      );
    });
  }, [data, isCrypto]);

  const createBarChart = (mainContainer, ticker, title, data, color) => {
    // Dimensiones y márgenes del gráfico
    const margin = { top: 20, right: 30, bottom: 40, left: 30 };
    const width = 350 - margin.left - margin.right;
    const height = 200 - margin.top - margin.bottom;

    // Contenedor específico para este gráfico de barras
    const chartContainer = mainContainer
      .append("div")
      .attr("class", "chart-container");

    // Añadir el título del gráfico
    chartContainer.append("h4").text(title).style("color", color);

    // Creación del elemento SVG
    const svg = chartContainer
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Creación del elemento tooltip
    // Asegurarte de que el tooltip tenga un z-index alto
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("className", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "#238bb0")
      .style("color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("z-index", "10000");// Un valor muy alto para asegurar que está por encima de otros elementos

    // Configuración del eje Y
    const y = d3.scaleBand().range([0, height]).domain(weekdays).padding(0.1);
    svg.append("g").call(d3.axisLeft(y));

    // Configuración del eje X
    const x = d3
      .scaleLinear()
      .domain([0, d3.max(data)])
      .range([0, width]);

    // Calcular un número adecuado de ticks basado en el rango de datos
    const xMax = d3.max(data);
    const stepSize = Math.ceil(xMax / 10); // Puedes ajustar este valor
    let tickValues = d3.range(0, xMax + stepSize, stepSize);

    // Asegurarse de que siempre se incluya el cero y el máximo
    if (tickValues[0] !== 0) {
      tickValues.unshift(0);
    }
    if (tickValues[tickValues.length - 1] < xMax) {
      tickValues.push(xMax);
    }

    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).tickValues(tickValues).tickFormat(d3.format("d")));

    // Creación de las barras
    svg
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", 3)
      .attr("y", (d, i) => y(weekdays[i]))
      .attr("width", (d) => x(d))
      .attr("height", y.bandwidth())
      .attr("fill", color)
      .attr("rx", 5) // Ajusta este valor para controlar el redondeo en el eje X
      .attr("ry", 5) // Ajusta este valor para controlar el redondeo en el eje Y
      // Asegurar que el tooltip no se esconda por cualquier transformación CSS o por estar fuera de la vista
      .on("mouseover", function (event, d) {
        tooltip
          .html("Valor: " + d)
          .style("opacity", 1)
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY + 10 + "px");
      })
      .on("mousemove", function (event) {
        tooltip
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY + 10 + "px");
      })
      .on("mouseout", function () {
        tooltip.style("opacity", 0);
      });  
  };

  return <div className="barGraph3" ref={ref} />;
};

export default BarGraph3;
