import React, { useState } from "react";
import axios from "axios";
import "./WaitlistPopup.css";
import { ApiUrl } from "../services/restAPI";

const WaitlistPopup = ({ userSpaceFinance, onClose }) => {
  //const [name, setName] = useState("");
  //const [surname, setSurname] = useState("");
  //const [country, setCountry] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getDisplayName = () => {
    if (userSpaceFinance.email) {
      return userSpaceFinance.email;
    }
    else if (userSpaceFinance.name) {
      return userSpaceFinance.name;
    } 
    return userSpaceFinance.name;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    onClose();
    /*try {
      const userDataWaitList = {
        userName: getDisplayName(),
      };
      const UrlApiWaitlist = ApiUrl + "BusinessUserDetail/SaveWaitList";
      await axios.post(UrlApiWaitlist, userDataWaitList);
    } catch (error) {
      console.error("Failed to save waitlist", error);
      setIsSubmitting(false);
    } finally {
      onClose();
    }*/
    //handleLogout(); // Salir de la aplicación
  };

  const handleLogout = () => {
    localStorage.removeItem("sessionTokenSpaceFinance");
    localStorage.removeItem("privacyAccepted");
    localStorage.removeItem("usersSpaceFinance");
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className="waitlist-popup-overlay">
      <div className="waitlist-popup">
        <div className="waitlist-popup-header">
          We're delighted to have you on our waitlist
        </div>
        <div className="waitlist-popup-content">
          <p>
            With so many excited users like yourself joining us, our system is a
            bit overwhelmed at the moment.
          </p>
          <p>
            But don't worry, we're here to assist you! We can keep you informed about all the
            exciting things ahead through the email you provided us.
          </p>

          <form>
            {/*<div className="form-group-WaitList">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group-WaitList">
              <label htmlFor="surname">Surname:</label>
              <input
                type="text"
                id="surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
              />
            </div>
            <div className="form-group-WaitList">
              <label htmlFor="country">Country:</label>
              <input
                type="text"
                id="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
            </div>*/}
            <br></br>                  
            <div className="button-container-WaitList">              
              <button
                type="submit"
                className="button-style-WaitList"
                onClick={handleSubmit}
              >
                Accept
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WaitlistPopup;
