import axios from 'axios';

// Function to convert text to audio using ElevenLabs API
const convertTextToAudio = async (textToConvert) => {
  const apiKey = '787e3717be8e73b7d7a19d3226262a63'; 
  const voiceId = 'yoZ06aMxZJJ28mfd3POQ';
  const optimizeStreamingLatency = 0; // default value
  const outputFormat = 'mp3_44100_128'; // default value
  
  // Construct the full URL with query parameters
  const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream?optimize_streaming_latency=${optimizeStreamingLatency}&output_format=${outputFormat}`;

  const apiRequestOptions = {
    method: 'POST',
    url: url,
    headers: {
      'Accept': 'audio/mpeg',
      'Content-Type': 'application/json',
      'xi-api-key': apiKey,
    },
    data: {
      text: textToConvert,
      model_id: 'eleven_monolingual_v1', // default or your custom model ID
      voice_settings: {
        stability: 0,
        similarity_boost: 0,
        style: 0,
        use_speaker_boost: true
      }
    },
    responseType: 'blob', // To receive the audio data as a blob
  };

// Inside your convertTextToAudio function
try {
  console.log('Making API request with text:', textToConvert);
  const apiResponse = await axios.request(apiRequestOptions);
  console.log('API response received:', apiResponse);
  const audioUrl = URL.createObjectURL(apiResponse.data);
  console.log('Audio URL created:', audioUrl);
  return audioUrl;
} catch (error) {
  console.error('Error converting text to audio:', error);
  throw error;
}

};

export default convertTextToAudio;





//ErXwobaYiN019PkySvjV
//D38z5RcWu1voky8WS1ja
//IKne3meq5aSn9XLyUdCD