export const ApiUrlPrueba = "https://api.solodata.es/";
//primera version
/*export const ApiUrl = "https://wbusinesslogicspacefinanceapi.azurewebsites.net/api/";*/

//PRODUCCION
export const ApiUrl = "https://wbusinesslogicspacefinanceproapi.azurewebsites.net/api/"

//TEST
//export const ApiUrl = "https://wbusinesslogicspacefinancetestapi.azurewebsites.net/api/"


/*Google Api */
export const TypeUserLoginGoogle = "GL";
export const RedirectUri = "http://localhost:3000/";
//export const RedirectUri = "https://spacefinancetest.azurewebsites.net/";
export const Scope = "https://www.googleapis.com/auth/userinfo.email";
export const TokenExchangeUrl = "https://oauth2.googleapis.com/token";
export const GoogleUserInfoUrl = "https://www.googleapis.com/oauth2/v2/userinfo";
export const GrantType= "authorization_code";

/*Facebook Api*/

/*Twitter Api*/