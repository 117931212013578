import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { Card } from "antd";

const styles = {
  card: {
    borderRadius: "42px",
    padding: "10px",
    width: "90%",
    height: "315px",
    backgroundColor: "transparent",
    border: "none",
  },
};

const LineChart2 = ({
  portfolio_return,
  benchmark_return,
  initialWidth = 950,
  height = 280,
}) => {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({
    width: initialWidth,
    height: height,
  });

  const margin = { top: 5, right: 40, bottom: 70, left: 10 },
    innerWidth = dimensions.width - margin.left - margin.right,
    innerHeight = dimensions.height - margin.top - margin.bottom;

  const numberOfXTicks = 7;
  const numberOfYTicks = 8;

  const formatDate = d3.timeFormat("%b-%d-%y");
  const formatDateFull = d3.timeFormat("%B %d, %Y");

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: ref.current.parentElement.offsetWidth,
        height: height,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (
      !portfolio_return ||
      !portfolio_return.date ||
      !portfolio_return.value ||
      !benchmark_return ||
      !benchmark_return.date ||
      !benchmark_return.value
    ) {
      console.error("Data is empty!");
      return;
    }
  
    const dateData = portfolio_return.date;
    const valueData = portfolio_return.value;
    const transformedData = Object.keys(dateData).map((key) => {
      return {
        date: new Date(dateData[key]),
        value: +valueData[key] * 100,
      };
    });
    const newData = transformedData.sort((a, b) => a.date - b.date);
  
    const benchmarkDateData = benchmark_return.date;
    const benchmarkValueData = benchmark_return.value;
    const transformedBenchmarkData = Object.keys(benchmarkDateData).map(
      (key) => {
        return {
          date: new Date(benchmarkDateData[key]),
          value: +benchmarkValueData[key] * 100,
        };
      }
    );
    const sortedBenchmarkData = transformedBenchmarkData.sort(
      (a, b) => a.date - b.date
    );
  
    const allData = [...newData, ...sortedBenchmarkData];
    const minValue = d3.min(allData, (d) => d.value);
    const maxValue = d3.max(allData, (d) => d.value);
  
    d3.select(ref.current).selectAll("*").remove();
    const svg = d3
      .select(ref.current)
      .attr("width", dimensions.width + 15)
      .attr("height", dimensions.height);
  
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(allData, (d) => d.date))
      .range([margin.left, dimensions.width - margin.right]);
    let step = 1000;
    if (maxValue <= 1000) step = 100;
    else if (maxValue <= 2000) step = 200;
  
    const tickCount = Math.ceil(maxValue / step);
    const adjustedMax = tickCount * step;
    const yScale = d3
      .scaleLinear()
      .domain([minValue, adjustedMax])
      .range([innerHeight, 0]);
  
    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);
  
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(numberOfXTicks)
      .tickFormat(formatDate)
      .tickSize(0);
    const gx = g
      .append("g")
      .attr("transform", `translate(0, ${innerHeight + 10})`)
      .style("font-size", "10px")
      .style("color", "#3462A0")
      .call(xAxis);
    gx.selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-0.4em")
      .attr("dy", ".50em")
      .attr("transform", "rotate(-45)");
    gx.selectAll(".tick line").remove();
    gx.select(".domain").attr("stroke", "#E5E6E7");
  
    const yAxis = d3
      .axisRight(yScale)
      .ticks(numberOfYTicks)
      .tickFormat((d) => `${d}%`)
      .tickSize(0);
    const gy = g
      .append("g")
      .attr("transform", `translate(${innerWidth + 20}, 0)`)
      .style("font-size", "13px")
      .style("color", "#3462A0")
      .call(yAxis);
    gy.call((g) => g.select(".domain").remove())
      .selectAll(".tick line")
      .remove();
  
    const area = d3
      .area()
      .x((d) => xScale(d.date))
      .y0(innerHeight)
      .y1((d) => yScale(d.value))
      .curve(d3.curveMonotoneX);
  
    const defs = svg.append("defs");
  
    const gradient = defs
      .append("radialGradient")
      .attr("id", "line-chart2-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("cx", "40%")
      .attr("cy", "70%")
      .attr("r", "110%")
      .attr("fx", "30%")
      .attr("fy", "60%");
  
    gradient
      .append("stop")
      .attr("offset", "0")
      .attr("stop-color", "transparent")
      .attr("stop-opacity", 0.1);
  
    gradient
      .append("stop")
      .attr("offset", "1")
      .attr("stop-color", "transparent")
      .attr("stop-opacity", 0);
  
    const radius = 20;
    const clip = defs
      .append("clipPath")
      .attr("id", "clip")
      .append("path")
      .attr(
        "d",
        `M ${radius},0 h ${
          innerWidth - 2 * radius
        } a ${radius},${radius} 0 0 1 ${radius},${radius} v ${
          innerHeight - 2 * radius
        } a ${radius},${radius} 0 0 1 -${radius},${radius} h ${
          -innerWidth + 3 * radius
        } a ${radius},${radius} 0 0 1 -${radius},-${radius} v ${
          -innerHeight + 2 * radius
        } a ${radius},${radius} 0 0 1 ${radius},-${radius} z`
      );
  
    g.append("path")
      .datum(newData)
      .attr("class", "area")
      .attr("d", area)
      .style("fill", "url(#line-chart2-gradient)")
      .style("clip-path", "url(#clip)")
      .style("filter", "url(#rounded-corners)");
  
    const line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
      .curve(d3.curveMonotoneX);
  
    g.append("path")
      .datum(newData)
      .attr("fill", "none")
      .attr("stroke", "#0A0AA4")
      .attr("stroke-width", 1.5)
      .attr("d", line);
  
    const benchmarkLine = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
      .curve(d3.curveMonotoneX);
  
    g.append("path")
      .datum(sortedBenchmarkData)
      .attr("fill", "none")
      .attr("stroke", "#F9409E")
      .attr("stroke-width", 1.5)
      .attr("d", benchmarkLine);
  
    const focusPortfolio = g.append("g").style("display", "none");
    focusPortfolio
      .append("circle")
      .attr("r", 5)
      .attr("stroke", "#0A0AA4")
      .attr("fill", "white")
      .attr("stroke-width", 2);
  
    const tooltipPortfolio = d3
      .select("body")
      .append("div")
      .attr("id", "tooltip-portfolio")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "#238bb0")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("z-index", "2")
      .style("box-shadow", "0px 10px 10px rgba(0,0,0,0.1)")
      .style("border", "1px solid white")
      .style("text-align", "center");
  
    const focusBenchmark = g.append("g").style("display", "none");
    focusBenchmark
      .append("circle")
      .attr("r", 5)
      .attr("stroke", "#F9409E")
      .attr("fill", "white")
      .attr("stroke-width", 2);
  
    const tooltipBenchmark = d3
      .select("body")
      .append("div")
      .attr("id", "tooltip-benchmark")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "#238bb0")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("z-index", "2")
      .style("box-shadow", "0px 10px 10px rgba(0,0,0,0.1)")
      .style("border", "1px solid white")
      .style("text-align", "center");
  
    g.append("rect")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mouseover", function () {
        focusPortfolio.style("display", null);
        focusBenchmark.style("display", null);
      })
      .on("mouseout", function () {
        focusPortfolio.style("display", "none");
        focusBenchmark.style("display", "none");
        tooltipPortfolio.style("visibility", "hidden");
        tooltipBenchmark.style("visibility", "hidden");
      })
      .on("mousemove", function (event) {
        const pointer = d3.pointer(event, this);
        const pointerDate = xScale.invert(pointer[0]);
        const bisectDate = d3.bisector((d) => d.date).left;
  
        updateTooltip(newData, focusPortfolio, tooltipPortfolio, pointerDate, bisectDate, "#0A0AA4", event, 'portfolio');
        updateTooltip(sortedBenchmarkData, focusBenchmark, tooltipBenchmark, pointerDate, bisectDate, "#F9409E", event, 'benchmark');
      });
  
    function updateTooltip(data, focus, tooltip, pointerDate, bisectDate, color, event, type) {
      let idx = bisectDate(data, pointerDate);
      if (idx > 0 && idx < data.length) {
        const d0 = data[idx - 1];
        const d1 = data[idx];
        idx = pointerDate - d0.date > d1.date - pointerDate ? idx : idx - 1;
      } else if (idx >= data.length) {
        idx = data.length - 1;
      }
      const d = data[idx];
  
      focus.attr("transform", `translate(${xScale(d.date)}, ${yScale(d.value)})`);
  
      const tooltipWidth = 120;
      const pageXOffset = type === 'portfolio' ? -tooltipWidth - 10 : 10;
  
      tooltip
        .html(`<span style="font-size:16px; font-weight:bold; color:white">${d.value.toFixed(0)}%</span><br><span style="font-size:12px; color:white">${formatDateFull(d.date)}</span>`)
        .style("left", `${event.pageX + pageXOffset}px`)
        .style("top", `${event.pageY - 100}px`)
        .style("visibility", "visible")
        .style("background", color);
    }
  
    const legendSpacing = 120;
    const legendHeight = innerHeight + 70;
  
    const legends = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${legendHeight})`);
  
    const legendData = [
      { color: "#0A0AA4", name: "Portfolio" },
      { color: "#F9409E", name: "Benchmark" },
    ];
  
    legends
      .selectAll("rect")
      .data(legendData)
      .enter()
      .append("rect")
      .attr("x", (d, i) => i * legendSpacing)
      .attr("y", 0)
      .attr("width", 45)
      .attr("height", 3)
      .style("fill", (d) => d.color);
  
    legends
      .selectAll("text")
      .data(legendData)
      .enter()
      .append("text")
      .attr("x", (d, i) => i * legendSpacing + 50)
      .attr("y", 5)
      .text((d) => d.name)
      .style("font-size", "12px")
      .style("font-family", "sans-serif")
      .style("fill", "#3462A0");
  
    // Cleanup function to remove tooltips
    return () => {
      tooltipPortfolio.remove();
      tooltipBenchmark.remove();
    };
  }, [dimensions, height, portfolio_return]);
  

  return (
    <div>
      <Card style={styles.card}>
        <svg
          ref={ref}
          width={dimensions.width}
          height={dimensions.height}
        ></svg>
      </Card>
    </div>
  );
};

export default LineChart2;
