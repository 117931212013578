import React from "react";
import AboveBelowTable from "./AboveBelowTable";
import BarGraph3 from "./BarGraph3"; // Import your BarGraph component
import MonthAboveBelow from "./MonthAboveBelow"; // Import your BarGraph component
import AboveBelowDataFrames from "./AboveBelowDataFrames"; // Import your BarGraph component
//import WeekDayAboveBelowProb from './WeekDayAboveBelowProb';// Import your BarGraph component
//import MonthAboveBelowProb from './MonthAboveBelowProb';// Import your BarGraph component
import "./Times.css";
import AboveBelowTableSection from "./AboveBelowTableSection";

// ... your imports ...

function Times(props) {
  const { data1, data2, data3, data4, data5, data6, data7, data8, data9 } =
    props;

  console.log("Data1 received:", data1);
  console.log("Data2 received:", data2);

  const tickersSet = new Set([
    ...Object.keys(data2 || {}),
    ...Object.keys(data3 || {}),
    ...Object.keys(data4 || {}),
    ...Object.keys(data5 || {}),
    ...Object.keys(data6 || {}),
  ]);

  const uniqueTickers = [...tickersSet];

  // Filtrar tickers que tienen data para AboveBelowTable
  const tickersWithAboveBelowTable = uniqueTickers.filter(
    (ticker) => (data1 && data1[ticker]) || (data6 && data6[ticker])
  );

  // Verificar si hay datos en data1 o data6
  const data1Length = Object.keys(data1 || {}).length;
  const data6Length = Object.keys(data6 || {}).length;

  // Condición modificada para mostrar AboveBelowTableSection
  const shouldShowAboveBelowTableSection =
    data1Length + data6Length > 1 &&
    !(data1Length === 1 && data6Length === 0) &&
    !(data1Length === 0 && data6Length === 1);

  const aboveStyle = { color: '#47C714' };
  const belowStyle = { color: '#F94040' };

  return (
    <div className="times-table">
      <h2></h2>

      {/* Renderiza la sección de la tabla utilizando la clase AboveBelowTableSection */}
      {shouldShowAboveBelowTableSection && (
        <AboveBelowTableSection
          tickersWithAboveBelowTable={tickersWithAboveBelowTable}
          data1={data1}
          data6={data6}
        />
      )}

      {uniqueTickers.map((ticker) => (
        <div className="ticker-section" key={ticker}>
          {data1 && data1[ticker] && (
            <div className="times-section">
              <h3>Times that happend {ticker}</h3> {/* Title for new table */}
              <AboveBelowTable data={data1[ticker]} />
            </div>
          )}
          {data6 && data6[ticker] && (
            <div className="times-section">
              <h3>Times that happend {ticker}</h3> {/* Title for new table */}
              <AboveBelowTable data={data6[ticker]} />
            </div>
          )}

          {(data2 && data2[ticker]) ||
          (data3 && data3[ticker]) ||
          (data4 && data4[ticker]) ||
          (data5 && data5[ticker]) ? (
            <h3>Data for {ticker}</h3>
          ) : null}

          {data2 && data2[ticker] && (
            <div className="times-section">              
              <MonthAboveBelow data={{ [ticker]: data2[ticker] }} />
            </div>
          )}

          {data7 && data7[ticker] && (
            <div className="times-section">
              <h4><span style={aboveStyle}>Above</span> and <span style={belowStyle}>Below</span> Days - {ticker}</h4>
              <MonthAboveBelow data={{ [ticker]: data7[ticker] }} />
            </div>
          )}

          {data3 && data3[ticker] && (
            <div className="times-section">
              <h4></h4>
              <BarGraph3 data={{ [ticker]: data3[ticker] }} />
            </div>
          )}

          {data9 && data9[ticker] && (
            <div className="times-section">
              <BarGraph3 data={{ [ticker]: data9[ticker] }} isCrypto={true} />
            </div>
          )}

          {data4 && data4[ticker] && (
            <div className="times-section">
              <h3><span style={aboveStyle}>Above</span> and <span style={belowStyle}>Below</span> Days - {ticker}</h3>
              <AboveBelowDataFrames data={{ [ticker]: data4[ticker] }} />
            </div>
          )}

          {data8 && data8[ticker] && (
            <div className="times-section">
              <h4><span style={aboveStyle}>Above</span> and <span style={belowStyle}>Below</span> Days - {ticker}</h4>
              <AboveBelowDataFrames
                data={{ [ticker]: data8[ticker] }}
                isCrypto={true}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
export default Times;
