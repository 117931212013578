import React from 'react';
import './SidebarPopup.css';  // Ensure to create appropriate CSS based on the style described

const Popup = ({ onClose, title, children }) => {
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup" onClick={e => e.stopPropagation()}>
        <div className="popup-header">
          <h2>{title}</h2>
          {/*<button onClick={onClose} className="popup-close-btn">X</button>*/}
        </div>
        <div className="popup-content-SB">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
