import React from "react";
import NewsTable from "./NewsTable";
import BarGraph2 from "./BarGraph2";
import PieGraph from "./PieGraph";
import TotalScoreSentiment from "./TotalScoreSentiment";
import SentimentCategories from "./SentimentCategories";
import "./SentimentAnalysis.css";

function SentimentAnalysis(props) {
  const { data } = props;

  console.log("SentimentAnalysis Rendered");
  console.log("Data in SentimentAnalysis:", data);

  return (
    <div className="news-times-table">      
      {Object.entries(data).map(([ticker, tickerData]) => (
        <div className="ticker-section" key={ticker}>
          {/* Check if there are any errors for this ticker */}
          {tickerData.errors && tickerData.errors.length > 0 ? (
            <div>
              <h2>{ticker}</h2>
              <p>Error: {tickerData.errors[0]}</p>
            </div>
          ) : (
            tickerData &&
            tickerData.news &&
            tickerData.sentiment1 &&
            tickerData.sentiment2 &&
            tickerData.sentiment3 &&
            tickerData.sentiment4 && (
              <div className="news-section">
                <h2>News for {ticker}</h2>
                <div className="SentimentAnalysis-twoColuma">
                  <div className="SentimentAnalysis-twoColumaBar">
                  <BarGraph2 data={tickerData.sentiment1} />
                  </div>
                  <div className="SentimentAnalysis-twoColumaPie">
                  <PieGraph data={tickerData.sentiment2} />
                  </div>
                </div>
                <h2>NEWS FOR {ticker}</h2>
                <div className="SentimentAnalysis-twoColuma1">
                  <NewsTable data={tickerData.news} />
                  <TotalScoreSentiment data={tickerData.sentiment3} />
                  <SentimentCategories data={tickerData.sentiment4} />
                </div>
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
}

export default SentimentAnalysis;