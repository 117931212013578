import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Tooltip } from "react-tooltip";
import "./Overview.css";

const Overview = ({ data, dataType, handleDataChange, onClose }) => {
  const tableRef = useRef(null);
  const [parsedData, setParsedData] = useState([]);
  const [popupData, setPopupData] = useState(null); 
  
  const tooltips = [
    { id: 'Ticker', text: 'Ticker' },
    { id: 'Description', text: 'Basic overview.' },
    { id: 'Sector', text: 'Business category.' },
    { id: 'Industry', text: 'Specific market sector.' },
    { id: 'MarketCap', text: 'Total market value.' },
    { id: 'OutstandingShare', text: 'Shares in circulation.' },
    { id: 'EBITDA', text: 'Earnings metric.' },
    { id: 'PERatio', text: 'Valuation indicator.' },
    { id: 'BookValue', text: 'Asset value.' },
    { id: 'Beta', text: 'Volatility measure.' },
    { id: 'ProfitMargin', text: 'Profitability ratio.' },
    { id: 'OperatingMargin', text: 'Efficiency indicator.' },
    { id: 'ROA', text: 'Asset profitability.' },
    { id: 'ROE', text: 'Equity profitability.' },
    { id: 'PBR', text: 'XXXXXXXXX - XXXXXXXXXX.' },
    { id: 'EVtoRevenue', text: 'Business value relative to sales.' },
    { id: 'EVtoEBITDA', text: 'Business value relative to earnings.' }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setPopupData(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const parseHTMLToData = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      const rows = doc.querySelectorAll("tbody tr");
      const headers = Array.from(doc.querySelectorAll("thead th")).map((th) => th.textContent);
      
      const data = Array.from(rows).map((row) => {
        const cells = row.querySelectorAll("td");
        let obj = {};
        cells.forEach((cell, index) => {
          obj[headers[index]] = cell.textContent;
        });
        return obj;
      });

      return data;
    };

    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      // Create a table element
      const table = container.append("table").attr("class", "Overview");

      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Assuming `data` is an array of objects, get headers from the keys of the first object
      const headers = data.length > 0 ? Object.keys(data[0]) : [];

      // Append table headers
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .attr("class", (d) => (d === "Description" ? "description-column" : ""))
        .text((d) => d)
        .attr("data-tooltip-id", (d, i) => tooltips[i] ? tooltips[i].id : null)
        .attr("data-tooltip-content", (d, i) => tooltips[i] ? tooltips[i].text : null);

      // Append table rows
      const rowSelection = tbody
        .selectAll("tr")
        .data(data)
        .enter()
        .append("tr")
        .on("click", function (event, d) {
          const rowData = headers.map((header) => ({
            header,
            value: d[header]
          }));
          setPopupData(rowData);
        });

      // Append table cells
      rowSelection
        .selectAll("td")
        .data((row) => headers.map((header) => row[header]))
        .enter()
        .append("td")
        .attr("class", (d, i) => (headers[i] === "Description" ? "description-column" : ""))
        .classed("first-column", (d, i) => i === 0)
        .text((d) => d);

      return table;
    };

    let dataToRender = data;
    if (typeof data === "string") {
      dataToRender = parseHTMLToData(data);
      setParsedData(dataToRender);
    }

    renderTable(dataToRender);
  }, [data]);
  
  const handleClosePopup = () => {
    setPopupData(null);
  };

  const tooltipStyle = {
    backgroundColor: "#438BBA",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "14px"
  };

  return (
    <div className="Overview-container">
      <div ref={tableRef} />
      {popupData && <Popup data={popupData} onClose={handleClosePopup} />}
      {/* Add tooltips for table headers */}
      {tooltips.map((tooltip) => (
        <Tooltip key={tooltip.id} id={tooltip.id} place="top" effect="solid" style={tooltipStyle} />
      ))}
    </div>
  );
};

const Popup = ({ data, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-contentOverview">
        {/*<button className="popup-close" onClick={onClose}>X</button>*/}
        <ul>
          {data.map((item, index) => (
            <li key={index}>
              <strong>{item.header}:</strong> {item.value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Overview;