import React, { useState, useEffect } from "react";
import "./CountDownLandingPage.css";

const COUNTDOWN_TARGET = new Date("2024-08-19T23:59:59");

const getTimeLeft = () => {
  const totalTimeLeft = COUNTDOWN_TARGET - new Date();
  /*const years = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24 * 365));*/
  const days = Math.floor((totalTimeLeft / (1000 * 60 * 60 * 24)) % 365);
  const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
  const seconds = Math.floor((totalTimeLeft / 1000) % 60);
  return { days, hours, minutes, seconds };
};

const CountDownLandingPage = ({ title }) => {
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="countdown">
      <h2>{title}</h2>
      <div className="content">
        {Object.entries(timeLeft).map(([label, value]) => (
          <div className="box" key={label}>
            <span className="label">{label}</span>
            <div className="value">
              <span>{value}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountDownLandingPage;
