import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./heatmap.css";

const MyHeatmap = ({ data }) => {
  const heatmapRef = useRef(null);
  const labels = Object.keys(data);

  useEffect(() => {

    if (!heatmapRef.current) {
      return; // Si no hay referencia al SVG, no hagas nada
    }
  
    // Asegúrate de que `data` es un objeto
    if (typeof data !== 'object' || data === null) {
      console.error('Invalid data: data must be an object.');
      return;
    }
  
    // Comprueba que cada valor de `data` sea también un objeto y tenga valores numéricos
    for (const rowLabel of Object.keys(data)) {
      const row = data[rowLabel];
      if (typeof row !== 'object' || row === null) {
        console.error(`Invalid row: data[${rowLabel}] must be an object.`);
        return;
      }
      for (const columnLabel of Object.keys(row)) {
        const cellValue = row[columnLabel];
        if (typeof cellValue !== 'number' && cellValue !== null) {
          console.error(`Invalid cell value: data[${rowLabel}][${columnLabel}] must be a number or null.`);
          return;
        }
      }
    }

    if (heatmapRef.current) {
      const svg = d3.select(heatmapRef.current);
      const width = 750;
      const height = 550;
      const padding = 12;
      const offsetTop = 30; // Control padding at the top
      const offsetLeft = 100; // Control padding at the left

      const colorScale = d3
        .scaleSequential()
        .domain([-1, 1])
        .interpolator(d3.interpolateRdBu);

      const uniqueLabels = [...new Set(labels)];

       // Configura las escalas con las nuevas dimensiones
       const xScale = d3.scaleBand().domain(uniqueLabels).range([offsetLeft, width - padding * 2]);
       const yScale = d3.scaleBand().domain(uniqueLabels).range([offsetTop, height - padding * 2]);

      const heatmapData = labels.flatMap((rowLabel) => {
        return Object.keys(data[rowLabel]).map((columnLabel) => {
          const value = data[rowLabel][columnLabel];
          return {
            x: columnLabel,
            y: rowLabel,
            value: value !== null ? value : 0,
          };
        });
      });

      const gradient = svg.append("defs")
        .append("linearGradient")
        .attr("id", "textGradient")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "0%")
        .attr("y2", "100%");
      gradient.append("stop")
        .attr('class', 'start')
        .attr("offset", "0%")
        .attr("stop-color", "silver")
        .attr("stop-opacity", 4);
      gradient.append("stop")
        .attr('class', 'end')
        .attr("offset", "100%")
        .attr("stop-color", "silver")
        .attr("stop-opacity", 4);

      const rectsGroup = svg.append("g");
      const textGroup = svg.append("g");
      const diagonalGroup = svg.append("g");

      rectsGroup
        .selectAll(".heatmap-cell")
        .data(heatmapData)
        .enter()
        .append("rect")
        .attr("class", "heatmap-cell")
        .attr("x", (d) => xScale(d.x))
        .attr("y", (d) => yScale(d.y))
        .attr("width", xScale.bandwidth())
        .attr("height", yScale.bandwidth())
        .attr("fill", (d) => colorScale(d.value))
        .style("stroke", "silver")
        .style("rx", "5px")
        .style("ry", "1px");

      rectsGroup
        .selectAll(".heatmap-value")
        .data(heatmapData)
        .enter()
        .append("text")
        .attr("class", "heatmap-value")
        .attr("x", (d) => xScale(d.x) + xScale.bandwidth() / 2)
        .attr("y", (d) => yScale(d.y) + yScale.bandwidth() / 2)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .text((d) => {
          if (typeof d.value === 'number' && d.x !== d.y) {
            return d.value.toFixed(2);
          } else {
            return "";
          }
        })
        .style("fill", "black")
        .style("font-family", "Inter")
        .style("font-size", "15px");

        svg
        .attr("width", width)
        .attr("height", height)
        .style("padding", `${padding}px`)
        /*.style("border-radius", "50px")*/
        /*.style("box-shadow", "0 0 90000px rgba(7, 44, 231, 0.1)")*/
        .style("display", "inline-block")
        .style("border", "none")
        /*.style("outline", "1px solid silver")*/
        .style("outline-offset", "0px")
        .style("font-family", "Inter")
        .style("overflow", "hidden");

      textGroup
        .selectAll(".label-x")
        .data(uniqueLabels)
        .enter()
        .append("text")
        .attr("class", "label label-x")
        .attr("x", (d) => xScale(d) + xScale.bandwidth() / 2)
        .attr("y", offsetTop - 10)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "baseline")
        .text((d) => d)
        .style("font-family", "Inter")
        .style("font-size", "14px")
        .style("stroke", "#000")
        .style("stroke-width", "0.3px")
        .attr("fill", "url(#textGradient)")
        .raise();

      textGroup
        .selectAll(".label-y")
        .data(uniqueLabels)
        .enter()
        .append("text")
        .attr("class", "label label-y")
        .attr("x", offsetLeft - 10)
        .attr("y", (d) => yScale(d) + yScale.bandwidth() / 2)
        .attr("text-anchor", "end")
        .attr("dominant-baseline", "middle")
        .text((d) => d)
        .style("font-family", "Inter")
        .style("font-size", "14px")
        .style("stroke", "#000")
        .style("stroke-width", "0.3px")
        .attr("fill", "url(#textGradient)")
        .raise();

      diagonalGroup
        .selectAll(".diagonal-cell")
        .data(uniqueLabels)
        .enter()
        .append("rect")
        .attr("class", "diagonal-cell")
        .attr("x", (d) => xScale(d))
        .attr("y", (d) => yScale(d))
        .attr("width", xScale.bandwidth())
        .attr("height", yScale.bandwidth())
        .attr("fill", "none")
        .style("stroke", "none");

      diagonalGroup
        .selectAll(".ticker-label")
        .data(uniqueLabels)
        .enter()
        .append("text")
        .attr("class", "ticker-label")
        .attr("x", (d) => xScale(d) + xScale.bandwidth() / 2)
        .attr("y", (d) => yScale(d) + yScale.bandwidth() / 2)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .text((d) => d)
        .style("font-family", "Inter")
        .style("font-size", "15px")
        .style("font-weight", "bold")
        .style("fill", "white");

      svg
        .attr("width", width - offsetLeft)
        .attr("height", height - offsetTop + 30)
        .style("overflow", "visible")
        .style("display", "inline-block");
        //.style("border", "1px solid silver");
    }
  }, [data, labels]);

  return (
    <div className="heatmap-container">
      <svg ref={heatmapRef}></svg>
    </div>
  );
};

export default MyHeatmap;
