import React from 'react';
import "./SocialLink.css";

const SocialLink = ({ name, url, className }) => {
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer" className={`social-link ${className}`}>
      {name}
    </a>
  ) : (
    <div className={`social-link disabled ${className}`}>
      {name}
    </div>
  );
};

const SocialLinks = ({ links }) => {
  return (
    <div className="social-follow">
      <h3>Follow Us</h3>
      {links.map((link, index) => (
        <SocialLink key={index} name={link.name} url={link.url} className="footer-social-link" />
      ))}
    </div>
  );
};

export default SocialLinks;

