// EmailContent.jsx
import React from "react";

const EmailContent = ({ userSpaceFinance }) => {
    
  return (
    <div>
      <div className="menu-item content">Email</div>
      <p>{userSpaceFinance.email || userSpaceFinance.name}</p>
    </div>
  );
};

export default EmailContent;
