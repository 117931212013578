import React, { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
//import Google from "./assets/google.png";
//import Facebook from "./assets/facebook.png";
//import Github from "./assets/github.png";
import LoginForgotPassword from "./LoginForgotPassword";
import GetStarted from "../loginGetStarted/GetStarted";
import RingLoader from "react-spinners/RingLoader";
import { ApiUrl } from "../services/restAPI";
import ClientCredentials from "../services/ClientCredentials";
import { jwtDecode } from "jwt-decode";
import ChatBot from "../Chatbot";

/*Google Services */
import { TypeUserLoginGoogle } from "../services/restAPI";

/*Facebook Services */

/*Twitter Services */

const Login = ({ onAuthenticate, onClose }) => {
  const [form, setForm] = useState({ userName: "", password: "", type: "LP" });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoginForgotPassword, setShowLoginForgotPassword] = useState(false);
  const [showLoginSignUp, setShowLoginSignUp] = useState(false);
  const [userSpaceFinance, setUserSpaceFinance] = useState({});
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [showChatBoot, setshowChatBoot] = useState(false);

  function handleCallbackReponse(response) {   
    var userObject = jwtDecode(response.credential);
    setUserSpaceFinance({
      email: userObject.email,
      emailVerified: userObject.email_verified,
      name: userObject.name,
      givenName: userObject.given_name,
      familyName: userObject.family_name,
      picture: userObject.picture,
      locale: userObject.locale,
    });
    /*console.log("name: " + userObject.name)
          console.log("emailVerified: " + userObject.emailVerified)
          console.log("givenName: " + userObject.givenName)
          console.log("email: " + userObject.email)
          console.log("familyName: " + userObject.familyName)
          console.log("picture: " + userObject.picture)
*/
    //console.log("email: " + userObject.email + "; email_verified: " + userObject.email_verified +";  name: " + userObject.name +"; familyName: "+ userObject.family_name + "; picture: " + userObject.picture + "; givenName: " +userObject.given_name + "; locale: " +userObject.locale + "aud: "+userObject.aud) ;

    const username = userObject.email; // Obtener el nombre de usuario del objeto decodificado
    const code = userObject.aud; // Definir un código de ejemplo, ya que el inicio de sesión se realiza con Google
    const type = TypeUserLoginGoogle; // Definir el tipo de inicio de sesión (por ejemplo, Google)
    const firstName = userObject.given_name; // Obtener el nombre usuario
    const lastName = userObject.family_name; // Obtener el apellido del usuario
    const photo = userObject.picture; // Obtener la foto del usuario

    // Verificar el usuario en la base de datos
    axios
      .post(`${ApiUrl}/StartUserLogin/UserSessionLogin`, {
        username,
        code,
        type,
        firstName,
        lastName,
        photo,
      })
      .then((response) => {
        const { item1, item2 } = response.data;
        if (item1.numberError === 0 && item2 && item2.iD_User) {
          // Si el usuario existe en la base de datos, mostrar el ChatBot
          localStorage.setItem("sessionTokenSpaceFinance", item2.token);
          localStorage.setItem("usersSpaceFinance", JSON.stringify(item2));
          setshowChatBoot(true);          
        } else {
          // Si el usuario no existe en la base de datos, mostrar el mensaje de error
          handleSignOut();
          setError(true);
          setErrorMessage(item1.message || "It seems you are not a user yet. Please click on Sign Up to create an account");
        }
      })
      .catch((error) => {
        // Manejar errores de la solicitud
        setError(true);
        setErrorMessage("It seems you are not a user yet. Please click on Sign Up to create an account.");
      });
    document.getElementById("signInDiv").hidden = true;    
  }

  function handleSignOut() {
    setUserSpaceFinance({});
    document.getElementById("signInDiv").hidden = false;
  }

  useEffect(() => {
    setIsSubmitting(true);
    /*global google */
    google.accounts.id.initialize({
      client_id: ClientCredentials.clientId,
      callback: handleCallbackReponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });

    google.accounts.id.prompt();

    // Verificación de Edge y aplicación de estilos específicos
    function isEdge() {
      return navigator.userAgent.indexOf("Edg") !== -1;
    }

    if (isEdge()) {
      const loginButtonContinue = document.querySelector(
        ".loginButtonContinue"
      );
      if (loginButtonContinue) {
        loginButtonContinue.style.width = "290px";
        loginButtonContinue.style.padding = "15px 20px";
      }
    }
    
    setIsSubmitting(false);
  }, []);

  const eventHandlerSubmit = (e) => {
    e.preventDefault();
    eventHandlerButton();
  };

  const eventHandlerChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const eventHandlerButton = async () => {
    if (!form.userName || !form.password) {
      setError(true);
      setErrorMessage("Please enter your username and password to proceed.");
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${ApiUrl}StartUserLogin/UserSession`,
        form
      );
      const { item1, item2 } = response.data;

      if (item1.numberError !== 0) {
        setError(true);
        setErrorMessage(item1.message);
        return;
      }

      if (item2 && item2.token && item2.name) {
        localStorage.setItem("sessionTokenSpaceFinance", item2.token);
        //localStorage.setItem("usersSpaceFinance", JSON.stringify(item2));
        setshowChatBoot(true);
        onAuthenticate(true, item2);
      }
    } catch (error) {
      setError(true);
      setErrorMessage("It seems you are not a user yet. Please click on SignUp to create an account.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    // Asegura que sólo se inicialice cuando el elemento esté presente
    const signInDiv = document.getElementById("signInDiv");
    if (signInDiv) {
        initializeGoogleLogin();
    }
}, [showLoginForgotPassword, showLoginSignUp]); 

  const initializeGoogleLogin = () => {
    /*global google */
    google.accounts.id.initialize({
      client_id: ClientCredentials.clientId,
      callback: handleCallbackReponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });

    google.accounts.id.prompt();

    // Verificación de Edge y aplicación de estilos específicos
    function isEdge() {
      return navigator.userAgent.indexOf("Edg") !== -1;
    }

    if (isEdge()) {
      const loginButtonContinue = document.querySelector(
        ".loginButtonContinue"
      );
      if (loginButtonContinue) {
        loginButtonContinue.style.width = "290px";
        loginButtonContinue.style.padding = "15px 20px";
      }
    }
  };

  useEffect(() => {
    initializeGoogleLogin();
  }, []);

  const showLoginForgotPasswordForm = () => setShowLoginForgotPassword(true);
  const showLoginSignUpForm = () => setShowLoginSignUp(true);
  const backToLogin = () => {
    setShowLoginForgotPassword(false);
    setShowLoginSignUp(false);
    initializeGoogleLogin();
  };

  if (showLoginForgotPassword) {
    return <LoginForgotPassword onBackToLogin={backToLogin} />;
  }

  if (showLoginSignUp) {
    return <GetStarted onBackToLogin={backToLogin} onClose={onClose} />;
  }

  if (showChatBoot) {
    return (
      <ChatBot
        userSpaceFinance={userSpaceFinance}
        selectedTickers={selectedTickers}
      />
    );
  }

  return (
    <React.Fragment>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}

      <div className="login fadeInDown">
        <div className="wrapper">
          <div id="formContent">
            <div className="fadeIn first">
              <h2 className="forgot-password-header">Welcome once more!</h2>
              <button onClick={onClose} className="popup-close-btn">
                X
              </button>
              <p className="description-text">
                {"We're happy to see you again!"}
              </p>
              <div className="login-left">
                <div
                  className="loginButtonContinue google"
                  id="signInDiv"
                ></div>
                {/*             
                <div
                  className="loginButtonContinue google"
                  onClick={handleGoogleLogin}
                  role="button"
                  tabIndex="0"
                >
                  <img src={Google} alt="" className="icon" />
                  Continue with Google
                </div>
                <div className="loginButtonContinue facebook">
                  <img src={Facebook} alt="" className="icon" />
                  Continue with Facebook
                </div>
                <div className="loginButtonContinue github">
                  <img src={Github} alt="" className="icon" />
                  Continue with Github
                </div>*/}
              </div>
              <div className="center">
                <div className="line" />
                <div className="or">OR</div>
              </div>
            </div>
            <div className="login-right">
              <form onSubmit={eventHandlerSubmit}>
                <input
                  type="text"
                  className="fadeIn second"
                  name="userName"
                  placeholder="Email address"
                  value={form.userName}
                  onChange={eventHandlerChange}
                />
                <input
                  type="password"
                  className="fadeIn third"
                  name="password"
                  placeholder="Password"
                  value={form.password}
                  onChange={eventHandlerChange}
                />
                <div className="fadeIn third" id="forgotPassword">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showLoginForgotPasswordForm();
                    }}
                    className="link-style"
                  >
                    Forgot the password?
                  </a>
                </div>
                <input type="submit" className="fadeIn fourth" value="LogIn" />
                <div id="signupLink" className="text-left fadeIn fourth">
                  <span className="no-account-text">
                    Don't have an account?{" "}
                  </span>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showLoginSignUpForm();
                    }}
                    className="link-style"
                  >
                    Sign up
                  </a>
                </div>
              </form>
              {error && (
                <div id="formFooter">
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
