import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { Card } from "antd";

const styles = {
  card: {
    borderRadius: "42px",
    padding: "10px",
    width: "90%",
    height: "315px",
    backgroundColor: "transparent",
    border: "none",
  },
};

const LineChart = ({
  data: portfolio_return,
  initialWidth = 800,
  height = 280,
}) => {
  const ref = useRef();
  // Agregar estado para manejar el ancho de la gráfica
  const [chartWidth, setChartWidth] = useState(initialWidth);

  const margin = { top: 5, right: 40, bottom: 50, left: 0 },
    innerWidth = chartWidth - margin.left - margin.right,
    innerHeight = height - margin.top - margin.bottom;

  const numberOfXTicks = 7;
  const numberOfYTicks = 8;

  const formatDate = d3.timeFormat("%b-%d-%y");
  const formatDateFull = d3.timeFormat("%B %d, %Y");

  useEffect(() => {
    // Establecer el ancho inicial basado en el ancho del elemento contenedor o ventana
    const handleResize = () => {
      const chartContainerWidth = ref.current.parentElement.offsetWidth;
      setChartWidth(chartContainerWidth);
    };

    // Agregar el evento de escucha de redimensionamiento al cargar y establecer el tamaño inicial
    window.addEventListener("resize", handleResize);
    handleResize(); // Establece el ancho inicial al cargar

    // Limpiar el evento de escucha cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("Portfolio Return:", portfolio_return);

    if (
      !portfolio_return ||
      !portfolio_return.date ||
      !portfolio_return.value
    ) {
      console.error("Data is empty!");
      return;
    }

    const dateData = portfolio_return.date; // Access date data
    const valueData = portfolio_return.value; // Access value data

    // Convert date and value data into an array of objects
    const transformedData = Object.keys(dateData).map((key) => {
      return {
        date: new Date(dateData[key]),
        value: +valueData[key] * 100,
      };
    });

    const newData = transformedData.sort((a, b) => a.date - b.date);

    d3.select(ref.current).selectAll("*").remove();

    const svg = d3
      .select(ref.current)
      .attr("width", chartWidth + 15)
      .attr("height", height);

    const minValue = d3.min(newData, (d) => d.value);
    const maxValue = d3.max(newData, (d) => d.value);

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(newData, (d) => d.date))
      .range([20, innerWidth]);

    let step = 1000;
    if (maxValue <= 1000) {
      step = 100; // or any other value suitable for your case
    } else if (maxValue <= 2000) {
      step = 200;
    } // Add more conditions as needed

    const tickCount = Math.ceil(maxValue / step);
    const adjustedMax = tickCount * step;

    const yScale = d3
      .scaleLinear()
      .domain([minValue, adjustedMax])
      .range([innerHeight, 0]);

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(numberOfXTicks)
      .tickFormat(formatDate)
      .tickSize(0);
    const gx = g
      .append("g")
      .attr("transform", `translate(0, ${innerHeight + 10})`)
      .style("font-size", "10px")
      .style("color", "#3462A0")
      .call(xAxis);
    gx.selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");
    /*gx.call((g) => g.select(".domain").remove())*/
    gx.selectAll(".tick line").remove();
    // Cambiar el color de la línea del dominio a rojo
    gx.select(".domain").attr("stroke", "#E5E6E7"); // Establecer el color de la línea del dominio a rojo


    const yAxis = d3.axisRight(yScale).ticks(numberOfYTicks).tickFormat(d => `${d}%`).tickSize(0);
    /*const yAxis = d3.axisLeft(yScale).ticks(numberOfYTicks);*/
    const gy = g
      .append("g")
      .attr("transform", `translate(${innerWidth + 20}, 0)`)
      .style("font-size", "13px")
      .style("color", "#3462A0")
      .call(yAxis);
    gy.call((g) => g.select(".domain").remove())
      .selectAll(".tick line")
      .remove();

    const area = d3
      .area()
      .x((d) => xScale(d.date))
      .y0(innerHeight)
      .y1((d) => yScale(d.value))
      .curve(d3.curveMonotoneX);

    const defs = svg.append("defs");

    const gradient = defs
      .append("radialGradient")
      .attr("id", "line-chart-gradient") // Cambia aquí el id
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("cx", "40%")
      .attr("cy", "70%")
      .attr("r", "110%")
      .attr("fx", "30%")
      .attr("fy", "60%");

    gradient
      .append("stop")
      .attr("offset", "0")
      .attr("stop-color", "transparent")
      .attr("stop-opacity", 0.1);

    gradient
      .append("stop")
      .attr("offset", "1")
      .attr("stop-color", "transparent")
      .attr("stop-opacity", 0);

    const radius = 20;
    const clip = defs
      .append("clipPath")
      .attr("id", "clip")
      .append("path")
      .attr(
        "d",
        `M ${radius},0 h ${
          innerWidth - 2 * radius
        } a ${radius},${radius} 0 0 1 ${radius},${radius} v ${
          innerHeight - 2 * radius
        } a ${radius},${radius} 0 0 1 -${radius},${radius} h ${
          -innerWidth + 3 * radius
        } a ${radius},${radius} 0 0 1 -${radius},-${radius} v ${
          -innerHeight + 2 * radius
        } a ${radius},${radius} 0 0 1 ${radius},-${radius} z`
      );

    g.append("path")
      .datum(newData)
      .attr("class", "area")
      .attr("d", area)
      .style("fill", "url(#line-chart-gradient)")
      .style("clip-path", "url(#clip)")
      .style("filter", "url(#rounded-corners)");

    const line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
      .curve(d3.curveMonotoneX);

    g.append("path")
      .datum(newData)
      .attr("fill", "none")
      .attr("stroke", "#0A0AA4")
      .attr("stroke-width", 1.5)
      .attr("d", line);

    const focus = g.append("g").style("display", "none");

    focus
      .append("circle")
      .attr("r", 5)
      .attr("stroke", "steelblue")
      .attr("fill", "white")
      .attr("stroke-width", 2);

    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "#0A0AA4")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("z-index", "2")
      .style("box-shadow", "0px 10px 10px rgba(0,0,0,0.1)")
      .style("border", "1px solid white")
      .style("top", "0px")
      .style("left", "0px")
      .style("text-align", "center")
      .attr("class", "rounded p-2 bg-white text-dark");

    g.append("rect")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mouseover", function () {
        focus.style("display", null);
      })
      .on("mouseout", function () {
        focus.style("display", "none");
        tooltip.style("visibility", "hidden");
      })
      .on("mousemove", function (event) {
        const pointer = d3.pointer(event, this);
        const pointerDate = xScale.invert(pointer[0]);
        const bisectDate = d3.bisector((d) => d.date).left;

        let idx = bisectDate(newData, pointerDate);

        // This conditional checks whether the index is within the bounds of the array and
        // adjusts the index to point to the closest date to the pointer.
        if (idx > 0 && idx < newData.length) {
          const d0 = newData[idx - 1];
          const d1 = newData[idx];
          idx = pointerDate - d0.date > d1.date - pointerDate ? idx : idx - 1;
        } else if (idx >= newData.length) {
          // Handle case where idx is out of bounds on the right side
          idx = newData.length - 1;
        }

        const d = newData[idx];
        focus.attr(
          "transform",
          `translate(${xScale(d.date)}, ${yScale(d.value)})`
        );

        tooltip
          .html(
            `<span style="font-size:16px; font-weight:bold; font-family:Oxanium; color:white">
              ${(d.value).toFixed(0)} %
            </span>
            <br>
            <span style="font-size:12px; font-family:Oxanium; color:white">
              ${formatDateFull(d.date)}
            </span>`
          )
          .style("left", event.pageX + "px")
          .style("top", event.pageY - 100 + "px")
          .style("visibility", "visible");
      });
  }, [height, chartWidth, portfolio_return]);

  return (
    <div>
      <Card style={styles.card}>
        <svg ref={ref}></svg>
      </Card>
    </div>
  );
};

export default LineChart;
