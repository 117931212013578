import React, { useEffect, useRef, useState } from "react";
import "./SettingsPopup.css";
import ContactUsContent from "./ContactUsContent";
import ChangePasswordContent from './ChangePasswordContent';
import DeleteAccountContent from './DeleteAccountContent';
import EmailContent from './EmailContent';

const SettingsPopup = ({ onClose, userSpaceFinance }) => {
  const popupRef = useRef();
  const [activeMenu, setActiveMenu] = useState('');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const renderContent = () => {
    switch (activeMenu) {
      case 'email':
        return <EmailContent userSpaceFinance={userSpaceFinance}/>;
      case 'changePassword':
        return <ChangePasswordContent userSpaceFinance={userSpaceFinance}/>;
      case 'contactUs':
        return <ContactUsContent userSpaceFinance={userSpaceFinance}/>;
      case 'deleteAccount':
        return <DeleteAccountContent userSpaceFinance={userSpaceFinance} onClose={onClose}/>;
      default:
        return <EmailContent userSpaceFinance={userSpaceFinance}/>;
    }
  };

  return (
    <div className="settings-popup-overlay" onClick={(e) => e.stopPropagation()}>
      <div className="settings-popup" ref={popupRef}>
        <div className="settings-popup-header">
          <h2>Settings</h2>
          <button onClick={onClose} className="settings-popup-close-btn">X</button>
        </div>
        <div className="settings-popup-body">
          <div className="settings-popup-menu">
            <div className="menu-section">
              <div className="menu-item parent">Profile</div>
              <div className={`menu-item child ${activeMenu === 'email' ? 'active' : ''}`} onClick={() => setActiveMenu('email')}>Email</div>
              <div className={`menu-item child ${activeMenu === 'changePassword' ? 'active' : ''}`} onClick={() => setActiveMenu('changePassword')}>Change Password</div>
              <div className={`menu-item child ${activeMenu === 'contactUs' ? 'active' : ''}`} onClick={() => setActiveMenu('contactUs')}>Contact Us</div>
              <div className={`menu-item child ${activeMenu === 'deleteAccount' ? 'active' : ''}`} onClick={() => setActiveMenu('deleteAccount')}>Delete Account</div>
            </div>
            <div className="menu-section">
              <div className="menu-item parent">Plan</div>
              <div className="menu-item child disabled">Status</div>
              <div className="menu-item child disabled">Cancel Subscription</div>
              <div className="menu-item child disabled">Upgrade</div>
            </div>
          </div>
          <div className="settings-popup-content">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;