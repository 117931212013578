// LoginSendCode.js
import React, { useState } from "react";
import "./Login.css"; // Reutilizamos los estilos de Login.css
import axios from "axios";
import letterImage from "../assets/SendMail.svg"; // Asegúrate de reemplazar con la ruta correcta de tu imagen
import { ApiUrl } from "../services/restAPI";
import RingLoader from "react-spinners/RingLoader";
import PulseLoader from "react-spinners/PulseLoader";
import { Link } from "react-router-dom";
import LoginResetPassword from "./LoginResetPassword";

const LoginSendCode = ({ userName }) => {
  const [numberCode, setCode] = useState(new Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoginResetPassword, setShowLoginResetPassword] = useState(false);

  const validateDigit = (value) => {
    return !isNaN(value) && value.length === 1;
  };

  const handleInputChange = (element, index) => {
    const value = element.value;
    
    // Verifica que el valor sea un dígito o esté vacío
    if (!isNaN(value) && (value === "" || value.length === 1)) {
      let newCode = [...numberCode];
      newCode[index] = value;
      setCode(newCode);
  
      // Auto foco al siguiente input si se introduce un dígito y hay un siguiente input
      if (value.length === 1 && element.nextSibling) {
        element.nextSibling.focus();
      }
      // Auto foco al input anterior si se está borrando el dígito (tecla backspace) y no es el primer input
      else if (value === "" && index !== 0) {
        element.previousSibling.focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    const { key } = event;
    let newCode = [...numberCode];
  
    if (key === "Backspace") {
      if (numberCode[index] === "") {
        if (index !== 0) {
          newCode[index - 1] = "";
          setCode(newCode);
          event.target.previousSibling.focus();
        }
      } else {
        newCode[index] = "";
        setCode(newCode);
      }
    }
  };

  const handleSubmit = () => {
    setErrorMessage("");
    setIsSubmitting(true);

    let fullCode = numberCode.join("");
    let verifyCodeUrl = ApiUrl + "StartUserLogin/ApprovedForgotPassword"; // URL de la API para verificar el código

    axios
      .post(verifyCodeUrl, { userName, numberCode: fullCode })
      .then((response) => {
        setIsSubmitting(false);
        const { item1, item2 } = response.data;

        if (item2) {
          // Si item2 es true, significa que el código es correcto
          setShowLoginResetPassword(true);
        } else if (item1) {
          // Si item1 contiene un mensaje de error, muestra ese mensaje
          setErrorMessage(response.data.item1.message);
          setShowLoginResetPassword(false);
        } else {
          // Si no hay mensaje de error ni código correcto, muestra un mensaje genérico de error
          setErrorMessage("The verification code doesn't match. Please verify the code or click 'Resend Code.");
          setShowLoginResetPassword(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setShowLoginResetPassword(false);
        setErrorMessage("The verification code doesn't match. Please verify the code or click 'Resend Code.");
      });
  };

  const resendCode = () => {
    setErrorMessage("");
    setIsSubmitting(true);
    setCode(new Array(6).fill("")); 

    let resendCodeUrl = ApiUrl + "StartUserLogin/ResendForgotCodeMail"; // URL de la API para reenviar el código

    axios
      .post(resendCodeUrl, { userName })
      .then((response) => {
        setIsSubmitting(false);
        const { item1, item2 } = response.data;
        if (item2) {
          // Si item2 contiene un nuevo código, muestra un mensaje de éxito con el nuevo código
          setErrorMessage("A new code has been sent to your email.");
        } else if (item1) {
          // Si item1 contiene un mensaje de error, muestra ese mensaje
          setErrorMessage(item1.message);
        } else {
          // Si no hay mensaje de error ni nuevo código, muestra un mensaje genérico de error
          setErrorMessage("The verification code doesn't match. Please verify the code or click 'Resend Code..");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setErrorMessage("The verification code doesn't match. Please verify the code or click 'Resend Code..");
      });
  };

  const isCodeComplete = numberCode.every((digit) => validateDigit(digit));

  if (showLoginResetPassword) {
    return <LoginResetPassword userName={userName} />;
  }

  return (
    <>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}
      <div className="login fadeInDown">
        <div className="wrapper">
          <div id="formContent">
            <div className="login-right">
              <br></br>
              <br></br>
              <img src={letterImage} alt="Letter" />
              <p className="forgot-password-text">
                Great! Now, take a look at your mail {userName}, we've sent you
                the code to reset your password.
              </p>
              <div className="code-inputs">
                {numberCode.map((digit, index) => (
                  <input
                    className="code-input fadeIn second"
                    type="text"
                    maxLength="1"
                    key={index}
                    value={digit}
                    onChange={(e) => handleInputChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  />
                ))}
              </div>
              <button
                className="reset-password-button fadeIn third"
                onClick={handleSubmit}
                disabled={!isCodeComplete}
              >
                Send
              </button>
              <button
                className="reset-password-button fadeIn fourth"
                onClick={resendCode}
              >
                Resend Code
              </button>
              {errorMessage && (
                <div className="alert-danger">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginSendCode;
