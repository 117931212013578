// 1. React and third-party library imports
import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "react-bootstrap";
import "@fontsource/oxanium"; // Defaults to weight 400

// FontAwesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

// 2. Local component imports
import ChatBot from "./Chatbot";
import Login from "./login/Login";
import GetStarted from "./loginGetStarted/GetStarted";
import ClientCredentials from "./services/ClientCredentials";
import AnimatedText from "./AnimatedText ";
import AboutUsPopup from "./externalPages/AboutUsPopup";
import ContactUsPopup from "./externalPages/ContactUsPopup";
import DocumentationPopup from "./externalPages/DocumentationPopup";
import Countdown from "./externalPages/CountDownLandingPage";
import PrivacyPolicyPopup from "./privacyPolicy/PrivacyPolicyPopup";
import SocialLinks from "./externalPages//SocialLinks";

// 3. Redux store
import { store } from "./AudioRecord/store"; // Import your store from the store.js file
import RingLoader from "react-spinners/RingLoader";
import ConfettiExplosion from "react-confetti-explosion";

// 4. Stylesheets
import "./App.css"; // General App styles
import "./AppPp.css"; // Additional App styles
import "./AppStart.css"; // Start page specific styles
import "./indexPortada.css"; // Cover/Index page styles
import "./global.css"; // Global styles applicable across the app

// 5. Static assets like images
//import Logo_XXL from "./assets/newImages/Logo_XXL.png";
import Logo_XXL from "./assets/newImages/Logo_XXL.svg";
import IconCryptoXXL from "./assets/newImages/IconCryptoXXL.png";
import IconStockXXL from "./assets/newImages/IconStockXXL.png";
import IconWTFXXL from "./assets/newImages/IconWTFXXL.png";
import AstronautaBarrasXXL from "./assets/newImages/AstronautaBarrasXXL.gif";
import AstronautaBarrasXXLCelular from "./assets/newImages/AstronautaBarrasXXLCelular.svg";
import AstronautaNumerosXXL from "./assets/newImages/AstronautaNumerosXXL.svg";
import AstronautaNumerosXXLCelular from "./assets/newImages/AstronautaNumerosXXLCelular.svg";
import EstrellasXXL from "./assets/newImages/EstrellasXXL.png";
/*import menuHamburguesa from "./assets/menuHamburguesa.png";*/
import menuHamburguesa from "./assets/menuHamburguesa.svg";
import EugeneGif from "./assets/newImages/EugeneGif.gif";

// 6. Llamar a la API
import axios from "axios";
import { ApiUrl } from "./services/restAPI";

// Initialize FontAwesome Icons
library.add(fab, fas, far);

function ImageShowcase({ images }) {
  return (
    <>
      <section className="image-showcase-container">
        {images.map((image, index) => (
          <figure key={index} className="image-container">
            <img
              loading="lazy"
              src={image.src}
              alt={image.alt}
              className="showcase-image"
            />
          </figure>
        ))}
      </section>
    </>
  );
}

function ImageShowcaseGif({ images }) {
  return (
    <>
      <section className="image-showcase-container-gif ">
        {images.map((image, index) => (
          <figure key={index} className="image-container-gif ">
            <img
              loading="lazy"
              src={image.src}
              alt={image.alt}
              className="showcase-image-gif "
            />
          </figure>
        ))}
      </section>
    </>
  );
}

/************************************************
Component for displaying individual testimonials
*************************************************/

const TestimonialCard = ({ testimonialText, imageSrc, altText }) => (
  <div className="testimonial-card">
    <img
      loading="lazy"
      src={imageSrc}
      alt={altText}
      className="testimonial-image"
    />
    <p className="testimonial-text">{testimonialText}</p>
  </div>
);

// Main component
const TestimonialSection = () => {
  // Testimonial data could ideally come from an API or a database
  const testimonials = [
    {
      testimonialText:
        "With Eugene, I save time and get an instant, comprehensive view of the market. Essential for quick and accurate decision-making!",
      imageSrc: EstrellasXXL,
      altText: "Person giving a thumbs up",
    },
    {
      testimonialText:
        "It's the only one that allows optimization with different assets and cryptocurrencies.",
      imageSrc: EstrellasXXL,
      altText: "Person analyzing financial data",
    },
    {
      testimonialText:
        "The accuracy it provides gives me the necessary confidence to make decisions. Fundamental for any serious investor.",
      imageSrc: EstrellasXXL,
      altText: "Person reflecting on investment choices",
    },
  ];

  return (
    <>
      <section className="testimonial-section">
        <h2 className="hero-title-testimonial right-scroll">
          What people are saying about us
        </h2>
        <div className="testimonial-container right-scroll">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              testimonialText={testimonial.testimonialText}
              imageSrc={testimonial.imageSrc}
              alt={testimonial.altText}
            />
          ))}
        </div>
      </section>
    </>
  );
};

/*******************************
OUR ASSETS
********************************/
const data = [
  {
    id: 1,
    imageSrc: IconStockXXL,
    altText: "Global Stocks",
    title: "Global Stocks",
    description: "United States, Brazil, India, Germany, China and more!",
  },
  {
    id: 2,
    imageSrc: IconCryptoXXL,
    altText: "Cryptocurrencies",
    title: "Cryptos",
    description: "+ 6,000 cryptocurrencies at your fingertips",
  },
  {
    id: 3,
    imageSrc: IconWTFXXL,
    altText: "ETFs",
    title: "ETFs",
    description: "+3,700 ETFs at your disposal",
  },
];

const CategoryCard = ({ imageSrc, altText, title, description }) => (
  <div className="card-category">
    <img
      loading="lazy"
      src={imageSrc}
      alt={altText}
      className="card-category-image"
    />
    <h2 className="card-category-title">{title}</h2>
    <p className="card-category-description">{description}</p>
  </div>
);

const AssetsSection = () => (
  <section className="assets-section">
    <h2>Our Assets</h2>
    <div className="container-category">
      {data.map((item) => (
        <CategoryCard key={item.id} {...item} />
      ))}
    </div>
  </section>
);

/***************************************
 * WORK SMARTER WITH OUR TOOLS
 ***************************************/
const textStyle = {
  heading: {
    letterSpacing: "-1.5px",
    font: "50px/56px Inter, sans-serif",
    fontWeight: 700,
  },
  subHeading: {
    letterSpacing: "-0.36px",
    marginTop: "35px",
    font: "500 18px/26px Inter, -apple-system, Roboto, Helvetica, sans-serif",
  },
  button: {
    borderRadius: "40px",
    background: "linear-gradient(107deg, #2272ff 0%, #0d03c3 100%)",
    color: "#fff",
    whiteSpace: "nowrap",
    /*textTransform: "uppercase",*/
    letterSpacing: "-0.4px",
    padding: "19px 40px",
    font: "20px/150% Inter, sans-serif",
    cursor: "pointer",
    width: "250px",
  },
};

const FeatureCard = ({ title, description }) => (
  <div className="feature-card fade-in">
    <h2 className="feature-title">{title}</h2>
    <p className="feature-description">{description}</p>
  </div>
);

const ContentSection = () => {
  const features = [
    {
      title: "Optimization Portfolio",
      description:
        "Optimize your portfolio of stocks, cryptocurrencies, and ETFs to find the perfect combination that maximizes your returns and minimizes your risk. Choose your benchmark, your risk level, and more!",
    },
    {
      title: "Stationarity",
      description:
        "With the Times tool, you can analyze the stationarities of different assets and observe how much they have varied over a specific period of time. Gain a detailed insight into the evolution of assets over time.",
    },
    {
      title: "Annualized Volatility",
      description:
        "Assess the risk associated with assets through annualized volatility, and adjust investment strategies to effectively manage the risk of your asset portfolio.",
    },
    {
      title: "Percentage Change",
      description:
        "Examine the percentage change of assets over various time periods, allowing you to input dates in the most granular manner.",
    },
    {
      title: "Correlation",
      description:
        "Correlation reveals how assets move together, enabling effective portfolio diversification by selecting those with low correlations. Understanding these relationships empowers investors to make informed decisions, managing risk and enhancing overall stability.",
    },
    {
      title: "Financial Statements",
      description:
        "Access detailed information on balance sheets, income statements, cash flow, and earnings more easily than ever before.",
    },
  ];

  return (
    <section className="content-section">
      {features.map((feature, index) => (
        <FeatureCard
          key={index}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </section>
  );
};

const socialLinksData = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/space-finance-ai/",
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/channel/UC7i7g6biGjEEyI-0jNAgqyQ",
  },
  { name: "X/Twitter ", url: "https://twitter.com/SpaceFinance_AI" },
  {
    /*name: "Instagram", url: "" */
  }, // Inactivo
  {
    /*name: "Facebook", url: ""*/
  }, // Inactivo
];

const App = () => {
  const [showChatBot, setShowChatBot] = useState();
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentComponent, setCurrentComponent] = useState("login");
  const [userSpaceFinance, setUserSpaceFinance] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutUsPopupOpen, setIsAboutUsPopupOpen] = useState(false);
  const [isContactUsPopupOpen, setIsContactUsPopupOpen] = useState(false);
  const [isDocumentationPopupOpen, setIsDocumentationPopupOpen] =
    useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  /*confetti*/
  const [isExploding, setIsExploding] = React.useState(false);
  const confettiRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openAboutUsPopup = () => {
    setIsAboutUsPopupOpen(true);
  };

  const closeAboutUsPopup = () => {
    setIsAboutUsPopupOpen(false);
  };

  const openContactUsPopup = () => {
    setIsContactUsPopupOpen(true);
  };

  const closeContactUsPopup = () => {
    setIsContactUsPopupOpen(false);
  };

  const openDocumentationPopup = () => {
    setIsDocumentationPopupOpen(true);
  };

  const closeDocumentationPopup = () => {
    setIsDocumentationPopupOpen(false);
  };

  const openPrivacyPolicyPopup = () => {};

  const fetchUserSessionToken = async (userToken) => {
    try {
      const response = await axios.get(
        `${ApiUrl}StartUserLogin/UserSesionToken/${userToken}`
      );
      const { item1, item2 } = response.data;
      if (item1.numberError !== 0) {
        // Manejar error de la API
        console.error(item1.message);
        // Eliminar token inválido del localStorage
        localStorage.removeItem("sessionTokenSpaceFinance");
        localStorage.removeItem("usersSpaceFinance");
        return null;
      }

      return item2;
    } catch (error) {
      console.error("Error fetching user session token:", error);
      return null;
    }
  };

  useEffect(() => {
    const validateSession = async () => {
      if (initialLoad) {
        setIsSubmitting(true);
        ClientCredentials.loadCredentials();
        const userToken = localStorage.getItem("sessionTokenSpaceFinance");
        const usersSpaceFinance = localStorage.getItem("usersSpaceFinance");

        if (userToken) {          
          const userData = await fetchUserSessionToken(userToken);
          //console.log("RODRIGO: " + userData.name)
          if (userData) {
            setIsAuthenticated(true);
            setUserSpaceFinance({
              email: userData.name,
              emailVerified: userData.name,
              name: userData.fullName,
              givenName: userData.fullName,
              familyName: userData.fullName,
              picture: userData.photo,
              locale: "",
            });
            toggleChatBot();
          } else {
            // Token no válido, remover del localStorage
            localStorage.removeItem("sessionTokenSpaceFinance");
            localStorage.removeItem("usersSpaceFinance");
          }
        }
        setIsSubmitting(false);
        setInitialLoad(false);
      }

      if (isAuthenticated) {
        setCurrentComponent("chatBot");
        setIsSubmitting(false);
      }
    };

    validateSession();
  }, [initialLoad]);

  // Intersection Observer setup
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            triggerExplosion();
          }
        });
      },
      {
        root: null, // observing for viewport
        rootMargin: "0px",
        threshold: 0.5, // Trigger when 50% of the target is visible
      }
    );

    if (confettiRef.current) {
      observer.observe(confettiRef.current);
    }

    return () => {
      if (confettiRef.current) {
        observer.unobserve(confettiRef.current);
      }
    };
  }, []);

  /*useEffect(() => {
    const interval = setInterval(() => {
      triggerExplosion();
    }, 5000); // Establece que el confeti se active cada 5 segundos

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonta
  }, []);*/

  useEffect(() => {
    const elements = document.querySelectorAll(".fade-in, .right-scroll");
    const showOnScroll = () => {
      elements.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add("show");
        }
      });
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", showOnScroll);
    window.addEventListener("touchstart", showOnScroll);

    showOnScroll(); // Mostrar elementos al cargar la página

    return () => {
      window.removeEventListener("scroll", showOnScroll);
      window.removeEventListener("touchstart", showOnScroll);
    };
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll(".right-scroll");
    const showOnScroll = () => {
      elements.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add("show");
        }
      });
    };

    const showOnTouch = () => {
      elements.forEach((element) => {
        element.classList.add("show");
      });
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", showOnScroll);
    window.addEventListener("touchstart", showOnTouch);

    showOnScroll(); // Mostrar elementos al cargar la página

    return () => {
      window.removeEventListener("scroll", showOnScroll);
      window.removeEventListener("touchstart", showOnTouch);
    };
  }, []);

  const toggleChatBot = () => {
    setShowChatBot(!showChatBot);
  };

  const handleAuthentication = (authenticated, userData) => {
    setIsAuthenticated(authenticated);
    if (authenticated) {
      setUserSpaceFinance(userData);
      localStorage.setItem("usersSpaceFinance", JSON.stringify(userData));
      setCurrentComponent("chatBot");
    }
  };

  const showLogin = () => {
    setCurrentComponent("login");
    toggleChatBot(); // También abre el chatbot
  };

  const showGetStarted = () => {
    setCurrentComponent("getStarted");
    toggleChatBot(); // También abre el chatbot
  };

  const NavigationItem = ({ children, onClick }) => (
    <div className="navigation-item" onClick={onClick}>
      {children}
    </div>
  );

  const HeroSection = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
      const handleScroll = () => {
        // Cambia el estado si el scroll es mayor que 0
        setIsScrolled(window.pageYOffset > 0);
      };

      // Añade el listener cuando el componente se monta
      window.addEventListener("scroll", handleScroll);

      // Limpia el listener cuando el componente se desmonta
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    /*ESTO PERMITE ABRIR OTRA VENTANA - ME SERVIRA PARA LAS POLITICAS DE LAS COOKIES *

    const NavigationItem = ({ children, onClick, href, target }) => (
      <div className="navigation-item" onClick={() => {
        if (href) {
          window.open(href, target);
        }
        if (onClick) {
          onClick();
        }
      }}>{children}</div>
    );
*/

    return (
      <div className="hero-section-container">
        <div class="background-container"></div>
        <header className={`hero-header ${isScrolled ? "scrolled" : ""}`}>
          <h1>SPACE FINANCE.AI</h1>
          <div className="menu-toggle" onClick={toggleMenu}>
            <img src={menuHamburguesa} alt="Menú" />
          </div>
          <nav className={isMenuOpen ? "nav-open" : ""}>
            {/*<NavigationItem href="http://localhost:3000/documentation.html" target="_blank">Documentation</NavigationItem>*/}
            <NavigationItem onClick={openDocumentationPopup}>
              Documentation
            </NavigationItem>
            <NavigationItem onClick={openAboutUsPopup}>About Us</NavigationItem>
            <NavigationItem onClick={openContactUsPopup}>
              Contact Us
            </NavigationItem>
          </nav>
          {isAboutUsPopupOpen && <AboutUsPopup onClose={closeAboutUsPopup} />}
          {isContactUsPopupOpen && (
            <ContactUsPopup onClose={closeContactUsPopup} />
          )}
          {isDocumentationPopupOpen && (
            <DocumentationPopup onClose={closeDocumentationPopup} />
          )}
          <button className="sign-up-button" onClick={showLogin}>
            Login
          </button>
        </header>
        <section className="hero-content">
          <h2 className="hero-title">
            Hey! Meet Eugene your AI financial analyst
          </h2>
          <p className="hero-subtitle">
            The fastest and most effective method for conducting your analyses
          </p>
          <button className="get-startedButton" onClick={showGetStarted}>
            Get Started
          </button>
        </section>
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={AstronautaNumerosXXLCelular}
          />
          <img
            src={AstronautaNumerosXXL}
            alt="Astronauta y números"
            className="astronaut-image"
          />
        </picture>
        {showChatBot && (
          <div className="chatbot-container">
            {currentComponent === "login" && (
              <Login
                onAuthenticate={handleAuthentication}
                onClose={handleCloseLogin}
              />
            )}
            {currentComponent === "getStarted" && (
              <GetStarted
                onAuthenticate={handleAuthentication}
                onClose={handleCloseLogin}
              />
            )}
            {currentComponent === "chatBot" && (
              <ChatBot
                userSpaceFinance={userSpaceFinance}
                selectedTickers={selectedTickers}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const images = [
    { src: Logo_XXL, alt: "Space Finance" },
    // Repeat for more images
  ];

  const imagesGif = [
    { src: EugeneGif, alt: "Space Finance - Eugene" },
    // Repeat for more images
  ];

  // Reusable components
  const MenuItem = ({ title, items, onClick }) => (
    <section className="menu-item">
      <h3>{title}</h3>
      {items.map((item, index) => (
        <div key={index} onClick={() => onClick(item)}>
          {item}
        </div>
      ))}
    </section>
  );

  const handleMenuItemClick = (itemTitle) => {
    switch (itemTitle) {
      case "About Us":
        openAboutUsPopup();
        break;
      case "Contact Us":
        openContactUsPopup();
        break;
      case "Documentation":
        openDocumentationPopup();
        break;
      case "Privacy Policy":
        window.open("./privacy-policy.html", "_blank");
        break;
      // Añade más casos según sea necesario
      default:
        console.log("No action defined for", itemTitle);
    }
  };

  const companyInfo = {
    title: "Company",
    items: [
      "About Us",
      /*"Newsletters",*/
      "Privacy Policy",
      /*"Follow Us",*/
      "Podcast",
      "Contact Us",
      "Documentation",
    ],
  };

  const SocialLink = ({ name }) => <div className="social-link">{name}</div>;

  /*const partnerInfo = {
    title: "Our Partners",
    items: ["Career", "Contact Us", "FAQ"],
  };*/

  const handleCloseLogin = () => {
    localStorage.removeItem("sessionTokenSpaceFinance");
    localStorage.removeItem("usersSpaceFinance");
    localStorage.clear();

    window.location.href = "/"; // Redirige al usuario a la página inicial
  };

  if (isSubmitting) {
    return (
      <div className="loading-container">
        <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
      </div>
    );
  }

  // Función para manejar la explosión de confeti
  const triggerExplosion = () => {
    setIsExploding(true);
    setTimeout(() => setIsExploding(false), 1500); // Desactiva después de 500ms
  };

  return (
    <Provider store={store}>
      <Router>
        <PrivacyPolicyPopup />
        {showChatBot ? (
          <div className="App holder-pp">
            {/*<header>
              <h1
                className="main-title"
                onClick={toggleChatBot}
                style={{ paddingTop: "15px" }}
              >
                SpaceFinance.AI
              </h1>
            </header>*/}
            {!isAuthenticated ? (
              currentComponent === "login" ? (
                <Login
                  onAuthenticate={handleAuthentication}
                  onClose={handleCloseLogin}
                />
              ) : (
                <GetStarted
                  onAuthenticate={handleAuthentication}
                  onClose={handleCloseLogin}
                />
              )
            ) : (
              <div>
                {userSpaceFinance && (
                  <ChatBot
                    userSpaceFinance={userSpaceFinance}
                    selectedTickers={selectedTickers}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            <HeroSection />
            <AssetsSection />
            <section className="banner">
              <div className="content">
                <div className="text right-scroll">
                  <h2 style={textStyle.heading}>
                    Boost your results through well-informed investment
                    decisions
                  </h2>
                  <p style={textStyle.subHeading}>
                    Optimize your financial experience with our specialized
                    chat. Conduct asset analyses professionally, Wall
                    Street-style. Empower your investments like never before!
                  </p>
                  <button style={textStyle.button} onClick={showGetStarted}>
                    Get Started
                  </button>
                </div>
                <div className="image right-scroll">
                  <picture>
                    <source
                      media="(max-width: 768px)"
                      srcSet={AstronautaBarrasXXLCelular}
                    />
                    <img
                      src={AstronautaBarrasXXL}
                      alt="Astronauta y barras"
                      className="responsive-img fade-in"
                    />
                  </picture>
                </div>
              </div>
            </section>
            <header
              style={{
                background: "#000066",
                color: "#fff",
                padding: "20px",
                textAlign: "center",
                fontSize: "30px",
              }}
            >
              <h1
                className="fade-in"
                style={{
                  fontFamily: "Inter, sans-serif",
                  margin: "0",
                  padding: "1em 0em 0em 1em",
                }}
              >
                WORK SMARTER <br /> WITH OUR TOOLS ...
              </h1>
            </header>
            <main>
              <ContentSection />
            </main>
            <AnimatedText />
            <div className="confetti-wrapper" ref={confettiRef}>
              {isExploding && (
                <div className="confetti-container">
                  <ConfettiExplosion />
                </div>
              )}
              <ImageShowcase images={images} />
              <Countdown title="Time left to use Eugene for free"></Countdown>
            </div>
            <TestimonialSection></TestimonialSection>
            {/*<ImageShowcaseGif images={imagesGif} />*/}
            <header className="hero-section-footer">
              <div className="hero-wrap">
                <h1 className="h1-footer fade-in">We are here to help you</h1>
                <button
                  className="get-startedButtonFooter fade-in"
                  onClick={showGetStarted}
                >
                  Get Started Now
                </button>
                <div className="content-wrap">
                  <div className="cta-banner">
                    <p className="branding-title fade-in">SPACE FINANCE.AI</p>
                    <p className="fade-in">
                      Specialized chat in financial markets empowered by AI.
                    </p>
                  </div>
                  <MenuItem {...companyInfo} onClick={handleMenuItemClick} />
                  {/*<MenuItem {...partnerInfo} />*/}
                  <SocialLinks links={socialLinksData} />
                </div>
              </div>
            </header>
            <footer>
              <div className="footer-content">
                Copyright © {new Date().getFullYear()} Space Finance.AI.
              </div>
            </footer>
          </>
        )}
      </Router>
    </Provider>
  );
};

export default App;
library.add(fab, fas, far);
