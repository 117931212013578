// src/config/ClientCredentials.js

import axios from 'axios';
import { ApiUrl, TypeUserLoginGoogle } from "./restAPI"; // Asegúrate de que la ruta sea correcta

class ClientCredentials {
    static credentials = { clientId: '', clientSecret: '' };

    static async loadCredentials() {
        try {
            const response = await axios.get(`${ApiUrl}/StartUserLogin/ClientSecretLogin/${TypeUserLoginGoogle}`);
            const { item2 } = response.data;
            if (item2) {
                ClientCredentials.credentials = { clientId: item2.clientId, clientSecret: item2.clientSecret };
            } else {
                console.error('No client credentials found');
            }
        } catch (error) {
            console.error('Error fetching client credentials:', error);
        }
    }

    static get clientId() {
        return ClientCredentials.credentials.clientId;
    }

    static get clientSecret() {
        return ClientCredentials.credentials.clientSecret;
    }
}

export default ClientCredentials;
