import React from "react";
import "./SuggestionItem.css";

const SuggestionItem = ({ suggestion, onSelectSuggestion }) => {
  // Asume que 'suggestion' es ahora un objeto con 'title', 'text', y 'command'
  return (
    <div className="suggestions-container individual" onClick={() => onSelectSuggestion(suggestion.command)}>
      <h2>{suggestion.title}</h2>
      <div
        className="suggestion-item"
        
      >
        {suggestion.text}
      </div>
      {/* Puedes decidir cómo mostrar o usar el 'command' también */}
    </div>
  );
};

export default SuggestionItem;
