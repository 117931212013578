import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./Halving.css";

const DominanceBTCETH = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    if (data && data.length > 0) {
      // Adjust the data to include the "Rest of the Cryptos"
      const btcDominance = data[0]["BTC Dominance"];
      const ethDominance = data[0]["ETH Dominance"];
      const restDominance = 100 - btcDominance - ethDominance;

      // Prepare the modified data object
      const modifiedData = {
        BTC: btcDominance,
        ETH: ethDominance,
        Others: restDominance,
      };

      const width = 300;
      const height = 340; // Aumentado para permitir espacio para las leyendas
      const radius = Math.min(width, height) / 2;

      // Clear the SVG to prevent duplication on data update
      d3.select(ref.current).selectAll("*").remove();

      const svg = d3
        .select(ref.current)
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

      const color = d3.scaleOrdinal(["#FF8C00", "#26F8EC", "#56F416"]);

      // Use Object.entries to create entries from the modified data object
      const pie = d3.pie().value((d) => d[1]); // Access the value with d[1]
      const dataReady = pie(Object.entries(modifiedData));

      const arc = d3.arc().innerRadius(0).outerRadius(radius);

      svg
        .selectAll("slices")
        .data(dataReady)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", (d) => color(d.data[0])); // Use the key for color mapping
      
        // Calcula la posición extendida para las etiquetas
      const outerArc = d3.arc().innerRadius(radius * 0.65).outerRadius(radius * 0.65); // Ajusta el radio para mover las etiquetas hacia afuera

      svg
        .selectAll("text")
        .data(dataReady)
        .enter()
        .append("text")
        .attr("transform", (d) => `translate(${outerArc.centroid(d)})`) // Utiliza outerArc para el cálculo del centroid
        .style("text-anchor", "middle")
        .style("font-size", "25px")
        .style("font-family", "Montserrat, Arial, sans-serif")
        .style("fill", "#133588")
        .each(function (d) {
          var el = d3.select(this);
          var lines = `${d.data[0]}: ${d.data[1].toFixed(2)}%`.split(":");
          el.text("");
          el.append("tspan")
            .text(lines[0])
            .attr("x", 0)
            .attr("dy", "0");
          el.append("tspan")
            .text(lines[1])
            .style("font-weight", "bold")
            .attr("x", 0)
            .attr("dy", "1.2em");
        });
    }
  }, [data]); // Depend on data to rerender

  return (
    <div className="centered-content">
      <h2 className="crypto-halving-title">Market Dominance</h2>
      <svg ref={ref}></svg>
    </div>
  );
};

export default DominanceBTCETH;
