import React, { useState, useEffect } from 'react';
import "./PrivacyPolicyPopup.css"

const PrivacyPolicyPopup = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const privacyAccepted = localStorage.getItem('privacyAccepted');
        if (!privacyAccepted) {
            setIsVisible(true);
        }
    }, []);

    const acceptPrivacyPolicy = () => {
        localStorage.setItem('privacyAccepted', 'true');
        setIsVisible(false);
    };

    const rejectPrivacyPolicy = () => {
        // Aquí puedes manejar el rechazo. Ejemplo: simplemente ocultar el popup.
        setIsVisible(false);
        // Dependiendo de las necesidades, podrías redirigir o realizar otras acciones.
    };

    if (!isVisible) return null;

    return (
        <div className="privacy-policy-popup">
            <p>We use our own and third-party cookies that collect and analyze information in order to personalize your experience. For more information, click on the <a target='_blank' href="/privacy-policy.html">privacy policy</a>.</p>
            <div className="button-container">
                <button className="decline" onClick={rejectPrivacyPolicy}>Deny</button>
                <button className="accept" onClick={acceptPrivacyPolicy}>Accept</button>                
            </div>
        </div>
    );
};

export default PrivacyPolicyPopup;
