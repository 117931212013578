// LoginSendCode.js
import React, { useState } from "react";
import "../login/Login.css"; // Reutilizamos los estilos de Login.css
import axios from "axios";
import letterImage from "../assets/SendMail.svg"; // Asegúrate de reemplazar con la ruta correcta de tu imagen
import { ApiUrl } from "../services/restAPI";
import RingLoader from "react-spinners/RingLoader";
import ChatBot from "../Chatbot";

const GetStartedSendCode = ({ userName }) => {
  const [numberCode, setCode] = useState(new Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoginResetPassword, setShowLoginResetPassword] = useState(false);
  const [selectedTickers, setSelectedTickers] = useState([]);

  const [isLoading, setIsLoading] = useState(false); // Estado para el indicador de carga

  const validateDigit = (value) => {
    return !isNaN(value) && value.length === 1;
  };

  const [userSpaceFinance, setUserSpaceFinance] = useState({
    iD_User: null,
    name: userName,
    statusUser: null,
    statusUserName: null,
    token: null,
    validityDate: null,
  });

  const handleInputChange = (element, index) => {
    const value = element.value;
    
    // Verifica que el valor sea un dígito o esté vacío
    if (!isNaN(value) && (value === "" || value.length === 1)) {
      let newCode = [...numberCode];
      newCode[index] = value;
      setCode(newCode);
  
      // Auto foco al siguiente input si se introduce un dígito y hay un siguiente input
      if (value.length === 1 && element.nextSibling) {
        element.nextSibling.focus();
      }
      // Auto foco al input anterior si se está borrando el dígito (tecla backspace) y no es el primer input
      else if (value === "" && index !== 0) {
        element.previousSibling.focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    const { key } = event;
    let newCode = [...numberCode];
  
    if (key === "Backspace") {
      if (numberCode[index] === "") {
        if (index !== 0) {
          newCode[index - 1] = "";
          setCode(newCode);
          event.target.previousSibling.focus();
        }
      } else {
        newCode[index] = "";
        setCode(newCode);
      }
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    setIsSubmitting(true);

    let fullCode = numberCode.join("");
    let verifyCodeUrl = ApiUrl + "StartUserLogin/ApprovedSignup"; // URL de la API para verificar el código

    axios
      .post(verifyCodeUrl, { userName, numberCode: fullCode })
      .then((response) => {        
        if (response.status === 200) {
          const { item1, item2 } = response.data;

          if (item1 !== null) {
            // El servidor ha devuelto item1, lo que indica un mensaje de error
            setShowLoginResetPassword(false);
            setErrorMessage(item1.message);
            setIsSubmitting(false);
          } else if (item2 && item2.token && item2.name) {
            setUserSpaceFinance({
              iD_User: item2.iD_User,
              name: item2.name,
              statusUser: item2.statusUser,
              statusUserName: item2.statusUserName,
              token: item2.token,
              validityDate: item2.validityDate,
            });
            localStorage.setItem("sessionTokenSpaceFinance", item2.token);
            //localStorage.setItem("usersSpaceFinance", JSON.stringify(item2));
            // El servidor ha devuelto item2 como true, lo que indica que el código es correcto
            setShowLoginResetPassword(true);
            setIsSubmitting(false);
          } else {
            // Respuesta inesperada del servidor
            setShowLoginResetPassword(false);
            setErrorMessage("Unexpected server response - GetStartedSendCode.");
            setIsSubmitting(false);
          }
        } else {
          // En caso de que la respuesta del servidor no sea 200 OK
          setShowLoginResetPassword(false);
          setErrorMessage(
            "Error connecting to the API to verify the code - GetStartedSendCode."
          );
          setIsSubmitting(false);
        }
      })
      .catch((error) => {       
        setShowLoginResetPassword(false);
        setErrorMessage(
          "Error connecting to the API to verify the code - GetStartedSendCode."
        );
        setIsSubmitting(false);
      });
  };

  const resendCode = () => {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    setIsSubmitting(true);
    setCode(new Array(6).fill("")); 

    let resendCodeUrl = ApiUrl + "StartUserLogin/ResendCodeMail"; // URL de la API para reenviar el código

    axios
      .post(resendCodeUrl, { userName })
      .then((response) => {
        if (response.statusText === "OK") {
          // Manejar la respuesta exitosa, por ejemplo, mostrar un mensaje de éxito
          setErrorMessage("A new code has been sent to your email.");
          setIsSubmitting(false);
        } else {
          //setErrorMessage(response.data.result.error_msg);
          setErrorMessage("Error get value from server - GetStartedSendCode.");
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setErrorMessage("Error connecting to the API to resent the code.");
      });
  };

  const isCodeComplete = numberCode.every((digit) => validateDigit(digit));

  if (showLoginResetPassword) {
    return (
      <ChatBot
        userSpaceFinance={userSpaceFinance}
        selectedTickers={selectedTickers}
      />
    );
  }

  return (
    <>
      {isSubmitting && (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
        </div>
      )}
      <div className="login fadeInDown">
        <div className="wrapper">
          <div id="formContent">
            <div className="login-right">
              <br></br>
              <br></br>
              <img src={letterImage} alt="Letter" />
              <p className="forgot-password-text">
                Great! Now, take a look at your mail {userName}, we've sent you
                the code to reset your password.
              </p>
              <div className="code-inputs">
                {numberCode.map((digit, index) => (
                  <input
                    className="numberCode-input fadeIn second"
                    type="text"
                    maxLength="1"
                    key={index}
                    value={digit}
                    onChange={(e) => handleInputChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  />
                ))}
              </div>
              <button
                className="reset-password-button fadeIn third"
                onClick={handleSubmit}
                disabled={!isCodeComplete}
              >
                Send
              </button>
              <button
                className="reset-password-button fadeIn fourth"
                onClick={resendCode}
              >
                Resend Code
              </button>
              {errorMessage && (
                <div className="alert-danger">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStartedSendCode;
